import store from '../../store';
import actions from '../../actions';

export const getSystemUserDetails = () => {
    return new Promise((resolve, reject) => {
        store.dispatch(actions.getSystemUserDetails()).then((res) => {
            resolve(res)
        }).catch((err)=>{
            console.log(err);
            reject(err)
        })
    })
}

export const getUserPermissions = (permissionUserId) => {
    return new Promise((resolve, reject) => {
        store.dispatch(actions.getUserPermissions(permissionUserId)).then((res) => {
            resolve(res)
        }).catch((err)=>{
            console.log(err);
            reject(err)
        })
    })
}

