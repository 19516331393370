import actionTypes from '../actions/actionTypes';

//defining initial state for the reducer
let initialState= {
	studyList : [],
	studyData: [],
	studyArmList: [],
	studyDetail: {},
	studyCohorts: [],
	studyValidity:[],
	studyConcepts:[],
	studyValidationCriteria:{}
};

//reducer that matches the action type for trial actions and changes the state
export const studyReducer = (state = initialState, action ) => {
	
	switch(action.type){

		case actionTypes.GET_STUDY_DATA : {
			return {...state, studyList: action.payload}
		} 
		case actionTypes.SET_STUDY_DATA : {
			return {...state, studyData: action.payload}
		}
		case actionTypes.GET_STUDY_ARM_LIST : {
			return {...state, studyArmList: action.payload}
		}
		case actionTypes.GET_STUDY_DETAIL : {
			return {...state, studyDetail: action.payload}
		}
		case actionTypes.SAVE_STUDY_COHORTS : {
			const newStudyCohorts = state.studyCohorts;
			newStudyCohorts[action.cohortIndex] = action.payload;
			
			return {...state, studyCohorts: newStudyCohorts}
		}
		case actionTypes.GET_STUDY_VALIDITY : {
			return {...state, studyValidity:  action.payload}
		}
		case actionTypes.GET_STUDY_CONCEPTS : {
			
			return {...state, studyConcepts: state.studyConcepts.concat(action.payload)}
		}
		case actionTypes.UPDATE_STUDY_VALIDITY : {
			return {...state, studyValidity:  action.payload}
		}
		// case actionTypes.STUDY_VALIDATION_CRITERIA : {
			
		// 	 let criteria=JSON.parse(sessionStorage.getItem('studyCriteria'));
		// 	return {...state, studyValidationCriteria:  criteria ? criteria :action.payload}
		// }
		case actionTypes.CLEAR_STUDY_COHORTS :
			return {...state, studyCohorts: []}
	
		default : {
			return state
		}
	}
}
