import React, { useState, useEffect, lazy, Suspense } from 'react';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Copyright from '../../../components/Copyright/Copyright';
import { setIsLoading } from '../../../actions/common';
import {
	getSubdocumentById,
	getCohortListForDocument,
	getCohortListForConcept,
	getCohortList,
} from './DataCohortHelper';

const CohortDetail = lazy(() =>
	import(/* webpackChunkName: "CohortDetail" */ '../../../components/CohortDetail/CohortDetail')
);
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};

const DataCohort = (props) => {
	const [doc_name, setDocName] = useState('');
	const [content_type, setContentType] = useState('');

	useEffect(() => {
		let { docId } = props.match.params;
		getSubdocumentById(docId).then((resp) => {
			setDocName(resp?.data?.data?.name_with_date);
			setContentType(resp?.data?.data?.content_type);
		});
		
		if ((props?.cohort?.documentCohorts && Object.keys(props?.cohort?.documentCohorts).length === 0) || !props?.cohort?.documentCohorts) {
			getPageData();
			setIsLoading(false);
		}
	}, []);

	const getPageData = () => {
		setIsLoading(true);
		getCohortListForDocument(props.match?.params?.docId);
		getCohortListForConcept();
		getCohortList();
	};

	const gotoDatadetail = () => {
		props.history.push(constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId);
	};

	let patientConcepts = [];
	let patientids = [];
	let { loading } = props.loading;
	props?.cohort?.documentCohorts &&
	props?.cohort?.documentCohorts.map((concepts) => {
			if (patientids && patientids.indexOf(concepts.patient_id) != -1) {
				patientConcepts.map((pconcept) => {
					if (pconcept.patient_id == concepts.patient_id) {
						pconcept.patient_concepts.push(concepts);
					}
				});
			} else {
				patientids.push(concepts.patient_id);
				patientConcepts.push({
					patient_id: concepts.patient_id,
					patient_concepts: [concepts],
				});
			}
		});

	patientConcepts &&
		patientConcepts.map((patient) => {
			patient.cohorts = [];
			patient.patient_concepts &&
				patient.patient_concepts.map((concept) => {
					let ifExitsCohort = patient.cohorts.filter((cohort) => {
						return cohort.cohort_id == concept.cohort_id;
					});
					if (!ifExitsCohort.length) {
						patient.cohorts.push({
							cohort_id: concept.cohort_id,
							cohort_name: concept.cohort_name,
						});
					}
				});
		});

	//--- inserting concept in cohorts
	patientConcepts.map((patient) => {
		patient.cohorts.map((cohort) => {
			let concepts = patient.patient_concepts.filter((concept) => {
				return concept.cohort_id == cohort.cohort_id;
			});
			cohort['concepts'] = concepts;
		});
	});
	return (
		<div className="DataManual">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles data={headerData} history={props.history} match={props.match} />
			</ErrorBoundary>
			<div className="document-header-container">
				<span> Cohorts: </span>
				<a onClick={gotoDatadetail}>{doc_name}</a>
			</div>
			<div className="section-header">Cohorts</div>
			<div className="bg-dark-first" style={{ minHeight: 400, position: 'relative' }}>
				{patientConcepts &&
					patientConcepts.length > 0 &&
					patientConcepts[0].cohorts.length > 0 &&
					patientConcepts[0].cohorts.map((cohort, index) => (
						<div key={index} className="v-title-wrapper">
							<div className="v-col-link">
								<ErrorBoundary>
									<Suspense fallback={<div className="defaultSpinner" />}>
										<CohortDetail cohort1={cohort} />
									</Suspense>
								</ErrorBoundary>

								{/* {cohort.hasManAnnotation && (
									<ErrorBoundary>
										<ManualTooltipScroll
											annotationType="cohort"
											goToDataDetail={gotoDataDetail}
											cohortId={cohort.cohort_id}
										/>
									</ErrorBoundary>
								)} */}
							</div>
						</div>
					))}
			</div>
			<Copyright />
		</div>
	);
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DataCohort);
