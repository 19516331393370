import React from 'react';
import { ButtonToolbar, DropdownButton, Dropdown } from 'react-bootstrap';
import { getOptions } from '../../common/Header/headerFuns';
import constants from '../../constants';

const HamburgerMenu = ({ options, handleMenuClick, userRole, noMenu, docType, props }) => {
	let hamburgerOptions = [];
	
	if (userRole && userRole.Role) {
		
		if (options) {
			hamburgerOptions = options;
		} else {
			const options = getOptions(window.location.pathname || '', userRole.Role || '', noMenu, docType, props);
			hamburgerOptions = [ ...options ];
		}
	}
	return (
		<div className="submenu hamburgermenu">
			<ButtonToolbar>
				<DropdownButton
					title={
						<span className="header-menu">
							<i className="fas fa-bars" />
						</span>
						// <span>Hello</span>
					}
					alignRight
					id="dropdown-no-caret"
					onSelect={(eventKey) => handleMenuClick(eventKey)}
				>
					{hamburgerOptions.map((option, key) => {
						if(option.eventKey==26 && props?.relatedEncounter?.length==1){
							return(
								<a key={key} className="dropdown-item" href={constants.ROUTE_URL + "/dashboard/document/" + props?.relatedEncounter[0]?.subdocument_id}>
									{option.label}
                                </a>
							)
						} else{
							return(
								option.divider ? (
									<Dropdown.Divider key={key} />
								) : (
									<Dropdown.Item
										eventKey={option.eventKey}
										className={option.active ? 'selected' : ''}
										key={key}
									>
										{option.label}
									</Dropdown.Item>
								)
							)
						}
						
					})}
				</DropdownButton>
			</ButtonToolbar>
		</div>
	);
};

export default HamburgerMenu;
