import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import constants from '../../constants';
import { Alert, Table } from 'react-bootstrap';
import auth0 from 'auth0-js';
import modalConstants from '../../common/Header/modalConstants';
import SearchComponent from '../../common/SearchComponent/SearchComponent';
import { filterList } from '../../utilities/utils';
import HamburgerMenu from '../../common/Header/HamburgerMenu';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import ErrorBoundary from '../ErrorBoundary';
import { FetchUserList, getUserPermissions } from './UserDataHelper';
import removeMinusIcon from '../../images/remove_minus_icon.svg';

const options = [
	{ label: 'Administrator', value: 'Administrator' },
	{ label: 'Company', value: 'Company' },
	{ label: 'User', value: 'User' },
];

function UserData(props) {
	const [activeView, setActiveView] = useState(
		props.activeTab ? props.activeTab : { id: 1, title: 'User list' }
	);
	const [err, setErr] = useState('');
	const [showSearch, setShowSearch] = useState(
		props.showSearchMode ? props.showSearchMode : false
	);
	const [userSearchQuery, setUserSearchQuery] = useState('');
	const [menuOptions, setMenuOptions] = useState([]);
	const [userList, setUserList] = useState(props.customUser ? props.customUser : []);
	const [deleteMode, setDeleteMode] = useState(props.deleteStatus ? props.deleteStatus : false);
	const [selectedUser, setSelectedUser] = useState(
		props.selectedUserMode
			? props.selectedUserMode
			: {
					firstName: '',
					lastName: '',
					email: '',
					phone: '',
					location: '',
					access: '',
			  }
	);
	const sidenavProps = useSelector((state) => state.sidenav);

	useEffect(() => {
		FetchUserList().then((res) => {
			setUserList(res.data.data);
		});
		//SET INITIAL MENU OPTION IN HAMBURGER MENU.
		const _menuOptions = getMenuOptions(1);
		setMenuOptions(_menuOptions);
	}, []);

	const showUserDetail = (user) => {
		//MAP SAME OBJECT FOR DETAIL & EDIT
		const userObj = {
			firstName: user.first_name,
			lastName: user.last_name,
			email: user.email,
			phone: user.phone_number,
			access: user.access,
		};

		const _menuOptions = getMenuOptions(3);

		setSelectedUser(userObj);
		setActiveView({ id: 3, title: 'User detail' });
		setMenuOptions(_menuOptions);
		setShowSearch(false);

		//GET USER ACCESS TYPE.
		getUserPermissions(user.user_id).then((res) => {
			selectedUser.access = res.data.data[0].access;
			setSelectedUser(selectedUser);
		});
	};

	const handleOptionSelect = (option) => {
		let _activeView = {};

		if (option === modalConstants.userList) {
			_activeView = { id: 1, title: 'User list' };
		} else if (option === modalConstants.addUser) {
			_activeView = { id: 2, title: 'User add' };
		} else if (option === modalConstants.editUser) {
			addNewUserDetail();
			_activeView = { id: 2, title: 'User edit' };
		} else if (option === modalConstants.searchUser) {
			setShowSearch(true);
		} else if (option === modalConstants.deleteUser) {
			setDeleteMode(!deleteMode);
		}

		if (_activeView.hasOwnProperty('id')) {
			const _menuOptions = getMenuOptions(_activeView.id);
			setActiveView(_activeView);
			setMenuOptions(_menuOptions);
			setShowSearch(false);
		}
	};

	const getMenuOptions = (viewId) => {
		let menus = [];
		if (viewId === 1) {
			menus = [
				{ eventKey: modalConstants.addUser, label: 'Add user' },
				{ eventKey: modalConstants.deleteUser, label: 'Delete user' },
				{ eventKey: modalConstants.searchUser, label: 'Search users' },
				{ eventKey: modalConstants.userList, label: 'User list' },
			];
		} else if (viewId === 3) {
			menus = [
				{ eventKey: modalConstants.editUser, label: 'Edit user' },
				{ eventKey: modalConstants.deleteUser, label: 'Delete user' },
				{ eventKey: modalConstants.userList, label: 'User list' },
			];
		}
		return menus;
	};

	const searchUser = (event) => {
		setUserSearchQuery(event.target.value);
	};

	const viewUserList = () => {
		const columns = [{ dataKey: 'first_name' }, { dataKey: 'last_name' }];
		const sortedUserList = filterList(userSearchQuery, userList, columns);
		return (
			<div>
				<Table hover className="modal-data-table tbl-tech-detail">
					<thead className="thead-light">
						<tr>
							<th>User</th>
							<th>Email</th>
							<th>Phone</th>
							<th>Role</th>
						</tr>
					</thead>
					<tbody>
						{sortedUserList &&
							sortedUserList.map((user, index) => {
								return (
									<tr key={index}>
										<td>
											{deleteMode && (
												<span>
													<img
														src={
															removeMinusIcon
														}
														className="App-minusIcon"
														alt="minusIcon"
														width={20}
													/>
													<span style={{ padding: 4 }}></span>
												</span>
											)}
											<span
												onClick={() => showUserDetail(user)}
												className="hyperlink"
												id="show-user-details-id"
											>
												{user.first_name + ' ' + user.last_name}
											</span>
										</td>
										<td>{user.email}</td>
										<td>{user.phone_number}</td>
										<td>{user.access}</td>
									</tr>
								);
							})}
					</tbody>
				</Table>
			</div>
		);
	};

	const cancelUserData = () => {
		const _menuOptions = getMenuOptions(1);
		setActiveView({ id: 1, title: 'User list' });
		setErr('');
		setMenuOptions(_menuOptions);
		setSelectedUser({
			firstName: '',
			lastName: '',
			email: '',
			phone: '',
			location: '',
			access: '',
		});
	};

	const saveUserData = () => {
		// Generating random dummy password
		const randomPassword = 'A@' + Math.random().toString(36).slice(2);
		const { firstName, lastName, email, phone, location, access } = selectedUser;
		// Validating the user details
		if (!firstName) {
			setErr('First name is required.');
			return;
		}
		if (!lastName) {
			setErr('Last name is required.');
			return;
		}
		if (!email.trim()) {
			setErr('Email is required.');
			return;
		}
		if (email) {
			//Checking the email pattern entered by the user
			let emailReg = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
			let res = emailReg.test(email);
			if (!res) {
				setErr('Provide a valid email.');
				return;
			}
		}
		if (!phone) {
			setErr('Phone is required.');
			return;
		}
		if (!access) {
			setErr('Role is required.');
			return;
		}

		// For Auth0 Access
		if (constants.ALLOW_AUTH0_ACCESS_URL == 'Y') {
			//Assigning Auth0 credentials from the config file
			const webAuth = new auth0.WebAuth({
				domain: constants.AUTH0_DOMAIN,
				clientID: constants.AUTH0_CLIENT_ID,
			});

			// This method will add the user details in Auth0 application
			webAuth.signup(
				{
					connection: 'Username-Password-Authentication',
					email: email,
					password: randomPassword,
					email_verified: 'true',
					verify_email: 'false',
					phone_number: phone,
					phone_verified: 'true',
					user_metadata: {
						name: firstName + ' ' + lastName,
						// location:location,
						role: access.value,
					},
				},
				(err, authResult) => {
					if (err) {
						setSelectedUser({
							firstName: '',
							lastName: '',
							email: '',
							phone: '',
							location: '',
							access: '',
						});
						setErr(false);
					}
				}
			);
		}
	};

	const updateValue = (e) => {
		setSelectedUser({
			...selectedUser,
			[e.target.name]: e.target.value,
		});
	};

	const handleRoleChange = (value) => {
		selectedUser.access = value;
		const _selectedUser = Object.assign({}, selectedUser);
		setSelectedUser(_selectedUser);
	};

	const addNewUserDetail = () => {
		const { firstName, lastName, email, phone, access } = selectedUser;

		return (
			<div style={{ marginLeft: 32, paddingTop: 6 }}>
				<div>
					<label className="user-label">First name:</label>
					<input
						type="text"
						name="firstName"
						className="user-form-control text-input"
						value={firstName}
						onChange={updateValue}
					></input>
				</div>
				<div>
					<label className="user-label">Last name:</label>
					<input
						type="text"
						name="lastName"
						className="user-form-control text-input"
						value={lastName}
						onChange={updateValue}
					></input>
				</div>
				<div>
					<label className="user-label">Email:</label>
					<input
						type="text"
						name="email"
						className="user-form-control text-input"
						value={email}
						onChange={updateValue}
					></input>
				</div>
				<div>
					<label className="user-label">Phone:</label>
					<input
						type="text"
						name="phone"
						className="user-form-control"
						value={phone}
						onChange={updateValue}
					></input>
				</div>
				<div style={{ width: '59%' }}>
					<label className="user-label" style={{ display: 'inline-block' }}>
						Access:
					</label>
					<div style={{ display: 'inline-block', width: '68%' }}>
						<CustomSelect
							value={access}
							onChange={handleRoleChange}
							options={options}
							placeholder=""
							className="select-role"
							searchable={false}
							clearable={false}
						/>
					</div>
				</div>
				<div className="trialButton addNewTrial">
					<button
						type="button"
						className="trialArm"
						onClick={() => cancelUserData()}
					>
						Cancel
					</button>
					<button type="button" className="trialArm" onClick={() => saveUserData()}>
						Save
					</button>
				</div>

				{err ? (
					<div className="user-error_field">
						<Alert bsstyle="danger" variant="danger">
							{err}
						</Alert>
					</div>
				) : null}
			</div>
		);
	};

	return (
		<div>
			<div className="modal-heading headerbar row product-setting">
				<div
					style={{
						display: 'inline-block',
						width: '50%',
						paddingTop: 4,
						paddingLeft: 0,
						margin: 0,
					}}
				>
					{activeView.title}
				</div>
				<div style={{ display: 'inline-block', width: '15%' }}></div>
				<div style={{ display: 'inline-block', width: '30%' }}>
					{showSearch && (
						<div
							className="searchContainer"
							style={{ width: 240, position: 'relative' }}
						>
							<ErrorBoundary>
								<SearchComponent title="users" searchFunction={searchUser} />
							</ErrorBoundary>
						</div>
					)}
				</div>
				{menuOptions.length > 0 && (
					<div style={{ display: 'inline-block', width: '5%', textAlign: 'right' }}>
						<ErrorBoundary>
							<HamburgerMenu
								userRole={sidenavProps.userRole}
								options={menuOptions}
								handleMenuClick={handleOptionSelect}
							/>
						</ErrorBoundary>
					</div>
				)}
			</div>
			<div className="modal-container">
				<div>{activeView.id === 1 && viewUserList()}</div>
				<div>{activeView.id === 2 && addNewUserDetail()}</div>
				<div>{activeView.id === 3 && <UserDetail user={selectedUser} />}</div>
			</div>
		</div>
	);
}

UserData.propTypes = {
	activeTab: PropTypes.any,
	customUser: PropTypes.any,
	deleteStatus: PropTypes.any,
	selectedUserMode: PropTypes.any,
	showSearchMode: PropTypes.any,
};

export default UserData;

const UserDetail = ({ user }) => (
	<div>
		<table style={{ marginLeft: 20 }}>
			<tbody>
				<tr>
					<td>First Name:</td>
					<td>{user.firstName}</td>
				</tr>
				<tr>
					<td>Last Name:</td>
					<td>{user.lastName}</td>
				</tr>
				<tr>
					<td>Email:</td>
					<td>{user.email}</td>
				</tr>
				<tr>
					<td>Phone:</td>
					<td>{user.phone}</td>
				</tr>
				<tr>
					<td>Access:</td>
					<td>{user.access}</td>
				</tr>
			</tbody>
		</table>
	</div>
);
