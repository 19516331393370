import { apiGet } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';

export function FetchUserList() {
	
	return new Promise((resolve, reject) => {
		apiGet('FetchUserList')
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
				console.log(err);
			});
	});
}

export function getUserPermissions(user_id) {
	return new Promise(function (resolve, reject) {
		apiGet('FetchUserPermission', {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_USER_PERMISSION,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
