import React, { useState, useEffect, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import constants from '../../constants';
import { formatDate } from '../../utilities/utils';
import { sortCriteria, gotoCohortList } from '../CohortList/CohortHelper';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import { useSelector } from 'react-redux';
import {
	getCohortList,
	getCohortCriteria,
	searchConceptsById,
	setCriterion_Concepts,
	setCriteriaData,
	deleteCohortApi,
	confirmCohortDelete,
	getCohortOutcomes,
} from './EditCohortContainerHelper';

const EditCohortComponent = lazy(() =>
	import(
		/* webpackChunkName: "EditCohortComponent" */ '../../components/EditCohort/EditCohortComponent'
	)
);
import cohortIcon from '../../images/cohort_icon.png';

const headerData = {
	title: 'Cohort',
	list: 'edit',
	img: (
		<img
			src={cohortIcon}
			className={'App-CohortList'}
			alt={'Cohort List'}
		></img>
	),
};

function EditCohort(props) {
	const [cohortId, setCohortId] = useState(-1);
	const [infoFetched, setInfoFetched] = useState(false);
	const [cohortName, setCohortName] = useState('');
	const [selectedOutcomeIds, setSelectedOutcomeIds] = useState(['']);
	const [criterionData, setCriterionData] = useState([]);
	const [criterionArr, setCriterionArr] = useState([]);
	const [dates, setDates] = useState([]);
	const [duplicateCohortError, setDuplicateCohortError] = useState('');
	const [showLoading, setShowLoading] = useState(true);
	const confirmDelete = useSelector((state) => state.cohort)?.confirmDelete;

	useEffect(() => {
		const _cohortId = props.match.params.cohort_id;
		getCohortList().then((res) => {
			const _cohortName =
				res?.data?.data.find((cohort) => cohort?.cohort_id == _cohortId)?.cohort_name || '';
			setCohortName(_cohortName);
			setCohortId(_cohortId);
		});

		// Get outcome event ids
		getCohortOutcomes(_cohortId).then((res) => {
			const _selectedOutcomeIds = (res.data?.payload?.outcomes &&
				res.data.payload?.outcomes.map((event) => ({ id: event.event_id.toString() }))) || [
				'',
			];
			setSelectedOutcomeIds(_selectedOutcomeIds);
		});

		getCohortCriteria(_cohortId).then((res) => {
			if (res.data.data) {
				let criteria = res.data.data;
				let conceptIds = [];
				let eventIds = [];
				criteria.map((criterion) => {
					if (criterion.criteria_type == 'EventId') {
						eventIds.push(criterion.event_id);
					} else {
						conceptIds.push(criterion.concept_id);
					}
				});

				searchConceptsById(conceptIds, eventIds.join(',')).then(
					(criteriaConcepts) => {
						criteria.map((criterion) => {
							let associatedConcept;
							if (criterion.criteria_type == 'EventId') {
								associatedConcept = criteriaConcepts.filter(
									(items) => items.event_id == criterion.event_id
								)[0];
							} else {
								associatedConcept = criteriaConcepts.filter(
									(items) => items.cuid == criterion.concept_id
								)[0];
							}
							return (criterion.concepts = associatedConcept);
						});

						// SORT BY CRITERIA
						const sortedData = sortCriteria(criteria);

						setCriterion_Concepts(sortedData);

						sortedData &&
							sortedData.map((criteria, index) => {
								if (
									criteria.is_index_event == 'false' &&
									criteria.is_inclusion == 'true'
								) {
									addCriterionDataArr(
										'Inclusion Criterion',
										criteria?.concepts?.text,
										criteria?.concept_id
									);

									addCriterionArr(false, true, 'Inclusion criterion', {
										value: criteria?.concepts?.text,
										label: criteria?.concepts?.text,
										conceptID: criteria?.concept_id,
									});
								} else if (
									criteria.is_index_event == 'false' &&
									criteria.is_inclusion == 'false'
								) {
									addCriterionDataArr(
										'Exclusion criterion',
										criteria.concepts.text,
										criteria.concept_id
									);

									addCriterionArr(false, true, 'Exclusion criterion', {
										value: criteria.concepts.text,
										label: criteria.concepts.text,
										conceptID: criteria.concept_id,
									});
								} else {
									addCriterionDataArr(
										'Index event',
										criteria.concepts.text,
										criteria.concept_id
									);

									addCriterionArr(false, true, 'Index event', {
										value: criteria.concepts.text,
										label: criteria.concepts.text,
										conceptID: criteria.concept_id,
									});
								}
								if (
									criteria.criterion_absolute_start_date &&
									criteria.criterion_absolute_end_date
								) {
									addDatesArr(
										formatDate(
											criteria.criterion_absolute_start_date,
											'YYYY-MM-DD'
										),
										formatDate(
											criteria.criterion_absolute_end_date,
											'YYYY-MM-DD'
										),
										criteria.concepts?.text,
										criteria.concept_id
									);
								}
							});

						if (dates.length == 0) {
							addDatesArr('', '', '', '');
						}

						setCriteriaData(criterionData);
						setInfoFetched(true);
						setShowLoading(false);
					}
				);
			} else {
				setShowLoading(false);
			}
		});
	}, []);

	const addCriterionDataArr = (type, criteria, conceptID) => {
		criterionData.push({
			id: criterionData?.length,
			type: type,
			criteria: criteria,
			conceptID: conceptID,
		});

		const _criterionData = Object.assign([], criterionData);
		setCriterionData(_criterionData);
	};

	const addCriterionArr = (show1, show2, criterion, value) => {
		criterionArr.push({
			show2: show2,
			show1: show1,
			value: value,
			id: criterionArr?.length,
			criterion: criterion,
			key: criterionArr?.length,
		});
		const _criterionArr = Object.assign([], criterionArr);
		setCriterionArr(_criterionArr);
	};

	const addDatesArr = (start, end, criteria, conceptID) => {
		dates.push({
			id: dates.length + 1,
			start: start,
			end: end,
			criteria: criteria,
			conceptID: conceptID,
		});
		const _dates = Object.assign([], dates);
		setDates(_dates);
	};

	const deleteCohort = (cohortId) => {
		deleteCohortApi(cohortId).then(
			(res) => {
				if (res.data.code === 200) {
					gotoCohortList();
				} else if (res.data.code === 400) {
					setDuplicateCohortError(res.data.description);
				}
			},
			(error) => {
				if (error.response.data.code === 400) {
					setDuplicateCohortError(error.response.data.description);
				}
				// Hide confirm modal
			}
		);
		confirmCohortDelete(false);
	};

	return (
		<div>
			<HeaderTitles data={headerData} search={false} />
			<div>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<EditCohortComponent
						cohortId={cohortId}
						cohortName={cohortName}
						selectedOutcomeIds={selectedOutcomeIds}
						criterionData={criterionData}
						criterionArr={criterionArr}
						infoFetched={infoFetched}
						dates={dates}
						duplicateCohortError={duplicateCohortError}
					/>
				</Suspense>
			</div>
			{confirmDelete && (
				<ModalComponent
					open={true}
					content={
						<div className="jconfirm-box-container">
							<div className="jconfirm-content-pane">
								<h4>Are you sure you want to delete?</h4>
							</div>
							<div className="jconfirm-content-pane">
								<div className="jconfirm-buttons">
									<button
										className="btn btn-default"
										onClick={() => {
											confirmCohortDelete();
										}}
									>
										Cancel
									</button>
									<button
										className="btn btn-default"
										onClick={() => deleteCohort(cohortId)}
									>
										Ok
									</button>
								</div>
							</div>
						</div>
					}
					className="testClass"
				/>
			)}
		</div>
	);
}

EditCohort.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			cohort_id: PropTypes.any,
		}),
	}),
};

export default EditCohort;
