import { apiGet } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';

export function getUserBatches(query) {
	return new Promise((resolve, reject) => {
		apiGet('GetUserBatches', query)
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getUserProject(query) {
	return new Promise((resolve, reject) => {
		apiGet('GetProjects', query)
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function openModal(modalType) {
	store.dispatch({
		type: actionTypes.OPEN_MODAL_POPUP,
		payload: modalType,
	});
}
