/**
 * CONTAINS HELPER FUNCTIONS FOR HEADER
 */
import constants from '../../constants';
import modalConstants from './modalConstants';
import { roleAccess } from '../../utilities/AppConstants';
import queryString from 'query-string';
import { getObjectFromLocal } from '../../utilities/utils';
/****************************************
* Function Name: pathWithoutSlashes
* Description: It remove all forward slashes from string.
* @param - str - string
****************************************/
export const pathWithoutSlashes = (str) => {
	return str.replace(/\//g, '');
};

/****************************************
* Function Name: getOptions
* Description: It determines hamburger menu options based on path provided.
* @param - path - string path of current route.
****************************************/
export const getOptions = (path, userRole, noMenu, doc_type, props) => {
	if (noMenu) {
		return [];
	}
	let options = [];
	
	if (path == constants.ROUTE_URL + '/dashboard/studylist') {
		options = [ { eventKey: '1', label: 'Edit study list' }, { eventKey: 'SHOW_SEARCH', label: 'Search studies' } ];
	} else if (path.indexOf('/dashboard/studyvalidity') !== -1 && path.indexOf('/dashboard/studyedit') === -1) {
		switch (userRole) {
			case roleAccess.ANNOTATOR:
				options = [
					{ eventKey: '2', label: 'Study design' },
					{ eventKey: '3', label: 'Study results' },
					{ divider: 'true' },
					{ eventKey: '6', label: 'Edit study validity report' }
				];
				break;
			default:
				options = [
					{ eventKey: '2', label: 'Study design' },
					{ eventKey: '3', label: 'Study results' },
					{ eventKey: '4', label: 'Study validity', active: true },
					{ divider: 'true' },
					{ eventKey: '6', label: 'Edit study validity report' }
				];
				break;
		}
	} else if (path.indexOf('/dashboard/studyresult') !== -1 && path.indexOf('/dashboard/studyedit') === -1) {
		switch (userRole) {
			case roleAccess.ANNOTATOR:
				options = [
					{ eventKey: '2', label: 'Study design' },
					{ eventKey: '3', label: 'Study results', active: true },
					{ divider: 'true' },
					{ eventKey: '5', label: 'Edit study design' }
				];
				break;
			default:
				options = [
					{ eventKey: '2', label: 'Study design' },
					{ eventKey: '3', label: 'Study results', active: true },
					{ eventKey: '4', label: 'Study validity' },
					{ divider: 'true' },
					{ eventKey: '5', label: 'Edit study design' }
				];
				break;
		}
	} else if (path.indexOf('/dashboard/study') !== -1 && path.indexOf('/dashboard/studyedit') === -1) {
		switch (userRole) {
			case roleAccess.ANNOTATOR:
				options = [
					{ eventKey: '2', label: 'Study design', active: true },
					{ eventKey: '3', label: 'Study results' },
					{ divider: 'true' },
					{ eventKey: '5', label: 'Edit study design' }
				];
				break;
			default:
				options = [
					{ eventKey: '2', label: 'Study design', active: true },
					{ eventKey: '3', label: 'Study results' },
					{ eventKey: '4', label: 'Study validity' },
					{ divider: 'true' },
					{ eventKey: '5', label: 'Edit study design' }
				];
				break;
		}
	} else if (path.indexOf('/dashboard/studyedit') !== -1) {
		options = [ { eventKey: '2', label: 'Study design' } ];
	} else if (path.indexOf('/dashboard/addstudy') !== -1) {
		options = [ { eventKey: '23', label: 'Study list' } ];
	} else if (path.indexOf('/dashboard/editstudyvalidity') !== -1) {
		options = [
			{ eventKey: '2', label: 'Study design' },
			{ eventKey: '3', label: 'Study results' },
			// { eventKey: "4", label: "Study validity" },
		];
	} else if (path.indexOf('/dashboard/twoclassdrilldown') !== -1) {
		options = [
			{ eventKey: '2', label: 'Study design' },
			{ eventKey: '3', label: 'Study results' },
			{ eventKey: "4", label: "Study validity" },
		];
	} else if (path.indexOf('/dashboard/conceptKappa') !== -1) {
		options = [
			{ eventKey: '2', label: 'Study design' },
			{ eventKey: '3', label: 'Study results' },
			{ eventKey: '4', label: 'Study validity' },
			{ divider: 'true' },
			{ eventKey: '6', label: 'Edit study validity report' }
		];
	} else if (path.indexOf('/dashboard/cohortdesign') !== -1) {
		options = [
			{ eventKey: '', label: 'Cohort design', active: true },
			{ eventKey: '9', label: 'Cohort results' },
			{ divider: 'true' },
			{ eventKey: '10', label: 'Edit cohort' }
		];
	} else if (path.indexOf('/dashboard/cohortresult') !== -1) {
		options = [
			{ eventKey: '8', label: 'Cohort design' },
			{ eventKey: '', label: 'Cohort results', active: true },
			{ divider: 'true' },
			{ eventKey: '10', label: 'Edit cohort' }
		];
	} else if (path.indexOf('/dashboard/cohort') !== -1 && path.indexOf('dashboard/cohort/edit') === -1) {
		options = [
			{ eventKey: '7', label: 'Edit cohort list' },
			{ eventKey: 'SHOW_SEARCH', label: 'Search cohorts' }
		];
	} else if (path.indexOf('/dashboard/cohort/edit') !== -1) {
		options = [ { eventKey: '8', label: 'Cohort design' } ];
	} else if (path.indexOf('/dashboard/addcohort') !== -1) {
		options = [ { eventKey: '24', label: 'Cohort list' } ];
	} else if (path.indexOf('/dashboard/patientlist') !== -1) {
		options = [
			{ eventKey: modalConstants.dataSetting, label: 'Add patient' },
			{ eventKey: 'SHOW_SEARCH', label: 'Search patients' }
		];
	} else if (path.indexOf('/dashboard/patient/') !== -1) {
		options = [ { eventKey: '25', label: 'Patient list' } ];
	} else if (path.indexOf('/dashboard/datalist') != -1) {
		switch (userRole) {
			case roleAccess.ANNOTATOR:
				options = [ { eventKey: '20', label: 'Show work queue' } ];
				break;
			default:
				options = [
					{ eventKey: modalConstants.dataSetting, label: 'Add data' },
					{ eventKey: 'SHOW_SEARCH', label: 'Search data' }
				];
				break;
		}
	} else if (path.indexOf('/dashboard/technology') !== -1) {
		options = [ { eventKey: 'SHOW_SEARCH', label: 'Search technologies' } ];
	} else if (path.indexOf('/dashboard/document') !== -1) {
		if (doc_type == 'discrete') {
			if (props?.relatedEncounter?.length > 0) {
				options = [ { eventKey: '26', label: 'View narrative data' } ];
			} else {
				options = [ { eventKey: '26', label: 'No narrative data' } ];
			}
		} else {
			if (userRole != roleAccess.ANNOTATOR) {
				if (props?.relatedEncounter?.length > 0) {
					options.push({ eventKey: '26', label: 'View structured data' });
				} else {
					options.push({ eventKey: '26', label: 'No structured data' });
				}
			}
			options.push({ eventKey: '11', label: 'Automated annotations' });
			options.push({ eventKey: '12', label: 'Manual annotations' });
			if(userRole === roleAccess.ANNOTATOR && !getObjectFromLocal('fromEditMultipleManual') && !getObjectFromLocal('fromEditManual')){
				options.push({ eventKey: '36', label: 'Edit muliple manual annotations' });
			}
			if (userRole != roleAccess.ANNOTATOR) {
				options.push({ eventKey: '13', label: 'Cohorts' });
			}
		}
	} else if (path.indexOf('/dashboard/datamanual') !== -1) {
		switch (userRole) {
			case roleAccess.ADMINISTRATOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '27', label: 'Derived manual annotations' }
				];
				break;
			case roleAccess.USER:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '27', label: 'Derived manual annotations' }
				];
				break;
			case roleAccess.SUPERVISOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '17', label: 'Review conflicts' },
					{ eventKey: '27', label: 'Derived manual annotations' }
				];
				break;
			case roleAccess.ANNOTATOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '15', label: 'Edit manual annotations' },
					{ eventKey: '27', label: 'Derived manual annotations' },
					// { eventKey: '16', label: 'Rapid manual annotations' },
					{ eventKey: '17', label: 'Review conflicts' }
				];
				break;
			default:
				options = [ { eventKey: '14', label: 'View narrative' } ];
				break;
		}
	} else if (path.indexOf('/dashboard/editdatamanual') !== -1) {
		switch (userRole) {
			case roleAccess.SUPERVISOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					// { eventKey: '16', label: 'Rapid manual annotations' },
					{ eventKey: '17', label: 'Review conflicts' }
				];
				break;
			case roleAccess.ANNOTATOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					// { eventKey: '16', label: 'Rapid manual annotations' },
					{ eventKey: '17', label: 'Review conflicts' }
				];
				break;
			default:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					// { eventKey: '16', label: 'Rapid manual annotations' }
				];
				break;
		}
	} else if (path.indexOf('/dashboard/datacohort') !== -1) {
		options = [ { eventKey: '14', label: 'View narrative' } ];
	} else if (path.indexOf('/dashboard/dataautomated') !== -1) {
		options = [ { eventKey: '14', label: 'View narrative' },
		{ eventKey: '35', label: 'Derived automated annotations' } ];
	} else if(path.indexOf('/dashboard/derivedautomatedannotation/') !== -1) {
		options = [ { eventKey: '14', label: 'View narrative' }]
	}else if (path.indexOf('/dashboard/datareview') !== -1) {
		switch (userRole) {
			case roleAccess.SUPERVISOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '18', label: 'Resolve conflicts' },
					{ eventKey: '19', label: 'See all conflicts in the study' }
				];
				break;
			case roleAccess.ANNOTATOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
				];
				break;
			default:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '19', label: 'See all conflicts in the study' }
				];
				break;
		}
	} else if (path.indexOf('/dashboard/dataplareview') !== -1) {
		switch (userRole) {
			case roleAccess.SUPERVISOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '31', label: 'Resolve conflicts' },
					{ eventKey: '19', label: 'See all conflicts in the study' }
				];
				break;
			case roleAccess.ANNOTATOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
				];
				break;
			default:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '19', label: 'See all conflicts in the study' }
				];
				break;
		}
	} else if (path.indexOf('/dashboard/dataplaresolve') !== -1) {
		options = [
			{ eventKey: '14', label: 'View narrative' },
			{ eventKey: '29', label: 'Review conflicts' }
			// { eventKey: "19", label: "See all conflicts in the study"}
		];
		// switch (userRole) {
		//     case roleAccess.SUPERVISOR:
		//             options = [
		//                 { eventKey: "14", label: "View narrative" } ,
		//                 { eventKey: "31", label: "Resolve conflicts" },
		//                 { eventKey: "19", label: "See all conflicts in the study"}
		//             ]
		//         break;
		//     default:
		//         options = [
		//             { eventKey: "14", label: "View narrative" } ,
		//             { eventKey: "19", label: "See all conflicts in the study"}
		//         ]
		//         break;
		// }
	} else if (path.indexOf('/dashboard/datarapid') !== -1) {
		options = [ { eventKey: '14', label: 'View narrative' } ];
	} else if (path.indexOf('/dashboard/dataworkqueue') !== -1) {
		options = [ { eventKey: '21', label: 'Show total queue' } ];
	} else if (path.indexOf('/dashboard/datastudyreview') !== -1) {
		options = [];
		let initial = queryString.parse(window.location.search).initial;
		let concept_id = queryString.parse(window.location.search).concept_id;

		try {
			if (props.match && props.match.params && props.match.params.docId == 0) {
			} else {
				options.push({ eventKey: '14', label: 'View narrative' });
				switch (userRole) {
					case roleAccess.SUPERVISOR:
						options.push({ eventKey: '22', label: 'Resolve conflicts' });
						break;
					default:
						break;
				}
			}
		} catch (err) {
			console.log(err);
		}

		if (initial && concept_id) {
			options.push({ eventKey: '19', label: 'See all conflicts in the study' });
		}
	} else if (path.indexOf('/dashboard/datastudyresolve') !== -1) {
		options = [
			// { eventKey: "14", label: "View narrative" } ,
			{ eventKey: '19', label: 'See all conflicts in the study' }
		];
	} else if (path.indexOf('/dashboard/dataresolve') !== -1) {
		options = [ { eventKey: '17', label: 'Review conflicts' } ];
	} else if (path.indexOf('/dashboard/dataplastudyreview') !== -1) {
		switch (userRole) {
			case roleAccess.SUPERVISOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '29', label: 'Review conflicts' },
					{ eventKey: '30', label: 'Resolve see all conflicts in the study' }
				];
				break;
			default:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '29', label: 'Review conflicts' }
				];
				break;
		}
	} else if (path.indexOf('/dashboard/dataplastudyresolve') !== -1) {
		options = [ { eventKey: '14', label: 'View narrative' }, { eventKey: '29', label: 'Review conflicts' } ];
	} else if (path.indexOf('/dashboard/patientlevelannotation') !== -1) {
		switch (userRole) {
            case roleAccess.ADMINISTRATOR:
				options = [
					{ eventKey: '14', label: 'View narrative' }
				];
				break;
			case roleAccess.USER:
				options = [
					{ eventKey: '14', label: 'View narrative' }
				];
				break;
			case roleAccess.SUPERVISOR:
				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '29', label: 'Review conflicts' }
				];
				break;
			case roleAccess.ANNOTATOR:
				const editMode = sessionStorage.getItem('editModeDataPatientLevelAnnotation');
				const editOption= { eventKey: '28', label: 'Edit derived manual annotations' };

				options = [
					{ eventKey: '14', label: 'View narrative' },
					{ eventKey: '12', label: 'View manual annotations' },
					{ eventKey: '29', label: 'Review conflicts' }
				];
				if(!editMode){
					options.push(editOption)
				}
				break;
			default:
				options = [ { eventKey: '14', label: 'View narrative' } ];
				break;
		}
	} else if (path.indexOf('/dashboard/conceptsupport') !== -1) {
		options = [ { eventKey: '32', label: 'View narrative' } ];

		if (props.viewMode) {
			options.push({ eventKey: '33', label: 'Show details' });
		} else {
			options.push({ eventKey: '33', label: 'Hide details' });
		}
	} else if (path.indexOf('/dashboard/demographicdata') !== -1) {
		options = [ { eventKey: '34', label: 'View patient detail' } ];
	}

	return options;
};

/****************************************
* Function Name: getIconName
* Description: It returns header icon based on path provided.
* @param - path - string path of current route.
****************************************/
export const getIconName = (path) => {
	let iconName = '';
	if (path?.indexOf('data') != -1) {
		iconName = 'icon_data';
	} else if (path?.indexOf('patient') != -1) {
		iconName = 'icon_patient';
	} else if (path?.indexOf('cohort') != -1) {
		iconName = 'icon_cohort';
	} else if (path?.indexOf('document') != -1) {
		iconName = 'icon_data';
	} else if (path?.indexOf('tech') != -1) {
		iconName = 'icon_technology';
	} else if (path?.indexOf('dashboard') != -1) {
		iconName = 'icon_study';
	}
	return iconName;
};
