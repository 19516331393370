import React, { useState, useEffect } from 'react';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import actions from '../../../actions';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { setIsLoading } from '../../../actions/common';
import {
	getSubdocumentById,
	getUserManualAnnotationsWithoutFlag,
	getUserDetails,
	searchConceptsById,
} from './DataReviewHelper';
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};

const DataReview = (props) => {
	const [doc_name, setDocName] = useState('');
	const [doc_id, setDocId] = useState('');
	const [distinctConcept, setDistinctConcept] = useState([]);
	const [content_type, setContentType] = useState('');
	const [annotationList, setAnnotationList] = useState([]);
	let { loading } = props.loading;

	useEffect(() => {
		let { docId } = props.match.params;
		setIsLoading(true);
		getSubdocumentById(docId).then((resp) => {
			setDocName(resp.data.data.name_with_date);
			setContentType(resp.data.data.content_type);
		});
	}, []);

	useEffect(() => {
		let { docId } = props.match.params;
		if (props.sidenav.userRole.Role) {
			getUserManualAnnotationsWithoutFlag(docId).then((annotation) => {
				let userIds = [];
				userIds = annotation
					.map((annotate) => {
						return annotate.annotation_details.map((detail) => {
							return detail.user_id;
						});
					})
					.flat()
					.filter((item, index, array) => {
						return array.indexOf(item) == index;
					})
					.join(',');

				getUserDetails(userIds)
					.then((res) => {
						let userList = res.data.data;
						let annotations = annotation;
						let distinctConcept = [
							...new Set(annotations.map((item) => item.concept_id)),
						];

						annotations.map((annotatioDetail, detailIndex) => {
							annotations[detailIndex].sequence = detailIndex;
							annotatioDetail.annotation_details.map((annotation, annIndex) => {
								let user = userList.filter(
									(item) => item.user_id == annotation.user_id
								)[0];
								let userName = user.first_name + ' ' + user.last_name;
								annotations[detailIndex].annotation_details[annIndex].userName =
									userName;
							});
						});
						setIsLoading(true);
						searchConceptsById(distinctConcept).then((criteriaConcepts) => {
							setIsLoading(false);
							setDistinctConcept(criteriaConcepts);
							setAnnotationList(annotations);
						});
					})
					.finally(() => {
						setIsLoading(false);
					});
			});
		}
	}, [props.sidenav.userRole]);

	const redirectToDocument = (annotation) => {
		return (
			constants.ROUTE_URL +
				'/dashboard/document/' +
				props.match.params?.docId +
				'?route_annotation_id=' +
				annotation.annotation_id || ''
		);
	};

	const setHistory = () => {};
	const gotoDatadetail = () => {
		props.history.push(constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId);
	};

	const sortByUsername = (annotations) => {
		return annotations.sort(function (a, b) {
			var nameA = a.userName.toLowerCase(),
				nameB = b.userName.toLowerCase();
			if (nameA < nameB)
				//sort string ascending
				return -1;
			// if (nameA > nameB)
			//     return 1
			return 0; //default return value (no sorting)
		});
	};

	return (
		<div className="DataReview">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					history={props.history}
					match={props.match}
				/>
			</ErrorBoundary>
			<div className="document-header-container">
				<span> Manual annotation conflicts: </span>
				<a style={{ textDecoration: 'none' }} onClick={gotoDatadetail}>
					{doc_name}
				</a>
			</div>
			{distinctConcept.map((concept) => {
				const conceptExits = annotationList.filter((data) => data.concept_id == concept.id);
				return (
					<React.Fragment>
						{conceptExits[0] && (
							<div className="section-header">
								{concept.text} ({concept.sab} {concept.code}){' '}
							</div>
						)}
						{annotationList &&
							annotationList
								.filter((item) => item.concept_id == concept.id)
								.map((list) => {
									list.annotation_details = sortByUsername(
										list.annotation_details
									);
									let annotationOne = list.annotation_details[0];
									let annotationTwo = list.annotation_details[1];
									return (
										<React.Fragment>
											<div className="alt-bg">
												<div className="group-one-study">
													<span>{annotationOne.userName}</span>
													{': '}
													<a
														className="hyperlink"
														onClick={setHistory}
														href={redirectToDocument(annotationOne)}
													>
														{annotationOne.is_present && (
															<span>
																{annotationOne.is_experienced
																	? ''
																	: 'No '}
															</span>
														)}
														<span>{concept.text}</span>
														{annotationOne.annotation_type != null && (
															<span>
																{annotationOne.is_present
																	? ''
																	: ' not mentioned '}
															</span>
														)}
														<span>
															{annotationOne.annotation_type == null
																? ' not mentioned '
																: ''}
														</span>
													</a>
												</div>
											</div>
											<div className="alt-bg">
												<div className="group-two-study">
													<span>{annotationTwo.userName}</span>
													{': '}
													<a
														className="hyperlink"
														href={redirectToDocument(annotationTwo)}
													>
														{annotationTwo.is_present && (
															<span>
																{annotationTwo.is_experienced
																	? ''
																	: 'No '}
															</span>
														)}
														<span>{concept.text}</span>
														{annotationTwo.annotation_type != null && (
															<span>
																{annotationTwo.is_present
																	? ''
																	: ' not mentioned '}
															</span>
														)}
														<span>
															{annotationTwo.annotation_type == null
																? ' not mentioned '
																: ''}
														</span>
													</a>
												</div>
											</div>
										</React.Fragment>
									);
								})}
					</React.Fragment>
				);
			})}
		</div>
	);
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(DataReview);
