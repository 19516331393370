import actionTypes from '../actions/actionTypes';

let initialState = {
	fetching: false,
	userList:[],
	accessList:[],
	providerDetails:[],
	userDetails: [],
	userPermissionList:[],
	loggedInUser:{}
	
};

//reducer that matches the action type for history actions and changes the state
export const userManagementReducer = (state = initialState, action ) => {
	
	switch(action.type) {

		case actionTypes.GET_USER_DETAILS : {
			return {...state, userList:action.payload}
		}
		
		case actionTypes.GET_ACCESS_ORGANIZATION : {
			return {...state, accessList:action.payload}
		}

		case actionTypes.SET_PROVIDER_DETAILS : {
			return {...state, providerDetails:action.payload}
		}

		case actionTypes.SET_USER_LIST : {
			return {...state, userDetails:action.payload}
		}
		case actionTypes.GET_USER_PERMISSION : {
			return {...state, userPermissionList:action.payload}
		}
		case actionTypes.GET_SYSTEM_USER_DETAILS : {
			return {...state, loggedInUser:action.payload}
		}
		default : {
			return state;
		}
	}
}