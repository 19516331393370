import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
//import {Button} from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import actions from '../actions';
// import constants from '../constants';
// import { removeItem } from '../utilities/utils';
import ReactHtmlParser from 'react-html-parser';
import ErrorBoundary from '../ErrorBoundary';

const SaveConfirm = forwardRef((props, ref) => {
	const [open, setOpen] = useState(false);
	const [className, setClassName] = useState('testClass');

	useImperativeHandle(ref, () => {
		handleClick: handleClick;
	});
	useEffect(() => {
		props.open({
			handleClick: () => handleClick(),
		});
		return () => {
			props.open(null);
		};
	}, []);

	const cancelClick = (event) => {
		setClassName('testClass');
		setOpen(false);
		if (props.rootClosexit) {
			props.rootClosexit(event);
		} else {
			event.stopPropagation();
			event.nativeEvent.stopImmediatePropagation();
			return false;
		}
	};

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(true);
		setClassName('animated shake Data-Management');

		setTimeout(() => setClassName('Data-Management'), 1000);
	};

	const okClick = () => {
		setClassName('Data-Management');
		setOpen(false);
		props.confirmClick();
	};

	return (
		<div>
			<ErrorBoundary>
				<ModalComponent
					open={props.testingOpen || open}
					backdrop={true}
					content={
						<div className="jconfirm-box-container" style={{ padding: '20px' }}>
							<div className="jconfirm-content-pane">
								{/* <div style={{fontSize:18}}>{props.text}</div> */}
								<div
									style={{ fontSize: 18 }}
									dangerouslySetInnerHTML={{ __html: props.text }}
								/>
							</div>
							<div className="jconfirm-content-pane">
								<div className="jconfirm-buttons">
									<button
										type="button"
										className="btn btn-default"
										onClick={(event) => cancelClick(event)}
										id='cancel-btn'
									>
										Cancel
									</button>
									<button
										type="button"
										className="btn btn-default"
										onClick={() => okClick()}
										id='ok-btn'
									>
										Ok
									</button>
								</div>
							</div>
						</div>
					}
					className={'testClass'}
					handleClose={handleClose}
				/>
			</ErrorBoundary>
		</div>
	);
});

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(SaveConfirm));
