import actionTypes from '../../actions/actionTypes';
import { apiGet } from '../../utilities/utils';
import store from '../../store';
import actions from '../../actions';

/**************************************** 
    * Function Name: getCohortById
    * Description: Returns a cohort object by id. First it checks in redux store, 
    if not found then fires API call, and then find requested cohort by id given.
    @param cohortId Cohort id
****************************************/
export const getCohortById = (cohortId) => {
	return new Promise((resolve, reject) => {
		//CHECK IF COHORT PRESENT IN REDUX, ALSO CHECK FOR REQUIRED COHORT
		const cohortInStore = store.getState().cohort.cohortList;
		const isCohortEdited = store.getState().v_cohort.isCohortEdited;
		if (
			!isCohortEdited &&
			cohortInStore.length &&
			cohortInStore.findIndex((cohort) => cohort.cohort_id === cohortId) !== -1
		) {
			const cohort = cohortInStore.find((cohort) => cohort.cohort_id === cohortId);
			if (cohort) {
				resolve(cohort);
			} else {
				reject();
			}
		} else {
			//FETCH ALL COHORTS, STORE IN REDUX, PROVIDE REQUESTED COHORT BY ID
			store
				.dispatch(actions.getCohortList({}))
				.then((res) => {
					if (isCohortEdited) {
						store.dispatch(cohortEdited(false));
					}
					const cohorts = res.data.data;
					const cohort = cohorts.find((cohort) => cohort.cohort_id === cohortId);
					resolve(cohort);
				})
				.catch((err) => {
					console.error(err);
					reject(err);
				});
		}
	});
};

/****************************************
 * Function Name: getCohortCriteria
 * Description: exports an action to get the cohort criterion
 * from the backend.
 * The data received is returned via promise which can be used
 * for manipulation.
 * Method has one signature.
 * @param - cohort_id - used to make the api call based on the cohort ID.
 ****************************************/

export const getCohortCriteria = (cohort_id) => {
	return new Promise((resolve, reject) => {
		apiGet('GetCohortCriterion', {
			'cohortObj.cohort_id': cohort_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
};
/** **************************************
 * Function Name: searchConceptsById
 * Description: get concept detail based on concept ids provided.
 * Method has one signature.
 ****************************************/

 export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export const searchConceptsById = (conceptIds, eventIds) => {
// 	return new Promise((resolve, reject) => {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// };
