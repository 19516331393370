import { combineReducers } from 'redux';
import {authReducer} from './auth';
import {patientReducer} from './patients';
import { historyReducer } from './history';
import { cohortReducer } from './cohorts';
import { studyReducer } from './study';
import {technologyReducer} from './technology';
import {loadingReducer} from './loading';
import {sidenavReducer} from './sidenav';
import {dataManagementReducer} from './datamanagement';
import {userManagementReducer} from './usermanagement';
import {rapidManualReducer} from './rapidList';
import {summaryAnalyticsReducer} from './summaryanalytics';
import { modalReducer } from './modalReducer';
import { reducerPatient } from './PatientReducer';
import { cohortReducer as cohortReducer_v2 } from '../containers/CohortList/cohortReducer';

// const history = createBrowserHistory();
//import {accountManagementReducer} from './accountmanagement';

//combining all the reducers into one and exporting it.

const appReducer = combineReducers({
  // router: connectRouter(history),
  auth: authReducer,
  patient: patientReducer,
  historyreducer: historyReducer,
  cohort : cohortReducer,
  study : studyReducer, 
  technology: technologyReducer,
  loading: loadingReducer,
  sidenav: sidenavReducer,
  datamanagement : dataManagementReducer,
  usermanagement : userManagementReducer,
  rapidList : rapidManualReducer,
  summaryanalytics : summaryAnalyticsReducer,
  modal: modalReducer,
  v_patients: reducerPatient,
  v_cohort: cohortReducer_v2
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}
export default rootReducer;
