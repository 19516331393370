import actionTypes from "./actionTypes";

export function openModal(modalType) {

	return dispatch => {
		dispatch({
			type: actionTypes.OPEN_MODAL_POPUP,
			payload: modalType
		});
	}
}
//NOTE: TO CLOSE MODAL, SAME FUNCTION IS USED (openModal()), IT ONLY SETS type = ""