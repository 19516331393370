import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { useSelector } from 'react-redux';
import Loading from 'react-loading-spinner';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import queryString from 'query-string';
import HeaderAccordion from '../../../components/HeaderAccordion/HeaderAccordion';
import Copyright from '../../../components/Copyright/Copyright';

import {
	searchConceptsById,
	setIsLoading,
	getConceptSupportDataForDocument,
} from './DataConceptSupportHelper';
import dataIcon from '../../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};

const DataConceptSupport = (props) => {
	const [selectedConcept, setSelectedConcept] = useState(
		queryString.parse(window.location.search)?.conceptId
	);
	const [conceptName, setConceptName] = useState('');
	const [mainConcept, setMainConcept] = useState('');
	const [supportConceptsByCategory, setSupportConceptsByCategory] = useState(
		props.supportConceptsByCategoryProps ? props.supportConceptsByCategoryProps : []
	);
	const [viewMode, setViewMode] = useState(true);
	const loadingProps = useSelector((state) => state.loading);

	useEffect(() => {
		let conceptId = queryString.parse(window.location.search)?.conceptId;
		let docId = props.match.params.docId;
		let storedConceptSupport = sessionStorage.getItem('conceptsupport_' + docId);
		// setIsLoading(true);
		searchConceptsById(conceptId).then((Concepts) => {
			setConceptName((Concepts.length > 0 && Concepts[0].text) || '');
			setSelectedConcept(queryString.parse(window.location.search).conceptId);
		});

		if (storedConceptSupport) {
			let csvalue = JSON.parse(storedConceptSupport);
			if (csvalue) {
				setInitialData(csvalue);
			} else {
				getConceptSupport(conceptId);
			}
		} else {
			getConceptSupport(conceptId);
		}
	}, []);

	const redirectToAnnotation = (items) => {
		if (props.match.params.docId == '0') {
			let automatedAnnotationId =
				'automated_annotation_id=' +
				items.section_number +
				'_' +
				items.begin_offset +
				'_' +
				items.end_offset;
			props.history.push(
				constants.ROUTE_URL + '/dashboard/datapreview?' + automatedAnnotationId
			);
		} else {
			let automatedAnnotationId =
				'automated_annotation_id=' +
				items.section_number +
				'_' +
				items.begin_offset +
				'_' +
				items.end_offset;
			props.history.push(
				constants.ROUTE_URL +
					'/dashboard/document/' +
					items.subdocument_id +
					'?' +
					automatedAnnotationId
			);
		}
	};

	const getConceptSupport = (conceptId) => {
		let docId = props.match.params.docId;
		let initialData = [];
		getConceptSupportDataForDocument(docId, conceptId)
			.then((annotation) => {
				initialData = annotation.data.data;
				setInitialData(initialData);
				sessionStorage.setItem('conceptsupport_' + docId, JSON.stringify(initialData));
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};

	const setInitialData = (initialData) => {
		let entities = [];
		let categories = [];
		let mainConcept = [];
		let _supportedConcepts = [];

		initialData.map((section) => {
			section.entities.map((concepts) => {
				categories.push(concepts.concept_category);
				if (concepts.concept_id == selectedConcept) {
					_supportedConcepts = concepts.support_concepts;
				}
				entities.push(concepts);
			});
		});

		let supportedConcepts = [];
		let supportConceptsByCategory = [];
		entities.map((item) => {
			let temp = _supportedConcepts.filter(
				(concept_id) => concept_id.split(':')[0] == item.concept_id
			);
			if (temp.length > 0) {
				if (item.concept_id == selectedConcept) {
					if (temp[0].split(':')[1] != 0) {
						item.coefficient_value = temp[0].split(':')[1] || '';
						mainConcept.push(item);
					}
				} else {
					if (temp[0].split(':')[1] != 0) {
						item.coefficient_value = temp[0].split(':')[1] || '';
						supportedConcepts.push(item);
					}
				}
			}
		});
		if (mainConcept.length > 0) {
			supportedConcepts.unshift(mainConcept[0]);
		}

		let uniqueCategory = [...new Set(categories.map((item) => item))];

		uniqueCategory.map((category) => {
			let temp = supportedConcepts.filter((item) => item.concept_category == category);
			if (temp.length > 0) {
				supportConceptsByCategory.push({
					categoryName: category,
					concepts: temp,
				});
			}
		});
		// setIsLoading(false);
		setMainConcept([]);
		setSupportConceptsByCategory(
			props.supportConceptsByCategoryProps
				? props.supportConceptsByCategoryProps
				: supportConceptsByCategory
		);
	};

	const toggleMode = () => {
		setViewMode(!viewMode);
	};
	const goBack = () => {
		props.history.goBack();
	};

	return (
		<div className="DataAutomated">
			{loadingProps.loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}
			<HeaderTitles
				data={headerData}
				search={false}
				viewMode={viewMode}
				toggleMode={toggleMode}
				goBack={goBack}
				history={props.history}
				match={props.match}
			/>
			<div className="document-header-container">
				<span>Data: </span>
				<a href="">
					{'Concept support for '}
					{conceptName.toLowerCase()}
				</a>
			</div>
			{supportConceptsByCategory.map((section) => {
				return (
					<React.Fragment>
						<div className="section-header">{section.categoryName}</div>
						<ul>
							{section.concepts &&
								section.concepts.map((items) => {
									return (
										<React.Fragment>
											{items.concept_occurence.length > 1 ? (
												<OverlayTrigger
													placement={'auto'}
													trigger="click"
													rootClose={true}
													overlay={
														<Popover placement="auto">
															<ConceptListPopover
																conceptInfo={items}
																redirectToAnnotation={
																	redirectToAnnotation
																}
															/>
														</Popover>
													}
												>
													<li
														style={{ display: 'table' }}
														id="concept-occurence-id"
													>
														<a className="hyperlink">
															{items.concept_name}
														</a>
														{items.concept_occurence.length > 1 && (
															<React.Fragment>
																<span
																	className="hyperlink"
																	style={{
																		display: 'inline-block',
																		marginTop: '-5px',
																		position: 'absolute',
																		marginLeft: '2px',
																	}}
																>
																	{items.concept_occurence.length}
																</span>
																{!viewMode ? (
																	<span
																		style={{
																			fontSize: '12px',
																			paddingLeft: '15px',
																		}}
																	>
																		{'(' +
																			items.coefficient_value +
																			')'}
																	</span>
																) : (
																	''
																)}
															</React.Fragment>
														)}
													</li>
												</OverlayTrigger>
											) : (
												<li>
													<a
														className="hyperlink"
														onClick={() =>
															redirectToAnnotation(
																items.concept_occurence[0]
															)
														}
													>
														{items.concept_name}
													</a>
													{!viewMode ? (
														<span
															style={{
																fontSize: '12px',
																paddingLeft: '5px',
															}}
														>
															{'(' + items.coefficient_value + ')'}
														</span>
													) : (
														''
													)}
												</li>
											)}
										</React.Fragment>
									);
								})}
						</ul>
					</React.Fragment>
				);
			})}
			<Copyright />
		</div>
	);
};

DataConceptSupport.propTypes = {
	history: PropTypes.shape({
		goBack: PropTypes.func,
		push: PropTypes.func,
	}),
	loading: PropTypes.shape({
		loading: PropTypes.any,
	}),
	match: PropTypes.shape({
		params: PropTypes.shape({
			docId: PropTypes.string,
		}),
	}),
};

// const mapStateToProps = (state) => state;
export default DataConceptSupport;

const ConceptListPopover = ({ conceptInfo, redirectToAnnotation }) => {
	let subdocumentIds = [
		...new Set(conceptInfo.concept_occurence.map((item) => item.subdocument_id)),
	];
	let subdocuments = [];
	subdocumentIds.map((subdocumentId) => {
		let filterSubdocument = conceptInfo.concept_occurence.filter(
			(items) => items.subdocument_id == subdocumentId
		)[0];
		subdocuments.push(filterSubdocument);
	});

	return (
		<React.Fragment>
			<div className="automated-annotation-pop">
				<div className="title">Concept list</div>
				{subdocuments.map((subdocument, index) => {
					return (
						<HeaderAccordion
							type={'header'}
							identifier={index}
							text={subdocument.subdocument_name}
							leftPad={10}
							isOpen={false}
						>
							<ul className="list nopadding">
								{conceptInfo.concept_occurence
									.filter(
										(item) => item.subdocument_id == subdocument.subdocument_id
									)
									.map((concept, index) => {
										return (
											<li
												className={`v-title-wrapper  ${
													index % 2 == 0 ? '' : 'bg-strip-dark'
												}`}
												style={{ paddingLeft: '35px' }}
												onClick={() => redirectToAnnotation(concept)}
											>
												<a className="hyperlink">{concept.name} </a>(
												{conceptInfo.concept_name})
											</li>
										);
									})}
							</ul>
						</HeaderAccordion>
					);
				})}
			</div>
		</React.Fragment>
	);
};
