import React from 'react';
import constants from '../../constants';
import removeMinusIcon from  '../../images/remove_minus_icon.svg';
import addPlusIcon from  '../../images/add_plus_icon.svg';
const PanelHeading = ({ heading, handlePlusClick, handleMinusClick }) => (
	<div className={handlePlusClick ? 'v-panel-heading-button' : 'v-panel-heading-button'}>
		{heading}

		{handlePlusClick && (
			<img
				src={addPlusIcon}
				onClick={handlePlusClick}
				className="hyperlink control-button"
				alt="plusicon"
			/>
		)}
		{handleMinusClick && (
			<img
				src={removeMinusIcon}
				onClick={handleMinusClick}
				className="hyperlink control-button"
				alt="minusIcon"
			/>
		)}
	</div>
);

export default PanelHeading;
