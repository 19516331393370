import { apiGet } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';
import actions from '../../actions';

export function setIsLoading(loading) {
	store.dispatch({
		type: actionTypes.IS_LOADING,
		payload: loading,
	});
}

export function setTotalPatients(number) {
	store.dispatch({
		type: actionTypes.SET_TOTAL_PATIENTS,
		payload: number,
	});
}

export function getCohortList(params) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortList', params)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
}

export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export function searchConceptsById(conceptIds, eventIds) {
// 	return new Promise(function (resolve, reject) {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// }

export function getEvent() {
	return new Promise(function (resolve, reject) {
		apiGet('GetEvent', {
			eventType: 'outcome',
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_EVENT,
					payload: res.data.data.reverse(),
				});
				resolve(res.data.data.reverse());
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getStudyDetail(study_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
