import store from '../../../store';
import actions from '../../../actions';

export const getSubdocumentById = (docId) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getSubdocumentById(docId))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const GetSubdocumentList = (docId, type) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.GetSubdocumentList(docId, type))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export const getHistoryData = (docId) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getHistoryData(docId))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export const getManualAnnotationsNew = (docId) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getManualAnnotationsNew(docId))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const setRapidManualAnnotation = (annotation) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.setRapidManualAnnotation(annotation))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};


export const getDescriptorValues = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getDescriptorValues())
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getSeverityValues = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getSeverityValues())
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getProgressionValues = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getProgressionValues())
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getNumericValueUnits = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getNumericValueUnits())
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getMedicationUnits = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getMedicationUnits())
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export const getMedicationForms = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getMedicationForms())
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export const getMedicationRoutes = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getMedicationRoutes())
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
export const GetConceptCategoriesOptions = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.GetConceptCategoriesOptions())
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const GetNotExperiencedReasons = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.GetNotExperiencedReasons)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const GetNotDefinitiveDetails = () => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.GetNotDefinitiveDetails)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};