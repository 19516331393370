import actionTypes from '../actions/actionTypes';

let initialState = {
	navList : [],
	filter: '',
	history:[],
	userRole:{},
	userRoles:[]
};

export const sidenavReducer = (state=initialState, action) => {
	
	switch(action.type) {

		case actionTypes.SET_SIDE_NAVLIST : {
			return { ...state, navList: action.payload }
		}

		case actionTypes.SET_FILTER_TEXT: {
			return { ...state, filter: action.payload }
		}

		case actionTypes.REMOVE_FILTER_TEXT: {
			return { ...state, filter: '' }
		}

		case actionTypes.SET_HISTORY: {
			
			let _history=[];
			let storedHistory = sessionStorage.getItem('storedHistory');
			let parsedHistory =[];
			if(storedHistory){ 
				parsedHistory = JSON.parse(storedHistory);
			}
			if(parsedHistory.length ==5){
				_history=parsedHistory.reverse().slice(0,parsedHistory.length-1).reverse()
			} else{
				_history=parsedHistory;
			}
			
			// if(state.history.length!=0){
			// 	if(state.history.length ==5){
			// 		_history=state.history.reverse().slice(0,state.history.length-1).reverse()
			// 	} else{
			// 		_history=state.history;
			// 	}
			// 	//_history=state.history.splice(0,1);
			// }
			
			_history.push(action.payload);
			sessionStorage.setItem('storedHistory',JSON.stringify(_history));
			return { ...state, history: _history }
		}
		case actionTypes.REMOVE_HISTORY: {
			return { ...state, history: [] }
		}
		case actionTypes.GET_USER_ROLE: {

			 let userRole = '';
			 let userRoles = action.payload;
			if(sessionStorage.getItem('userRole')){
				userRole = sessionStorage.getItem('userRole');
			} else {
				let isCompany = userRoles && userRoles.filter(items => items.Role == "Company").length>0 ? true : false;
				let isAdministrator = userRoles && userRoles.filter(items => items.Role == "Administrator").length>0 ? true : false;
				let isUser = userRoles && userRoles.filter(items => items.Role == "User").length>0 ? true : false;
				let isAnnotator = userRoles && userRoles.filter(items => items.Role == "Annotator").length>0 ? true : false;
				let isSupervisor = userRoles && userRoles.filter(items => items.Role == "Supervisor").length>0 ? true : false;

				if(isCompany){
					userRole = 'Company';
				} else if(isAdministrator){
					userRole = 'Administrator';
				}
				else if(isUser){
					userRole = 'User';
				}
				else if(isAnnotator){
					userRole = 'Annotator';
				}
				else if(isSupervisor){
					userRole = 'Supervisor';
				}
			}
			
			return { ...state, userRole: {'Role': userRole}, userRoles: action.payload }
		}
		case actionTypes.SET_USER_ROLE: {
			return { ...state, userRole: {'Role':action.payload } }
		}
		default : {
			return state;
		}
	}
}