import actionTypes from '../actions/actionTypes';
import store from '../store';

//defining initial state for the reducer
let initialState = {

	patient_name: '',
	patients : [],
	organizations : [],
	patientDocuments : [],
	patientDetails: [],
	patientDiagnoses: [],
	patientStudyList: [],
	annotatorPatientList:{},
	totalPatients:0

};

//reducer that matches the action type for patient actions and changes the state
export const patientReducer = (state=initialState, action) => {

	switch(action.type){
		case actionTypes.GET_PATIENT_DATA : {
			return {...state , patients: action.payload }
		}

		case actionTypes.GET_ORGANIZATIONS : {
			// console.log("patients reducer GET_ORGANIZATIONS: ",action.payload);
			return {...state , organizations: action.payload }
		}

		case actionTypes.GET_DOCUMENTS : {
			return {...state, patientDocuments: action.payload}
		}
		
		case actionTypes.SET_PATIENT_DETAIL_DATA : {
			return {...state, patientDetails: action.payload}
		}

		case actionTypes.SET_PATIENT_NAME : {
			return {...state, patient_name: action.payload}
		}

		case actionTypes.SET_DIAGNOSES : {
			return {...state, patientDiagnoses: action.payload}
		}
		case actionTypes.GET_STUDY_LIST_FOR_PATIENT : {
			return {...state, patientStudyList: action.payload.data}
		}
		case actionTypes.EMPTY_PATIENT_DETAILS : {
			return {...state,  	patientDiagnoses: action.payload,
								patientDetails: action.payload,
								patientDocuments: action.payload ,
								patient_name: action.payload,
								patients: action.payload
					}
		}
		case actionTypes.SET_ANNOTATOR_PATIENT_LIST : {
			
			 let key = Object.keys(action.payload)[0];
			 let value = action.payload[key];
			let list = state.annotatorPatientList || {};
			list[key] = value;
;			return {...state, annotatorPatientList: list}
		}
		case actionTypes.SET_TOTAL_PATIENTS: {
		return {...state, totalPatients: action.payload};
		}
		default: {
			return state;
		}
	}
}
