import { apiGet } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';
import actions from '../../actions';

/****************************************
 * Function Name: setTotalPatients
 * Description: Set total number of patients for pagination.
 * @param number Number - Total number of patients.
 ****************************************/
export function setTotalPatients(number) {
	store.dispatch({
		type: actionTypes.SET_TOTAL_PATIENTS,
		payload: number,
	});
}
/** **************************************\/'/'
 * ]\]
 * Function Name: getStudyDetail
 * Description: exports an action to get the study details from the backend
 * The details received gets set in the study reducer which can be used
 * later for manipulation.
 * Method has one signature.
 ****************************************/
export function getStudyDetail(study_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}
/** **************************************
 * Function Name: searchConceptsById
 * Description: get concept detail based on concept ids provided.
 * Method has one signature.
 ****************************************/

 export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export function searchConceptsById(conceptIds, eventIds) {
// 	return new Promise(function (resolve, reject) {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// }
/****************************************
 * Function Name: getEvent
 * Description: exports an action to get the Events
 * from the backend.
 * The data received is set in the reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getEvent() {
	return new Promise(function (resolve, reject) {
		apiGet('GetEvent', {
			eventType: 'outcome',
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_EVENT,
					payload: res.data.data.reverse(),
				});
				resolve(res.data.data.reverse());
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
/****************************************
 * Function Name: getCohortList
 * Description: exports an action to get the cohort list data from the backend
 * The data received gets set in the cohorts reducer which can be used
 * later for manipulation.
 * Method has no signature.
 ****************************************/

export function getCohortList(params) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortList', params)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
}
