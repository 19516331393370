import actionTypes from '../actions/actionTypes';

//defining initial state for the reducer
let initialState= {
	/* summaryAnalyticsList : [] */

};

//reducer that matches the action type for rapid manual annotatiions actions and changes the state
export const summaryAnalyticsReducer = (state = initialState, action) => {
	// console.log("summaryAnalyticsReducer")
	switch(action.type) {
		case actionTypes.GET_SUMMARY_ANALYTICS : {
			return {...state, summaryAnalyticsList: action.payload}
		}

		default : {
			return state;
		}
	}
}
