import React, { useState, useEffect, forwardRef,useImperativeHandle } from 'react';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import UserData from '../../components/UserData/UserData';
import actions from '../../actions';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';

const UserManagement = forwardRef((props, ref) => {
	const [open, setOpen] = useState(false);

	useEffect(() => {
		props.open({
			handleClick: () => handleClick(),
		});
		return () => {
			props.open(null);
		};
	}, []);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		props.dispatch(actions.openModal(''));
		//SET CLOSE MODAL IN REDUX
	};

	return (
		<ErrorBoundary>
			<ModalComponent
				open={open}
				handleClose={handleClose}
				content={
					<div className="modal_body_content">
						<UserData />
					</div>
				}
				className="Data-Management"
			/>
		</ErrorBoundary>
	);
});

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(UserManagement);
