import actionTypes from '../actions/actionTypes';

//defining initial state for the reducer
let initialState= {
	rapidList : []

};

//reducer that matches the action type for rapid manual annotatiions actions and changes the state
export const rapidManualReducer = (state = initialState, action) => {

	switch(action.type) {
		case actionTypes.GET_RAPID_MANUAL_ANNOTATION : {
			return {...state, rapidList: action.payload}
		}

		default : {
			return state;
		}
	}
}
