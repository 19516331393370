import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import constants from '../../constants';
import { useSelector } from 'react-redux';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import queryString from 'query-string';
import { setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import Loading from 'react-loading-spinner';
import ErrorBoundary from '../../components/ErrorBoundary';
import Copyright from '../../components/Copyright/Copyright';
import { Link } from 'react-router-dom';
import { setIsLoading } from '../../actions/common';

import {
	GetAnnotatorKappaScores,
	getStudyDetail,
	searchConceptsById,
	setSideNavList,
} from './ConceptKappaHelper';
import trialIcon from '../../images/trial_icon.png';
const headerData = {
	title: 'Validity',
	list: 'detail',
	img: (
		<img
			src={trialIcon}
			className={'App-TrialList'}
			alt={'Trial List'}
		/>
	),
};

const ConceptKappa = (props) => {
	const [kappData, setKappData] = useState({});
	const [conceptName, setConceptName] = useState('');
	const loading = useSelector((state) => state.loading)?.loading;
	const { studyId } = props.match.params;
	useEffect(() => {
		setSideNavList([]);
		setIsLoading(true);

		let concept_id = queryString.parse(window.location.search).concept_id;
		let cohort_id = queryString.parse(window.location.search).cohort_id;

		getStudyDetail(studyId).then((studyDetali) => {
			let filterLabel = {
				page: 'conceptKappa',
				options: [
					{
						filterLabel: 'Study',
						name: studyDetali.study_name,
						fixed: true,
					},
				],
			};
			setFilterLabel(filterLabel);
			GetAnnotatorKappaScores(concept_id, cohort_id)
				.then((res) => {
					if (res) {
						setKappData(res);
						if (concept_id) {
							searchConceptsById(concept_id).then((resp) => {
								if (resp) {
									setConceptName(resp[0]?.text);
									let filterLabel = {
										page: 'conceptKappa',
										options: [
											{
												filterLabel: 'Study',
												name: studyDetali.study_name,
												fixed: true,
											},
											{
												filterLabel: 'Concept',
												name: resp?.data?.data[0].text,
												fixed: false,
											},
										],
									};
									setFilterLabel(filterLabel);
								}
							});
						} else {
						}
					}
				})
				.finally((res) => {
					setIsLoading(false);
				});
			setIsLoading(false);
		});
	}, []);

	const getConceptKappaUrl = (anootatorOneIndex, annotatorTwoIndex, initial) => {
		let userIdList = [
			Object.keys(kappData.Annotators[anootatorOneIndex])[0],
			Object.keys(kappData.Annotators[annotatorTwoIndex])[0],
		].sort();
		let concept_id = queryString.parse(window.location.search).concept_id;
		return (
			constants.ROUTE_URL +
			'/dashboard/datastudyreview/0?study_id=' +
			studyId +
			'&concept_id=' +
			concept_id +
			'&userIdList=' +
			userIdList.join(',') +
			'&initial=' +
			initial +
			'&forKappaScore=true'
		);
	};

	return (
		<React.Fragment>
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					studyId={props.match.params.studyId}
					{...props}
				/>
			</ErrorBoundary>
			<div>
				<h3 className="v-cohort-name">
					Inter-rater reliability for {conceptName.toLocaleLowerCase()}
				</h3>
			</div>
			<div>
				<div className="sub-header">
					<div className="first">Initial annotator agreement</div>
				</div>
				<div className="study-validity-tbl-container">
					<table className="tbl-strip">
						<thead className="tbl-head">
							<tr>
								<td>
									<div className="first">
										<span>Annotators</span>
									</div>
								</td>
								{kappData.Annotators &&
									kappData.Annotators.map((annotator, index) => {
										return (
											<td>
												<div className="">
													<span>
														{annotator[Object.keys(annotator)[0]]}
													</span>
												</div>
											</td>
										);
									})}
							</tr>
						</thead>
						<tbody>
							{kappData.Annotators &&
								kappData.Annotators.map((annotator, index) => {
									return (
										<tr>
											<td>
												<div className="first">
													<span>
														{annotator[Object.keys(annotator)[0]]}
													</span>
												</div>
											</td>
											{kappData.InitialKappaScores[index] &&
												kappData.InitialKappaScores[index].map(
													(scores, ind) => {
														return (
															<td>
																<div>
																	{scores == 1 ? (
																		<span
																			style={{
																				color: '#a5a5a5',
																			}}
																		>
																			1.00
																		</span>
																	) : (
																		<Link
																			to={getConceptKappaUrl(
																				index,
																				ind,
																				'true'
																			)}
																		>
																			{scores == -999
																				? ''
																				: typeof scores ===
																						'number' &&
																				  scores?.toFixedNoRounding(
																						2
																				  )}
																		</Link>
																	)}
																</div>
															</td>
														);
													}
												)}
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
			<div>
				<div className="sub-header">
					<div className="first">Current annotator agreement</div>
				</div>
				<div className="study-validity-tbl-container">
					<table className="tbl-strip">
						<thead className="tbl-head">
							<tr>
								<td>
									<div className="first">
										<span>Annotators</span>
									</div>
								</td>
								{kappData.Annotators &&
									kappData.Annotators.map((annotator, index) => {
										return (
											<td>
												<div className="">
													<span>
														{annotator[Object.keys(annotator)[0]]}
													</span>
												</div>
											</td>
										);
									})}
							</tr>
						</thead>
						<tbody>
							{kappData.Annotators &&
								kappData.Annotators.map((annotator, index) => {
									return (
										<tr>
											<td>
												<div className="first">
													<span>
														{annotator[Object.keys(annotator)[0]]}
													</span>
												</div>
											</td>
											{kappData.CurrentKappaScores &&
												kappData.CurrentKappaScores[index].map(
													(scores, ind) => {
														return (
															<td>
																<div>
																	{scores == 1 ? (
																		<span
																			style={{
																				color: '#a5a5a5',
																			}}
																		>
																			1.00
																		</span>
																	) : (
																		<a
																			href={getConceptKappaUrl(
																				index,
																				ind,
																				'false'
																			)}
																		>
																			{scores == -999
																				? ''
																				: typeof scores ===
																						'number' &&
																				  scores.toFixedNoRounding(
																						2
																				  )}
																		</a>
																	)}
																</div>
															</td>
														);
													}
												)}
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</div>
			<Copyright />
		</React.Fragment>
	);
};

ConceptKappa.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      studyId: PropTypes.any
    })
  })
}

export default ConceptKappa;
