import actionTypes from './actionTypes';
import { apiGet } from '../utilities/utils';

/**************************************** 
     * Function Name: getUploadOrigin
     * Description: exports an action to get the list of Upload paths from the backend 
     * to be displayed to the user in the form of dropdown in the DataManagement
     * section of the UI.
     * Method has no signature.
****************************************/
export function getUploadPath(){
	return dispatch => {

	}
}	

/**************************************** 
     * Function Name: getUploadOrigin
     * Description: exports an action to get the list of Upload origins from the backend 
     * to be displayed to the user in the form of dropdown in the DataManagement
     * section of the UI.
     * Method has no signature.
****************************************/

export function getUploadOrigin(){
	return dispatch => {
		apiGet("GetDocumentOriginList", {}).then(res => {
               dispatch({
                    type: actionTypes.SET_UPLOAD_ORIGIN,
                    payload: res.data.data
               });
          })
          .catch(err => {
               console.log(err);
          })
	}
}	

/**************************************** 
     * Function Name: getUploadFileFormat
     * Description: exports an action to get the list of file formats from the backend 
     * to be displayed to the user in the form of dropdown in the DataManagement
     * section of the UI.
     * Method has no signature.
****************************************/

export function getUploadFileFormat(){
	return dispatch => {
		apiGet("GetDocumentFormatList", {}).then(res => {
               dispatch({
                    type: actionTypes.SET_FILE_FORMAT,
                    payload: res.data.data
               });
          })
          .catch(err => {
               console.log(err);
          })
	}
}	

/**************************************** 
     * Function Name: getUploadOrganization
     * Description: exports an action to get the organizations for Data Management
     * section of the UI from the backend.
     * Method has no signature.
****************************************/

export function getUploadOrganization(){
	return dispatch => {
		apiGet("GetOrganization", {forUpload: true}).then(res => {
               dispatch({
                    type: actionTypes.SET_UPLOAD_ORGANIZATION,
                    payload: res.data.data
               });
          })
          .catch(err => {
               console.log(err);
          })
	}
}	

/**************************************** 
     * Function Name: startDataUpload
     * Description: exports an action to start the data upload when the user clicks
     * the start button. 
     * Method has one signature.
     * @param -uploadData - The object having the necessary details required for
     * the upload of the data such as Batch Name, Path, Origin, etc.
****************************************/

export function startDataUpload(uploadData){
	
     const {batchName, path, origin, fileFormat, organization} = uploadData;

     return dispatch => new Promise(function(resolve, reject){
          dispatch({
               type: actionTypes.START_DATA_UPLOAD
          });

		apiGet("UploadBatch", {
               batchName : batchName,
               path : path,
               origin: origin.value,
               fileFormat: fileFormat.value,
               orgId: organization.value
          }).then(res => {
               dispatch({type: actionTypes.END_DATA_UPLOAD
               });
               resolve(res);
          })
          .catch(err => {
               console.log(err);
               dispatch({type: actionTypes.END_DATA_UPLOAD
               });
               reject(err);
          });
	})
}	

/**************************************** 
     * Function Name: getBatchOrganization
     * Description: exports an action to start the data upload when the user clicks
     * the start button. 
     * Method has one signature.
     * @param -organization_id - The object having the necessary details required for
     * the upload of the data such as organization_id
****************************************/

export function getBatchOrganization(organization_id){
     return dispatch => new Promise(function (resolve,reject){

          apiGet("GetBatchOrganization", {
               organization_id: organization_id
          }).then(res => {
               dispatch({
                    type: actionTypes.GET_BATCH_ORGANIZATION,
                    payload: res.data
               });
               resolve(res);
          })
          .catch(err => {
               console.log(err);
               reject(err)
          })
     })
}  

/**************************************** 
     * Function Name: getDocumentOrganization
     * Description: exports an action to start the data upload when the user clicks
     * the start button. 
     * Method has one signature.
     * @param -organization_id - The object having the necessary details required for
     * the upload of the data such as organization_id
****************************************/

export function getDocumentOrganization(organization_id){
     return dispatch => new Promise(function (resolve,reject){

          apiGet("GetDocuments", {
               organization_id: organization_id
          }).then(res => {
               dispatch({
                    type: actionTypes.GET_DOCUMENT_ORGANIZATION,
                    payload: res.data
               });
               resolve(res);
          })
          .catch(err => {
               console.log(err);
               reject(err)
          })
     })
}  

/**************************************** 
     * Function Name: getDocumentsBatch
     * Description: exports an action to get the documents for Data Management
     * section of the UI from the backend.
     * Method has no signature.
****************************************/

export function getDocumentsBatch(){
     return dispatch => new Promise(function (resolve,reject){

          apiGet("GetDocuments", {})
          .then(res => {
               resolve(res.data.data);

          })
          .catch(err => {
               console.log(err);
               reject(err)
          })
     })
} 

/**************************************** 
     * Function Name: setBatchList
     * Description: exports an action to get the batch for Data Management
     * section of the UI from the backend.
     * Method has one signature.
     * @param -batchList - The object having the necessary details required for
     * the display of the data such as batchList
****************************************/

export function setBatchList(batchList){
     return dispatch => {
          dispatch({
               type: actionTypes.SET_BATCH_LIST,
               payload: batchList
          });
     }
}  

/**************************************** 
     * Function Name: setDocumentList
     * Description: exports an action to get the batch for Data Management
     * section of the UI from the backend.
     * Method has one signature.
     * @param -documentList - The object having the necessary details required for
     * the display of the data such as documentList
****************************************/
export function setDocumentList(documentList){
     return dispatch => {
          dispatch({
               type: actionTypes.SET_BATCH_LIST,
               payload: documentList
          });
     }
}  

/**************************************** 
     * Function Name: deleteDocument
     * Description: exports an action to get the batch for Data Management
     * section of the UI from the backend.
     * Method has one signature.
     * @param -m_documentId - The object having the necessary details required for
     * the removing of the data such as document list
****************************************/
export function deleteDocument(m_documentId) {
     return dispatch => new Promise(function(resolve, reject){
          apiGet("DeleteDocument", {
               m_documentId:m_documentId
          }).then(res => {
               resolve(res);
               //console.log(res)
              
          })
          .catch(err => {
               console.log(err);
               reject(err);
          })
     })
}

/**************************************** 
     * Function Name: deleteBatch
     * Description: exports an action to get the batch for Data Management
     * section of the UI from the backend.
     * Method has one signature.
     * @param -m_documentId - The object having the necessary details required for
     * the removing of the data such as document list
****************************************/

export function deleteBatch(m_batchId) {
     return dispatch => new Promise(function(resolve, reject){
          apiGet("DeleteBatch", {
               m_batchId:m_batchId
          }).then(res => {
               resolve(res);
              //  console.log(res);
              
          })
          .catch(err => {
               console.log(err);
               reject(err);
          })
     })
}

/**************************************** 
     * Function Name: getUserBatches
     * Description: Fetch user batches
     * @param - query - Object with required parameters.
****************************************/
export function getUserBatches(query){
     return dispatch => new Promise((resolve, reject) => {
          apiGet("GetUserBatches", query).then(res => {
               resolve(res.data.data)
          })
          .catch(err => {
               console.log(err);
               reject(err);
          })
     })
}

/**************************************** 
     * Function Name: getUserProject
     * Description: Fetch user batches
     * @param - query - Object with required parameters.
****************************************/
export function getUserProject(query){
     return dispatch => new Promise((resolve, reject) => {
          apiGet("GetProjects", query).then(res => {
               resolve(res.data.data)
          })
          .catch(err => {
               console.log(err);
               reject(err);
          })
     })
}


/**************************************** 
     * Function Name: AddProject
     * Description: Fetch user batches
     * @param - query - Object with required parameters.
****************************************/
export function AddProject(query){
     return dispatch => new Promise((resolve, reject) => {
          apiGet("AddAnnotationProject", query).then(res => {
               resolve(res.data.data)
          })
          .catch(err => {
               console.log(err);
               reject(err);
          })
     })
}