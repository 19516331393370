import actionTypes from './actionTypes';
import { apiGet } from '../utilities/utils';

/**************************************** 
     * Function Name: getLexiconCount
     * Description: exports an action to get the Lexicon Count
     * from the backend.
     * The data received is set in the technology reducer which can be used
     * for manipulation. 
     * Method has no signature.
****************************************/

export function getLexiconCount() {
	return dispatch =>  {

		apiGet('GetLexiconCount', {}).then(res => {

			dispatch({
				type: actionTypes.GET_LEXICON_COUNT,
				payload: res.data.recordsTotal
			})
		})
		.catch(err => {
			console.log(err);
			
		})
	}
}

/**************************************** 
     * Function Name: getTemporalOrderingCount
     * Description: exports an action to get the Temporal Ordering Count
     * from the backend.
     * The data received is set in the technology reducer which can be used
     * for manipulation. 
     * Method has no signature.
****************************************/

export function getTemporalOrderingCount() {
	return dispatch => {
		apiGet('GetTemporalOrdering', {}).then(res => {
			dispatch({
				type: actionTypes.GET_TEMPORAL_ORDER,
				payload: res.data.recordsTotal
			})
		})
		.catch(err => {
			console.log(err);
		})
	}
}

/**************************************** 
     * Function Name: getGrammarCount
     * Description: exports an action to get the Grammar Count
     * from the backend.
     * The data received is set in the technology reducer which can be used
     * for manipulation. 
     * Method has no signature.
****************************************/

export function getGrammarCount() {
	return dispatch => {
		apiGet('GetGrammarCount', {}).then(res => {
			dispatch({
				type: actionTypes.GET_GRAMMAR_COUNT,
				payload: res.data.recordsTotal
			})
		})
		.catch(err => {
			console.log(err);
		})
	}
}

/**************************************** 
     * Function Name: getNegationsCount
     * Description: exports an action to get the Negations Count
     * from the backend.
     * The data received is set in the technology reducer which can be used
     * for manipulation. 
     * Method has no signature.
****************************************/

export function getNegationsCount () {
	return dispatch => {
		apiGet('GetNegationsCount', {}).then(res => {
			dispatch({
				type: actionTypes.GET_NEGATIONS_COUNT,
				payload: res.data.recordsTotal
			})
		})
		.catch(err => {
			console.log(err);
		})
	}
}

/**************************************** 
     * Function Name: getConceptAssociationsCount
     * Description: exports an action to get the Concepts Association Count
     * from the backend.
     * The data received is set in the technology reducer which can be used
     * for manipulation. 
     * Method has no signature.
****************************************/

export function getConceptAssociationsCount () {
	return dispatch => {
		apiGet('GetConceptAssociation', {}).then(res => {
			dispatch({
				type: actionTypes.GET_ASSOCIATION_COUNT,
				payload: res.data.recordsTotal
			})
		})
		.catch(err => {
			console.log(err);
		})
	}
}

/**************************************** 
     * Function Name: getNECount
     * Description: exports an action to get the NE Count
     * from the backend.
     * The data received is set in the technology reducer which can be used
     * for manipulation. 
     * Method has no signature.
****************************************/

export function getNECount () {
	return dispatch => {
		apiGet('GetNamedEntityCount', {}).then(res => {
			dispatch({
				type: actionTypes.GET_NE_COUNT,
				payload: res.data.recordsTotal
			})
		})
		.catch(err => {
			console.log(err);
		})
	}
}

/**************************************** 
     * Function Name: getConceptSupportCount
     * Description: exports an action to get the Concept Support Count
     * from the backend.
     * The data received is set in the technology reducer which can be used
     * for manipulation. 
     * Method has no signature.
****************************************/

export function getConceptSupportCount () {
	return dispatch => {
		apiGet('GetConceptSupport', {}).then(res => {
			dispatch({
				type: actionTypes.GET_CONCEPT_SUPPORT,
				payload: res.data.recordsTotal
			})
		})
		.catch(err => {
			console.log(err);
		})
	}
}

/**************************************** 
     * Function Name: getLexicons
     * Description: exports an action to get the Search Concepts 
     * from the backend.
     * The data received is set in the technology reducer which can be used
     * for manipulation. 
     * Method has two signatures.
     * @param - start - The starting value from which the concepts 
     * need to be fetched. 
     * @param - length - The number of concepts that have to be 
     * fetched from the starting value.
****************************************/

export function getLexicons (start, length) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('SearchConcepts', {
			'conceptTableEntry.umls_concept_category' : 'All',
			start : start,
			length: length
		}).then(res => {
			dispatch({
				type: actionTypes.GET_LEXICON_CONCEPTS,
				payload: res.data
			});
			resolve(res.data);
		})
		.catch(err => {
			console.log(err);
			reject(err);
		});
	});
	
}

/**************************************** 
     * Function Name: setItemDetail
     * Description: exports an action to set the Detail of a Techology
     * Item to be displayed to the user.
     * The itemDetail data is set in the technology reducer which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - itemDetail -An object having the details of the technology item.
****************************************/

export function setItemDetail(itemDetail) {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_TECH_ITEM_DETAIL,
			payload: itemDetail
		})
	}
}
/**************************************** 
     * Function Name: getLexiconsByConceptID
     * Description: exports an action to get the matching concepts of the concept passed through the summary analytics page.
     * The data received is set in the technology reducer which can be used
     * for manipulation. 
     * Method has three signatures.
	 * @param - m_conceptID 
	 *  @param - start - The starting value from which the concepts 
     * need to be fetched. 
     * @param - length - The number of concepts that have to be 
     * fetched from the starting value.
 ****************************************/

export function getLexiconsByConceptID (m_conceptID, start, length) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('GetAnnotationList', {
			'conceptTableEntry.concept_id' : m_conceptID,
			start : start,
			length: length
		}).then(res => {
			dispatch({
				type: actionTypes.GET_LEXICON_CONCEPTS_BY_ID,
				payload: res.data
			});
			resolve(res.data);
		})
		.catch(err => {
			console.log(err);
			reject(err);
		});
	});
	
}