import { apiGet } from "../../utilities/utils";
import store from "../../store";
import actionTypes from "../../actions/actionTypes";

// COUNTS = '', MEANS API IS NOT FETCHED YET, SHOW LOADER
const initialCounts = {
	study_count: '',
	cohort_count: '',
	data_count: '',
	patient_count: '',
};
/** ************************************
 * Function Name: getStudyList
 * Description: Fetch study list from API
 * @param query Object of parameters to supply
 *************************************/
 export const getStudyList = (query) => {
	return new Promise((resolve, reject) => {
		apiGet('GetStudyList', query).then((res) => {
			if (res.data.data && res.data.data.length) {
				res.data.data.forEach((study) => {
					study.counts = { ...initialCounts };
				});
				resolve(res.data.data);
			} else {
				resolve([]);
			}
		});
	}).catch((err) => {
		console.error(err);
		reject();
	});
};

export function openModal(modalType) {
	return new Promise((resolve,reject) => {
		store.dispatch({
			type: actionTypes.OPEN_MODAL_POPUP,
			payload: modalType
		});
	})
}

export function SetUserRole(userRole) {
	return new Promise((resolve, reject) => {
		// apiGet('GetUserRole').then(res => {
			store.dispatch({
				type: actionTypes.SET_USER_ROLE,
				payload: userRole
			})
			resolve(userRole);
		// })
		// .catch(err => {
		// 	reject(err);
		// });
	})
}