import PropTypes from 'prop-types';
import React, { useState, useEffect, lazy, Suspense } from 'react';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import constants from '../../constants';
import PanelHeading from '../../components/PanelHeading/PanelHeading';
// import CohortResultTable from '../../components/CohortResultTable/CohortResultTable';
import { setHistory, setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import { setQueryParams } from '../../utilities/utils';
import { roleAccess } from '../../utilities/AppConstants';
import Copyright from '../../components/Copyright/Copyright';
import {
	getStudyDetail,
	getCohortList,
	getCohortCriteria,
	getStudyArmList,
	searchConceptsById,
	saveStudyCohorts,
	clearStudyCohorts,
	getCohortPatient,
	GetCohortOutcomesPatientCounts,
} from './StudyResultHelper';
import { useSelector } from 'react-redux';
const CohortResultTable = lazy(() =>
	import(
		/* webpackChunkName: "CohortResultTable" */ '../../components/CohortResultTable/CohortResultTable'
	)
);
import trialIcon from '../../images/trial_icon.png';

const headerData = {
	title: 'Study',
	list: 'detail',
	img: (
		<img
			src={trialIcon}
			className={'App-TrialList'}
			alt={'Trial List'}
		/>
	),
};

function StudyResult(props) {
	const [baseCohort, setBaseCohort] = useState({});
	const [loading, setLoading] = useState(true);
	const sidenavProps = useSelector((state) => state.sidenav);
	const studyProps = useSelector((state) => state.study);

	useEffect(() => {
		const { studyId } = props.match.params;

		getStudyDetail(studyId).then((studyDetail) => {
			getCohortList().then((cohortList) => {
				const _baseCohort =
					cohortList.data.data &&
					cohortList.data.data.filter((cohort) => {
						if (cohort.cohort_id == studyDetail.base_cohort) {
							return cohort;
						}
					});
				setBaseCohort(_baseCohort[0]);
				getStudyArmList(studyId).then((studyArmList) => {
					const PromiseArrCohort =
						(studyArmList &&
							studyArmList.map((studyArm, index) => {
								return getCohortCriteria(studyArm.cohort_id);
							})) ||
						[];

					PromiseArrCohort.splice(0, 0, getCohortCriteria(studyDetail.base_cohort));

					Promise.all(PromiseArrCohort).then((cohortCriterion) => {
						cohortCriterion &&
							cohortCriterion.map((criterion, index) => {
								const cohortIndex = index;
								let conceptIds = [];
								let eventIds = [];
								criterion.data &&
									criterion.data.data &&
									criterion.data.data.map((criteria) => {
										if (criteria.criteria_type == 'EventId') {
											eventIds.push(criteria.event_id);
										} else {
											conceptIds.push(criteria.concept_id);
										}
									});

								searchConceptsById(conceptIds, eventIds.join(',')).then(
									(criteriaConcepts) => {
										const studyCohorts = [];
										const dataObj = criterion.data.data.map(
											(criteria, index) => {
												const cohortObj =
													cohortList.data.data &&
													cohortList.data.data.find((cohort, index) => {
														return (
															cohort.cohort_id == criteria.cohort_id
														);
													});
												let associatedConcept;
												if (criteria.criteria_type == 'EventId') {
													associatedConcept = criteriaConcepts.filter(
														(items) =>
															items.event_id == criteria.event_id
													)[0];
												} else {
													associatedConcept = criteriaConcepts.filter(
														(items) => items.cuid == criteria.concept_id
													)[0];
												}
												return {
													...criteria,
													cohort: cohortObj,
													concepts: associatedConcept,
												};
											}
										);

										const firstCohort = dataObj[0];
										if (firstCohort) {
											getCohortPatient(firstCohort.cohort.cohort_id).then(
												(cohortPatients) => {
													GetCohortOutcomesPatientCounts(
														firstCohort.cohort.cohort_id
													).then((res) => {
														const outcomeEvents =
															res.data.payload || [];
														studyCohorts.push({
															...firstCohort,
															cohort: {
																...firstCohort.cohort,
																cohortPatients:
																	cohortPatients.length,
																outcomeEvents,
															},
														});
														if (cohortIndex == 0) {
															setBaseCohort({
																..._baseCohort[0],
																patientCount: cohortPatients.length,
																outcomeEvents,
															});
															setLoading(false);
														}
														dataObj &&
															dataObj.forEach((data, index, self) => {
																if (index != 0) {
																	studyCohorts.push(data);
																}

																if (index == self.length - 1) {
																	saveStudyCohorts(
																		studyCohorts,
																		cohortIndex
																	);
																}
															});
													});
												}
											);
										} else {
											let cohortListId = baseCohort.cohort_id ? baseCohort.cohort_id :  _baseCohort[0].cohort_id
											getCohortPatient(cohortListId).then(
												(cohortPatients) => {
													GetCohortOutcomesPatientCounts(
														cohortListId
													).then((res) => {
														const outcomeEvents =
															res.data.payload || [];
														setBaseCohort({
															..._baseCohort[0],
															patientCount: cohortPatients.length,
															outcomeEvents,
														});
														setLoading(false);
													});
												}
											);
										}
									}
								);
							});
					});
				});
			});
		});
	}, []);

	useEffect(() => {
		return () => {
			clearStudyCohorts();
		};
	}, []);

	const goToStudyPatient = (cohortId, cohortName) => {
		let filterLabel = {
			page: 'patient',
			options: [
				{
					filterLabel: 'Cohort',
					name: cohortName,
				},
			],
		};
		setFilterLabel(filterLabel);
		setHistory(false, studyProps.studyDetail.study_name);
		if (sidenavProps.userRole.Role == roleAccess.ANNOTATOR) {
			setQueryParams({
				pathname: constants.ROUTE_URL + '/dashboard/datalist',
				query: { cohort_id: cohortId },
			});
		} else {
			setQueryParams({
				pathname: constants.ROUTE_URL + '/dashboard/patientlist',
				query: {
					cohort_id: cohortId,
				},
			});
		}
	};

	const { studyDetail, studyCohorts } = studyProps;
	const studyArms = studyCohorts.slice(1);
	let { userRole } = sidenavProps;
	return (
		<div>
			<HeaderTitles
				data={headerData}
				search={false}
				studyId={props.match.params.studyId}
				{...props}
			/>
			<div>
				<h3 className="v-cohort-name">Result: {studyDetail.study_name}</h3>
			</div>
			<PanelHeading heading="Base cohort" />
			<div style={{ position: 'relative' }}>
				{loading && <div className="v-loading-spinner" />}
				<div className="study-result-container">
					<Suspense fallback={<div className="defaultSpinner" />}>
						<CohortResultTable
							handlePatientClick={() =>
								goToStudyPatient(baseCohort.cohort_id, baseCohort.cohort_name)
							}
							cohort={baseCohort}
							userRole={userRole}
						/>
					</Suspense>
				</div>

				{studyArms.map((studyArm, index) => {
					const cohort = studyArm[0].cohort;
					return (
						<div key={index}>
							<PanelHeading heading={'Study arm ' + (index + 1)} />

							<div className="study-result-container">
								<Suspense fallback={<div className="defaultSpinner" />}>
									<CohortResultTable
										handlePatientClick={() =>
											goToStudyPatient(cohort.cohort_id, cohort.cohort_name)
										}
										cohort={{
											patientCount: cohort.cohortPatients,
											outcomeEvents: cohort.outcomeEvents,
											cohort_id: cohort.cohort_id,
										}}
									/>
								</Suspense>
							</div>
						</div>
					);
				})}
			</div>
			<Copyright />
		</div>
	);
}

StudyResult.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			studyId: PropTypes.any,
		}),
	}),
};

export default StudyResult;
