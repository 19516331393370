import { apiPost } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';

export const clearData = () => {
	return new Promise((resolve, reject) => {
		apiPost('Logout', '')
			.then((res) => {
				store.dispatch({
					type: actionTypes.RESET_STATE,
					payload: null,
				});
				store.dispatch({
					type: actionTypes.GET_USER_ROLE,
					payload: '',
				});

				sessionStorage.clear();
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const openModal = (modalType) => {
	return new Promise((resolve, reject) => {
		store.dispatch({
			type: actionTypes.OPEN_MODAL_POPUP,
			payload: modalType,
		});
		resolve();
	});
};

export const removeFilterText = (filterText) => {
	return new Promise((resolve, reject) => {
		store.dispatch({
			type: actionTypes.REMOVE_FILTER_TEXT,
		});
		resolve();
	});
};
