import React, { useState, useEffect } from 'react';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { useSelector } from 'react-redux';
import Loading from 'react-loading-spinner';
import { rearrangeConceptCategories } from '../../../utilities/utils';
import { convertToPlural } from '../../../utilities/utils';
import { setQueryParams } from '../../../utilities/utils';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Copyright from '../../../components/Copyright/Copyright';
import { setIsLoading } from '../../../actions/common';

import { getSubdocumentById, getConceptCategories, getHistoryData } from './DataAutomatedHelper';
import dataIcon from '../../../images/data_icon.png';
const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};

const DataAutomated = (props) => {
	const [doc_name, setDocName] = useState('');
	const [autoAnnotationList, setAutoAnnotationList] = useState([]);
	const [sectionedJson, setSectionedJson] = useState([]);
	const [content_type, setContentType] = useState([]);
	const [distinctCategory, setDistinctCategory] = useState([]);
	const historyreducer = useSelector((state) => state.historyreducer);
	const loading = useSelector((state) => state.loading)?.loading;

	useEffect(() => {
		let { docId } = props.match.params;
		setIsLoading(true);
		getSubdocumentById(docId).then((resp) => {
			setDocName(resp?.data?.data?.name_with_date);
			setContentType(resp?.data?.data?.content_type);
		});

		//Fetch the concept categories from data store if present else fire an API to fetch it
		let categories = [];
		if (historyreducer?.conceptCategoryList?.length > 0) {
			categories = rearrangeConceptCategories(historyreducer.conceptCategoryList);
			categories.push('Others');
			setDistinctCategory(categories);
		} else {
			getConceptCategories().then((resp) => {
				categories = rearrangeConceptCategories(resp);
				categories.push('Others');
				setDistinctCategory(categories);
			});
		}

		getHistoryData(docId).then((sectionedJson) => {
			let arrEntities = [];
			sectionedJson.map((section) => {
				section.entities.map((item) => {
					if (!item.Concept_category || item.Concept_category === 'null') {
						item.Concept_category = 'Others';
					}
					arrEntities.push({ ...item, section_id: section.section_id });
				});
			});
			//Sort the list of section annotations in the ascending order of the concept name
			arrEntities = arrEntities.sort(function (a, b) {
				if (a.Text.toLowerCase() < b.Text.toLowerCase()) return -1;
				if (a.Text.toLowerCase() > b.Text.toLowerCase()) return 1;
				return 0;
			});

			setSectionedJson(sectionedJson);
			setAutoAnnotationList(arrEntities);
			setIsLoading(false);
		});
	}, []);

	const gotoDatadetail = () => {
		props.history.push(constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId);
	};

	const automatedAnnotationClick = (annotation) => {
		setQueryParams({
			pathname: constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId,
			query: {
				automated_annotation_id:
					annotation?.section_id +
					'_' +
					annotation?.BeginOffset +
					'_' +
					annotation?.EndOffset,
			},
		});
	};

	return (
		<div className="DataAutomated">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					history={props.history}
					match={props.match}
				/>
			</ErrorBoundary>

			<div className="document-header-container">
				<span>Automated annotations: </span>
				<span
					style={{ textDecoration: 'none' }}
					className="hyperlink"
					id="testid-Data-detail"
					onClick={gotoDatadetail}
				>
					{doc_name}
				</span>
			</div>
			{distinctCategory &&
				distinctCategory.map((category, indexCat) => {
					return (
						<React.Fragment key={indexCat + 'category'}>
							<div className="section-header">
								{category
									? convertToPlural(category) &&
									  convertToPlural(category)?.length > 0
										? convertToPlural(category)
										: 'Others'
									: 'Others'}
							</div>
							<ul>
								{autoAnnotationList &&
									autoAnnotationList
										.filter((annotation) => {
											return annotation.Concept_category == category;
										})
										.map((items, index) => {
											return (
												<li key={items?.Text + index}>
													<div className="list-item automated-annotation-id">
														<a
															className="hyperlink"
															id="testid-automated-annotation"
															onClick={() =>
																automatedAnnotationClick(items)
															}
														>
															{!items?.Experienced ? 'No ' : ''}

															{items?.Text}
														</a>{' '}
														(
														{items?.ConceptCodes &&
														Array.isArray(items?.ConceptCodes)
															? items?.ConceptCodes[0].CodeSystem +
															  ' ' +
															  items?.ConceptCodes[0].CodeValue
															: ''}
														)
													</div>
												</li>
											);
										})}	
							</ul>
						</React.Fragment>
					);
				})}
			<Copyright />
		</div>
	);
};

export default DataAutomated;