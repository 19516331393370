import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Alert, FormControl } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from '../../actions';
import constants from '../../constants';
import logo from '../../images/logo.svg';
import { getObjectFromLocal, setObjectToLocal } from '../../utilities/utils';

function CodeVerification(props) {
	const [code, setCode] = useState('');
	const [err, setErr] = useState('');
	const [placeholder, setPlaceholder] = useState('Verification code');
	let { codeErr } = props.auth;
	const [showAnimate, setShowAnimate] = useState(false);

	const focusPlaceholder = (e) => {
		setPlaceholder('');
	};

	const blurPlaceholder = (e) => {
		setPlaceholder('Verification code');
	};

	useEffect(() => {
		setShowAnimate(true);
		const element = document.getElementsByTagName('body')[0];
		element?.classList?.add('bg-image');
		const user = getObjectFromLocal('user') || {};
		const email = user.email;
		props.dispatch(actions.Auth0Login(email));
		return () => {
			const element = document.getElementsByTagName('body')[0];
			element?.classList?.remove('bg-image');
		};
	}, []);

	useEffect(() => {
		if (props.auth.step2verify) {
			props.history.push(constants.ROUTE_URL + '/dashboard/studylist');
		}
	}, [props.auth.step2verify]);

	const updateValue = (event) => {
		setCode(event.target.value);
		setErr('');
	};

	const verifyCode = () => {
		setErr('');
		if (code === '') {
			setErr('Please enter a code.');
			return;
		} else {
			const authSecret = getObjectFromLocal('authSecret') || {};
			const user = getObjectFromLocal('user') || {};
			const email = user.email;

			if (constants.ALLOW_AUTH0_ACCESS_URL == 'Y') {
				props
					.dispatch(actions.Auth0VerifyAuthenticationCode(authSecret.secret, code, email))
					.then((res) => {
						setObjectToLocal('user', { email, codeVerified: true });
						props.history.push(constants.ROUTE_URL + '/dashboard/studylist');
					})
					.then((err) => {
						setErr('Invalid code. Please try again.');
					})
					.catch((err) => {
						setErr('Invalid code. Please try again.');
					});
			} else {
				props
					.dispatch(actions.verifyAuthenticationCode(authSecret.secret, code))
					.then((res) => {
						setObjectToLocal('user', { email, codeVerified: true });
						props.history.push(constants.ROUTE_URL + '/dashboard/studylist');
					});
			}
		}
	};

	const handleKeyPress = (target) => {
		if (target.charCode == 13) {
			verifyCode();
		}
	};

	return (
		<CSSTransition in={showAnimate} timeout={3000} classNames="fade">
			<div className="login-container">
				<div className="login">
					<div className="login-header">
						<div className="logo-wrapper">
							<img className="logo_img" src={logo} alt="Verantos_Logo" />
						</div>
						<span className="login-text">Code verification</span>
					</div>
					<div className="singnature-text">
						New verification code sent to your registered mobile and email. Please enter
						verification code.
					</div>
					<div className="form-field-control">
						<FormControl
							className="form-field-input"
							type="text"
							name="code"
							value={code}
							id="verificationCode"
							placeholder={' '}
							onBlur={blurPlaceholder}
							autoFocus
							onClick={focusPlaceholder}
							onChange={updateValue}
							onKeyPress={handleKeyPress}
						/>
						<label
							htmlFor="verificationCode"
							id="email-label"
							className="form-field-label"
						>
							Verification Code
						</label>
					</div>
					<div className="login-error_field">{err || codeErr}</div>
					<p className="submit">
						<button
							type="submit"
							name="commit"
							value="Verify"
							id="verifyCode"
							className="btn-code-verify"
							onClick={verifyCode}
						>
							Submit
						</button>
					</p>
				</div>
			</div>
		</CSSTransition>
	);
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(CodeVerification);
