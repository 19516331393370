import React, { useState } from 'react';
import { useEffect } from 'react';
import NavLink from '../../common/NavLink/NavLink';
import constants from '../../constants';
import { roleAccess } from '../../utilities/AppConstants';
import studySmallBlue from '../../images/study-small-blue.svg';
import studySmallWhite from '../../images/study-small-white.svg';
import cohortSmallBlue from '../../images/cohort-small-blue.svg';
import cohortSmallWhite from '../../images/cohort-small-white.svg';
import patientSmallBlue from '../../images/patient-small-blue.svg';
import patientSmallWhite from '../../images/patient-small-white.svg';
import docSmallBlue from '../../images/doc-small-blue.svg';
import docSmallWhite from '../../images/doc-small-white.svg';

const MenuItem = ({ children }) => <div className="menu-item">{children}</div>;

const RoleBasedNavLink = (props) => {
	let { userRole } = props;
	const index = localStorage.getItem('activeIndex');
	let isRoleCompany = false;
	if (userRole == 'Company') {
		isRoleCompany = true;
	}

	return (
		<React.Fragment>
			{userRole != roleAccess.ANNOTATOR && (
				<>
					<NavLink
						className="event"
						id="testid-studylist"
						onClick={(event) =>
							props.onClick(event, constants.ROUTE_URL + '/dashboard/studylist')
						}
						to={constants.ROUTE_URL + '/dashboard/studylist'}
						name="study"
					>
						<MenuItem hash="">
							<img
								src={
									index == 'testid-studylist'
										? studySmallBlue
										: studySmallWhite
								}
								className="nav-study"
								alt="copyfile"
							/>
						</MenuItem>
					</NavLink>
					<NavLink
						className="event"
						id="testid-cohortlist"
						onClick={(event) =>
							props.onClick(event, constants.ROUTE_URL + '/dashboard/cohortlist')
						}
						to={constants.ROUTE_URL + '/dashboard/cohortlist'}
						name="cohort"
					>
						<MenuItem hash="">
							<img
								src={
									index == 'testid-cohortlist'
										? cohortSmallBlue
										: cohortSmallWhite
								}
								className="nav-cohort"
								alt="multi_user"
							/>
						</MenuItem>
					</NavLink>
					<NavLink
						className="patientTab event"
						id="testid-patientlist"
						onClick={(event) =>
							props.onClick(event, constants.ROUTE_URL + '/dashboard/patientlist')
						}
						to={constants.ROUTE_URL + '/dashboard/patientlist'}
						name="patient"
					>
						<MenuItem hash="">
							<img
								src={
									index == 'testid-patientlist'
										? patientSmallBlue
										: patientSmallWhite
								}
								className="nav-patient"
								alt="user"
							/>
						</MenuItem>
					</NavLink>
					<NavLink
						className="dataTab event"
						id="testid-datalist"
						onClick={(event) =>
							props.onClick(event, constants.ROUTE_URL + '/dashboard/datalist')
						}
						to={constants.ROUTE_URL + '/dashboard/datalist'}
						name="data"
					>
						<MenuItem hash="">
							<img
								src={
									index == 'testid-datalist'
										? docSmallBlue
										: docSmallWhite
								}
								className="nav-data"
								alt="nav-data"
							/>
						</MenuItem>
					</NavLink>
				</>
			)}
			{userRole == roleAccess.ANNOTATOR && (
				<NavLink
					className="dataTab event"
					id="testid-dataworkqueue"
					onClick={(event) =>
						props.onClick(event, constants.ROUTE_URL + '/dashboard/dataworkqueue')
					}
					to={constants.ROUTE_URL + '/dashboard/dataworkqueue'}
					name="data"
				>
					<MenuItem hash="">
						<img
							src={
								index == 'testid-datalist'
									? docSmallBlue
									: docSmallWhite
							}
							className="nav-data"
							alt="nav-data"
						/>
					</MenuItem>
				</NavLink>
			)}
			{isRoleCompany && (
				<NavLink
					className="event"
					id="testid-technology"
					onClick={(event) =>
						props.onClick(event, constants.ROUTE_URL + '/dashboard/technology')
					}
					to={constants.ROUTE_URL + '/dashboard/technology'}
					name="technology"
				>
					<MenuItem hash="">
						<img
							src={constants.IMG_URL + 'left_nav_technology.png'}
							className="nav-technology"
							alt="setting"
						/>
					</MenuItem>
				</NavLink>
			)}
		</React.Fragment>
	);
};

export default RoleBasedNavLink;
