
import actionTypes from '../../../actions/actionTypes';
import store from '../../../store';
import { apiGet } from '../../../utilities/utils';
import { setTotalPatients } from '../../PatientList/PatientListHelper';

export function GetUserRole() {
	return new Promise((resolve, reject) => {
		apiGet('GetUserRole').then(res => {
			store.dispatch({
				type: actionTypes.GET_USER_ROLE,
				payload: res.data.data
			})
			resolve(res.data.data)
		})
		.catch(err => {
			reject(err);
		});
	})
}

/****************************************
 * Function Name: getQueryParams
 * Description: Determine and return query parameters object based on given path parameters
 * @param pageType - 0 => patient list, 1 => data list page
 * @param pageNumber page number from pagination component
 * @param searchText Text to search
 * @param orgId Organization id
 ****************************************/
 const getQueryParams = (pageType, pageNumber, searchText, orgId, recordsPerPage) => {
	const query = { pageNumber, pageSize: recordsPerPage };
	if (pageType === 1) {
		query.m_searchByPatient = false;
	}

	//CHECK FOR IS SEARCH REQUESTED
	if (searchText) {
		query.m_searchString = searchText;
		query.m_searchByPatient = pageType === 0 ? true : false;
	}
	//PROVIDE ORGANIZATION ID IF PRESENT
	if (orgId) {
		query.getOrganizationPatient = true;
		query.Organization_id = orgId;
	}

	const params = window.location.search.split(/[?&=]/);

	if (params.indexOf('study_id') !== -1) {
		query.m_studyId = params[params.indexOf('study_id') + 1];
	} else if (params.indexOf('cohort_id') !== -1) {
		query.m_cohortId = params[params.indexOf('cohort_id') + 1];

		if (params.indexOf('outcome_id') !== -1) {
			query.m_outcomeId = params[params.indexOf('outcome_id') + 1];
		}
	} else if (params.indexOf('patient_id') !== -1) {
		query.m_PatientIdList = params[params.indexOf('patient_id') + 1];
		query.m_searchByPatient = pageType === 1 ? false : true;
	}

	//PROVIDE CONCEPT ID IF PRESENT
	if (params.indexOf('concept_id') !== -1) {
		let conceptId = params[params.indexOf('concept_id') + 1];
		if (conceptId) {
			query.m_conceptId = conceptId;
			query.flagForPatientWithConcept =
				params[params.indexOf('flagForPatientWithConcept') + 1];
		}
	}

	if (params.indexOf('event_id') !== -1) {
		let eventId = params[params.indexOf('event_id') + 1];

		if (eventId != 0) {
			query.m_eventId = eventId;
			query.flagForPatientWithConcept =
				params[params.indexOf('flagForPatientWithConcept') + 1];
		}
	}
	if (params.indexOf('forResult') !== -1) {
		query.forResult = params[params.indexOf('forResult') + 1];
	}

	if (params.indexOf('m_conceptSeverity') !== -1) {
		query.m_conceptSeverity = params[params.indexOf('m_conceptSeverity') + 1];
	}

	return query;
};

/****************************************
 * Function Name: getPatientListAnnotator
 * Description: Fetch patients from API.
 * @param pageType - 0 => patient list, 1 => data list page
 * @param pageNo Page number from pagination
 * @param searchText Text to search
 * @param organizationId
 ****************************************/
export const getPatientListAnnotator = (
	pageType = 0,
	pageNo,
	searchText,
	organizationId = '',
	isWorkQueue,
	recordsPerPage
) => {
	let totalCount = -1;
	/**
	 * API will return total patient count only for page 1, further count will not be available
	 * Hence, -1 means no need to update in state, 0 means no patients available
	 */
	return new Promise((resolve, reject) => {
		const queryParams = getQueryParams(
			pageType,
			pageNo,
			searchText,
			organizationId,
			recordsPerPage
		);
		if (isWorkQueue) {
			queryParams.workQueue = true;
		}
		let storedData = store.getState();
		let storedPatientList =
			storedData?.patient?.annotatorPatientList?.[`patientlist_${pageNo}_${recordsPerPage}`];
		let totalCount = storedData?.patient?.totalPatients;
		
		if (storedPatientList && storedPatientList.length > 0) {
			resolve({ count: totalCount, list: storedPatientList });
		} else {
			apiGet('GetPatient', queryParams)
				.then((res) => {
					//SET TOTAL NO OF PATIENT IN REDUX FOR PAGINATION COMPONENT
					if (res.data.noOfRecords) {
						setTotalPatients(res.data.noOfRecords);
						totalCount = res.data.noOfRecords;
					}

					const patients = res.data.data;

					//ADD COUNT ATTRIBUTES OBJECT, TO SHOW LOADER
					patients.map(
						(patient) =>
							(patient.counts = {
								study_count: '',
								cohort_count: '',
								data_count: '',
								patient_count: '',
								personal_count: patient.personal_count,
								total_count: patient.total_count,
							})
					);

					store.dispatch({
						type: actionTypes.SET_ANNOTATOR_PATIENT_LIST,
						payload: { [`patientlist_${pageNo}_${recordsPerPage}`]: patients },
					});

					resolve({ count: totalCount, list: patients });
				})
				.catch((err) => {
					// console.error(err);
					reject(err);
				});
		}
	});
};

/****************************************
 * Function Name: getPatientIcons
 * Description: get cohort and manual annotation information to determine
 * whether to show icon or not
 ****************************************/
 export const getPatientIcons = (patientFromState, patientIds, isAnnotatator) => {
	return new Promise((resolve, reject) => {
		//CREATE SEPARATE OBJECT FOR EACH PATIENT FROM ID LIST
		const patientList = patientIds.split(',').map((pid) => ({ pid }));

		const promiseArr = [];

		//GET COHORT INFO
		if (!isAnnotatator) {
			promiseArr.push(apiGet('GetCohortListForPatient', { m_PatientIdList: patientIds }));
		}

		//GET MANUAL ANNOTATION INFO
		const queryParam = { m_DocLevelManAnnotation: false, pageNumber: 1, pageSize: 10 };

		Promise.all(promiseArr).then(
			(res) => {
				const conceptList = res.length > 0 && res[0].data.data;
				if (!conceptList) {
					return;
				}

				//DETERMINE HAS COHORT AND CREATE OUTPUT IN ARRAY
				patientList.forEach((patient, pindex) => {
					const uniqueCohorts = [];

					let patientConcept = conceptList.filter(
						(item) => item.patient_id == patient.pid
					);
					let uniqueCohortIds = [
						...new Set(patientConcept.map((item) => item.cohort_id)),
					];

					patient.cohorts = [];
					uniqueCohortIds.map((cohort_id) => {
						patient.cohorts.push(
							patientConcept.filter((item) => item.cohort_id == cohort_id)[0]
						);
					});
					// console.log("1",patient.cohorts);

					patient.cohorts.map((cohort, cindex) => {
						patient.cohorts[cindex].concepts = patientConcept.filter(
							(item) => item.cohort_id == cohort.cohort_id
						);
					});
					// console.log("2",patient.cohorts);
					// const map = new Map();
					// conceptList.forEach(cohort => {
					//     //SINCE API RETURNS PATIENT ID AS STRING DO NOT USE STRICT COMPARISON IN FOLLOWING CASE
					//     if (cohort.patient_id == patient.pid && !map.has(cohort.cohort_id)) {
					//         map.set(cohort.cohort_id, true);
					//         uniqueCohorts.push(cohort);
					//     }
					// })
					// uniqueCohorts.forEach(cohort => {
					//     cohort.concepts = [];
					// })
					// //MAP CONCEPTS WITH EACH COHORTS
					// conceptList.forEach(concept => {

					//     const cohortIndex = uniqueCohorts.findIndex(cohort => cohort.cohort_id === concept.cohort_id && cohort.patient_id == concept.patient_id);
					//     if (cohortIndex !== -1) {
					//         uniqueCohorts[cohortIndex].concepts.push(concept);
					//     }
					// })

					// patient.cohorts = uniqueCohorts;
					patientList[pindex].cohorts = patient.cohorts;
				});

				//DETERMINE AND MAP MANUAL ANNOTATION ICON FLAG
				// const manualAnnotations = res.slice(1);
				// manualAnnotations.forEach((mAnn, index) => {
				//     patientList[index].hasManAnnotation = mAnn.data.manAnnCount > 0;
				// })
				// console.log(patientFromState,patientList);
				//MAP PATIENT AND ICONS BASED ON PATIENT ID
				const patientWithIcons = mapIconsWithPatients(patientFromState, patientList);
				resolve(patientWithIcons);
			},
			(err) => {
				console.error(err);
				reject();
			}
		);
	});
};
