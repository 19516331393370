import React from 'react';
import actions from '../../actions/index';
import store from '../../store';
import constants from '../../constants';
import { getRoutePath, setQueryParams } from '../../utilities/utils';
// CONTAINS COMMON FUNCTION OF FILTER FUNCTIONALITY.

/** **************************************
 * Function Name: setFilterLabel
 * Description: Sets label of filter in left navigation pane.
 * @param page target page
 * @param filterLabel Caption of filter.
 * @param name Specific name in filter label
****************************************/
export const setFilterLabel = (incomingFilter) => {
   
    let filter = {};
    filter.page = incomingFilter.page || '';
    filter.options = incomingFilter.options || [];

    // if(name1){
    //     filter.options.push({filterLabel:filterLabel,name: name });
    //     filter.options.push({filterLabel:filterLabel1,name: name1 });
    // } else {
    //     filter.options.push({filterLabel:filterLabel,name: name });
    // }



    // let filter ="";
    
    // if(filterLabel1){
    //     filter = (
    //         <React.Fragment>
    //             <a className="filterText" title={filterLabel + ': ' + name}>{filterLabel + ': ' + name}
    //                 <div className="removeCross">
    //                     <a href="javascript:;" onClick={() => removeBreadCrumb(page,filterLabel)}>
    //                         <i className="fas fa-times" />
    //                     </a>
    //                 </div>
    //             </a>
    //             <a className="filterText" title={filterLabel1 + ': ' + name1}>{filterLabel1 + ': ' + name1}
    //                 <div className="removeCross">
    //                     <a href="javascript:;" onClick={() => removeBreadCrumb(page,filterLabel)}>
    //                         <i className="fas fa-times" />
    //                     </a>
    //                 </div>
    //             </a>
    //         </React.Fragment>
    //     );

    // } else{
    //     filter = (
    //         <a className="filterText" title={filterLabel + ': ' + name}>{filterLabel + ': ' + name}
    //             <div className="removeCross">
    //                 <a href="javascript:;" onClick={() => removeBreadCrumb(page,filterLabel)}>
    //                     <i className="fas fa-times" />
    //                 </a>
    //             </div>
    //         </a>
    //     );
    // }
 

    // SET FILTER LABEL
    store.dispatch(actions.setFilterText(filter));
};
/** *************************************
 * Function Name: gotoListPage.

 * Description: This method navigates the user to given listing page.
 * @param {string} page target page
 * @param {string} organization_id Organization id.
 * @param {string} organization_name Name of the organization.
 ****************************************/
export const gotoListPage = (page, organization_id, organization_name, isPopup) => {
    let query = { filter: true };;
    
    if(page =='cohort' && isPopup){
       delete query.filter;
    }

    // REMOVE FILTER IF ANY
    store.dispatch(actions.setFilterText(null));

    if (organization_id) {
        let filterLabel ={
            page: page,
            options:[
              {
                filterLabel:'Organization',
                name:organization_name
              }
            ]
          }
        setFilterLabel(filterLabel);

        query.filter = true;
        query.organization_id = organization_id;
    }
    var urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get('study_id')){
      query.study_id = urlParams.get('study_id');
      store.dispatch(actions.getStudyDetail(query.study_id)).then(studyDetail => {
            let filterLabel ={
                page: page,
                options:[
                    {
                        filterLabel:'Study',
                        name:studyDetail.study_name
                    }
                ]
            }
            if(organization_name){
                filterLabel.options.push(
                    {
                        filterLabel:'Organization',
                        name:organization_name
                    }
                )
            }
            setFilterLabel(filterLabel);
        });	
    }
    if(urlParams.get('cohort_id')){
        query.cohort_id = urlParams.get('cohort_id');
        let _state = store.getState();
        let cohort_name =_state.cohort.cohortList.filter(items=> items.cohort_id==query.cohort_id)[0].cohort_name; 
        if (organization_id) {
            let filterLabel ={
                page: page,
                options:[
                    {
                        filterLabel:'Cohort',
                        name:cohort_name
                    },
                    {
                        filterLabel:'Organization',
                        name:organization_name
                    }
                ]
            }
            setFilterLabel(filterLabel);
        } else{
            let filterLabel ={
                page: page,
                options:[
                    {
                        filterLabel:'Cohort',
                        name:cohort_name
                    }
                ]
            }
            setFilterLabel(filterLabel);
        }
    }

    const path = getRoutePath(page);
   
    setHistory(isPopup);
     setQueryParams(
        { pathname: path, query: query }
    );
};

/**
 * Function Name: gotoListPage_patient
 * Description: Navigates to target page from any patient level.
 * @param {string} page Target page.
 * @param {number} organization_id Db id of organization.
 * @param {number} patient_id Patient id.
 * @param {string} patient_identifier Identifier of patient.
 */
export const gotoListPage_patient = (page, organization_id, patient_id, patient_identifier) => {
    setHistory(false, patient_identifier);
    let filterLabel ={
        page: page,
        options:[
            {
                filterLabel:'Patient',
                name:patient_identifier
            }
        ]
    }

    setFilterLabel(filterLabel);

    const path = getRoutePath(page);

    const query = { patient_id, filter: true };

    if (organization_id) {
        query.organization_id = organization_id;
    }

     setQueryParams(
        { pathname: path, query: query }
    );
};

/**
 * Function Name: gotoListPage_document
 * Description: Navigates to target page from document level.
 * @param {string} page Target page.
 * @param {number} organization_id Organization id.
 * @param {Number} patient_id Patient id.
 * @param {string} document_list Comma seperated list of subdocuments.
 * @param {string} documentName Name of encounter document.
 */
export const gotoListPage_document = (page, organization_id, patient_id, document_list, documentName) => {
    setHistory();
    let filterLabel ={
        page: page,
        options:[
            {
                filterLabel:'Document',
                name:documentName
            }
        ]
    }

    setFilterLabel(filterLabel);

    const path = getRoutePath(page);

     setQueryParams({
        pathname: path,
        query: {
            filter: true,
            organization_id,
            patient_id,
            document_list: document_list,
        },
    });
};


/** **************************************
 * Function Name: setHistory
 * Description: Sets history label in left navigation pane.
 * @param isPopup Boolean indicating whether request initiated from popup or not
 * @param labelParameter Specific name to show in history label
****************************************/
export const setHistory = (isPopup, labelParameter = '') => {
   
    if (isPopup) {
        return;
    }
   
    let pageName = '';
    const path = window.location.pathname;
    if (path.indexOf('datalist') != -1 || path.indexOf('dataworkqueue') !== -1) {
        pageName = 'Data list';
    } else if (path.indexOf('patient/') != -1) {
        pageName = 'Patient detail: ' + labelParameter;
    } else if (path.indexOf('patient') != -1) {
        pageName = 'Patient list';
    } else if (path.indexOf('cohortdesign') != -1) {
        pageName = 'Cohort detail:' + labelParameter;
    } else if (path.indexOf('cohortresult') != -1) {
        pageName = 'Cohort detail: ' + labelParameter;
    } else if (path.indexOf('cohort') != -1) {
        pageName = 'Cohort list';
    } else if (path.indexOf('studyvalidity') !== -1 || path.indexOf('studydesign') !== -1) {
        pageName = 'Study detail: ' + labelParameter;
    } else if (path.indexOf('dashboard/studyresult') !== -1 || path.indexOf('studydesign') !== -1) {
        pageName = 'Study detail:'+ labelParameter;
    } else if (path.indexOf('dashboard') !== -1) {
        pageName = 'Study list';
    } 

    

    store.dispatch(actions.setHistory({ ...window.location, display_name: pageName }));
};