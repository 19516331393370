import React, { useState, useEffect } from 'react';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import constants from '../../constants';
import { getCohortById, getCohortCriteria, searchConceptsById } from './CohortDesignHelper';
import PanelHeading from '../../components/PanelHeading/PanelHeading';
import store from '../../store';
import actions from '../../actions';
import { formatDate } from '../../utilities/utils';
import { setHistory, setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import Copyright from '../../components/Copyright/Copyright';
import PropTypes from 'prop-types';
import { setIsLoading } from '../../actions/common';
import cohortIcon from '../../images/cohort_icon.png';

const headerData = {
	title: 'Cohort',
	list: 'detail',
	img: (
		<img
			src={cohortIcon}
			className={'App-CohortList'}
			alt={'Cohort List'}
		/>
	),
};

function CohortDesign(props) {
	const [cohort, setCohort] = useState('');
	const [criteria, setCriteria] = useState(props._criteria ? props._criteria : []);
	const [showLoading, setShowLoading] = useState(false);
	const [indexEvent, setIndexEvent] = useState([]);
	const [inclusionEvent, setInclusionEvent] = useState([]);
	const [exclusionEvent, setExclusionEvent] = useState([]);
	const [isRoleCompany , setIsRoleCompany] = useState(false);
	// const isRoleCompany = props.sidenav?.userRole?.Role === 'Company';
	let loading = props?.loading?.loading;

	useEffect(() => {
		setFilterLabel({});
		setIsLoading(true);
		getCohortData(parseInt(props.match.params.cohort_id, 10));
	}, []);

	useEffect(()=>{
		if(props.sidenav?.userRole?.Role == 'Company'){
			setIsRoleCompany(true)
		}
		
	}, [props.sidenav?.userRole?.Role])

	useEffect(() => {
		if (criteria) {
			let _indexEvent = sort(criteria.filter((item) => item.is_index_event == 'true'));
			let _inclusionEvent = sort(criteria.filter((item) => item.is_inclusion == 'true'));
			let _exclusionEvent = sort(
				criteria.filter(
					(item) => item.is_inclusion == 'false' && item.is_index_event == 'false'
				)
			);
			setIndexEvent(_indexEvent);
			setInclusionEvent(_inclusionEvent);
			setExclusionEvent(_exclusionEvent);
		}
	}, [criteria]);

	const gotoTechnology = (id, text) => {
		let filterLabel = {
			page: 'study',
			options: [
				{
					filterLabel: 'Cohort',
					name: text,
				},
			],
		};

		setFilterLabel(filterLabel);
		setHistory(false, text);
		props.history.push(constants.ROUTE_URL + '/dashboard/techdetail');
	};

	const getCohortData = (cohortId) => {
		setShowLoading(true);
		setIsLoading(true);
		getCohortById(cohortId).then((cohort) => {
			setCohort(cohort);
			getCohortCriteria(cohort.cohort_id).then((res) => {
				const criteria = res.data.data;
				if (criteria) {
					let conceptIds = [];
					let eventIds = [];
					criteria.forEach((criterion) => {
						if (criterion.criteria_type == 'EventId') {
							eventIds.push(criterion.event_id);
						} else {
							conceptIds.push(criterion.concept_id);
						}
					});
					searchConceptsById(conceptIds, eventIds.join(',')).then(
						(criteriaConcepts) => {
							criteria.map((criterion) => {
								let associatedConcept;
								if (criterion.criteria_type == 'EventId') {
									associatedConcept = criteriaConcepts.filter(
										(items) => items.event_id == criterion.event_id
									)[0];
								} else {
									associatedConcept = criteriaConcepts.filter(
										(items) => items.cuid == criterion.concept_id
									)[0];
								}
								return (criterion.concepts = associatedConcept);
							});
							setCriteria(criteria);
							setIsLoading(false);
						}
					);
				} else {
					setIsLoading(false);
				}
			});
			setIsLoading(false);
		}).catch((err) => {
			console.log(err);
			setIsLoading(false);
		});
	};

	const sort = (arr) => {
		if (arr.length > 0) {
			return arr.sort(function (a, b) {
				if (a.concepts.text.toLowerCase() < b.concepts.text.toLowerCase()) return -1;
				if (a.concepts.text.toLowerCase() > b.concepts.text.toLowerCase()) return 1;
				return 0;
			});
		} else {
			return [];
		}
	};

	// const isRoleCompany = props.sidenav.userRole.Role === 'Company';
	// let loading = props.loading.loading;
	// let indexEvent, inclusionEvent, exclusionEvent;
	// if (criteria) {
	// 	indexEvent = sort(criteria.filter((item) => item.is_index_event == 'true'));
	// 	inclusionEvent = sort(criteria.filter((item) => item.is_inclusion == 'true'));
	// 	exclusionEvent = sort(
	// 		criteria.filter(
	// 			(item) => item.is_inclusion == 'false' && item.is_index_event == 'false'
	// 		)
	// 	);
	// }
	return (
		<div>
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<HeaderTitles data={headerData} search={false} cohortId={cohort.cohort_id}/>
			<div style={{ position: 'relative' }}>
				<div>
					<h3 className="v-cohort-name">Design: {cohort.cohort_name}</h3>
				</div>
				<PanelHeading heading="Inclusion and exclusion criteria" />

				<table className="v-criteria-table">
					<tbody>
						{indexEvent &&
							indexEvent.map((criterion, index) => {
								return (
									<tr key={index}>
										<td>Index event:</td>
										<td style={{ paddingLeft: 20 }}>
											{isRoleCompany ? (
												<span
													className="hyperlink"
													onClick={() =>
														gotoTechnology(
															criterion.cohort_id,
															criterion.concepts.text
														)
													}
												>
													{criterion.concepts.text}
												</span>
											) : (
												<span>{criterion.concepts.text}</span>
											)}
										</td>
									</tr>
								);
							})}
						{inclusionEvent &&
							inclusionEvent.map((criterion, index) => {
								return (
									<tr key={index}>
										<td>Inclusion criterion:</td>
										<td style={{ paddingLeft: 20 }}>
											{isRoleCompany ? (
												<span
													className="hyperlink"
													onClick={() =>
														gotoTechnology(
															criterion.cohort_id,
															criterion.concepts.text
														)
													}
												>
													{criterion.concepts.text}
												</span>
											) : (
												<span>{criterion.concepts.text}</span>
											)}
										</td>
									</tr>
								);
							})}
						{exclusionEvent &&
							exclusionEvent.map((criterion, index) => {
								return (
									<tr key={index}>
										<td>Exclusion criterion:</td>
										<td style={{ paddingLeft: 20 }}>
											{isRoleCompany ? (
												<span
													className="hyperlink"
													onClick={() =>
														gotoTechnology(
															criterion.cohort_id,
															criterion.concepts.text
														)
													}
												>
													{criterion.concepts.text}
												</span>
											) : (
												<span>{criterion.concepts.text}</span>
											)}
										</td>
									</tr>
								);
							})}
					</tbody>
				</table>

				<PanelHeading heading="Event date range" />
				<div className="v-criteria-date">
					{criteria &&
						criteria.map((criterion, index) => {
							return (
								criterion.criterion_absolute_start_date &&
								criterion.criterion_absolute_end_date && (
									<div key={index}>
										<p style={{ margin: 0 }}>{criterion.concepts.text}</p>
										<p>
											{' '}
											Date range:{' '}
											{formatDate(
												criterion.criterion_absolute_start_date
											)} to{' '}
											{formatDate(criterion.criterion_absolute_end_date)}
										</p>
									</div>
								)
							);
						})}
				</div>
			</div>
			<Copyright />
		</div>
	);
}

const mapStateToProps = (state) => {
	return{
		loading: state.loading,
		sidenav: state.sidenav,
	}
};
export default connect(mapStateToProps)(CohortDesign);
CohortDesign.propTypes = {
	match: PropTypes.shape({
		params: PropTypes.shape({
			cohort_id: PropTypes.any,
		}),
	}),
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
	sidenav: PropTypes.object,
	loading: PropTypes.object,
	userRole: PropTypes.object,
};
