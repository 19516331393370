import actionTypes from './actionTypes';
import { apiGet, apiPostForm } from '../utilities/utils';
import store from '../store';

/** **************************************
     * Function Name: getTrialList
     * Description: exports an action to get the trial list data from the backend
     * The data received gets set in the trials reducer which can be used
     * later for manipulation.
     * Method has no signature.
****************************************/

export function getStudyList(query) {
	return dispatch => new Promise((resolve, reject) => {
		apiGet('GetStudyList', query).then(res => {
			dispatch({
				type: actionTypes.GET_STUDY_DATA,
				payload: res.data.data,
			});
			resolve(res.data.data);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: removeStudy
     * Description: exports an action to remove a particular study data from the
     * backend. The response is returned via promise to ensure successful API call.
     * Method has one signature.
     * @param - trial_name - The name of the study based on which the study has
     * to be deleted from the backend.
****************************************/

export function removeStudy(study_name) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('RemoveTrial', {
			'trialObject.trialName': study_name,
		}).then(res => {
			// console.log(res);
		})
		.catch(err => {
			console.log(err);
		});
	});
}


/** **************************************
     * Function Name: getStudyDetail
     * Description: exports an action to get the study details from the backend
     * The details received gets set in the study reducer which can be used
     * later for manipulation.
     * Method has one signature.
****************************************/
export function getStudyDetail(study_id) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		}).then(res => {
			dispatch({
				type: actionTypes.GET_STUDY_DETAIL,
				payload: res.data,
			});
			resolve(res.data);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: getStudyArmList
     * Description: exports an action to get the study list data from the backend
     * The data received gets set in the study reducer which can be used
     * later for manipulation.
     * Method has one signature.
****************************************/

export function getStudyArmList(study_id) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetStudyArmList', {
			'm_StudyObj.study_id': study_id,
		}).then(res => {
			dispatch({
				type: actionTypes.GET_STUDY_ARM_LIST,
				payload: res.data.data,
			});
			resolve(res.data.data);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: setStudyData
     * Description: exports an action to get the study data from the backend
     * The data received gets set in the study reducer which can be used
     * later for manipulation.
     * Method has one signature.
****************************************/

export function setStudyData(studyDataObj) {
	return dispatch => {
		dispatch({
			type: actionTypes.SET_STUDY_DATA,
			payload: studyDataObj,
		});
	};
}


/** **************************************
     * Function Name: addStudy
     * Description: Add study data and send to the backend.
     * later for manipulation.
     * Method has five signature.
****************************************/
export function addStudy(base_cohort, study_name, m_StudyArmString, event_type, event_id) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('AddStudy', {
			'm_StudyObj.base_cohort': base_cohort,
			'm_StudyObj.study_name': study_name,
			'm_StudyArmString': m_StudyArmString,
			'm_OutcomeObj.event_type': event_type,
			'm_eventIds': event_id,
		}).then(res => {
			resolve(res);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: saveStudyCohorts
     * Description: exports an action to get the study cohorts data from the backend
     * The data received gets set in the study reducer which can be used
     * later for manipulation.
     * Method has one signature.
****************************************/
export function saveStudyCohorts(studyCohort, cohortIndex) {
	return dispatch => {
		dispatch({
			type: actionTypes.SAVE_STUDY_COHORTS,
			cohortIndex: cohortIndex,
			payload: studyCohort,
		});
	};
}

/** **************************************
     * Function Name: saveStudyCohorts
     * Description: exports an action to get the study cohorts data from the backend
     * The data received gets set in the study reducer which can be used
     * later for manipulation.
     * Method has one signature.
****************************************/
export function saveStudyValidationCriteria(study_id, criteria) {
	sessionStorage.setItem(study_id + 'studyCriteria', JSON.stringify(criteria));
}

/** **************************************
     * Function Name: getStudyValidationCriteria
     * Description: exports an action to get the study cohorts data from the backend
     * The data received gets set in the study reducer which can be used
     * later for manipulation.
     * Method has one signature.
****************************************/
export function getStudyValidationCriteria(study_id, criteria) {
	const value = sessionStorage.getItem(study_id + 'studyCriteria');
	return value ? JSON.parse(value) : '';
}


/** **************************************
     * Function Name: clearStudyCohorts
     * Description: exports an action to clear the study cohorts data from the backend
     * The data received gets set in the study reducer which can be used
     * later for manipulation.
     * Method has no signature.
****************************************/

export function clearStudyCohorts() {
	return dispatch => {
		dispatch({
			type: actionTypes.CLEAR_STUDY_COHORTS,
		});
	};
}


/** **************************************
     * Function Name: deleteStudy
     * Description: exports an action to delete the study data from the backend
     * later for manipulation.
     * Method has one signature.
****************************************/

export function deleteStudy(m_studyId) {
     return dispatch => new Promise(function(resolve, reject) {
          apiGet('DeleteStudy', {
               m_studyId: m_studyId,
          }).then(res => {
               resolve(res);
               // console.log(res)
          })
          .catch(err => {
               console.log(err);
               reject(err);
          });
     });
}


/** **************************************
     * Function Name: editStudy
     * Description: exports an action to Update the study detail data from backend
     * later for manipulation.
     * Method has six signature.
****************************************/
export function editStudy(study_id,base_cohort,study_name, m_StudyArmString, event_type, event_id) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('EditStudy', {
			'm_StudyObj.study_id': study_id,
			'm_StudyObj.base_cohort': base_cohort,
			'm_StudyObj.study_name': study_name,
			'm_StudyArmString': m_StudyArmString,
			'm_OutcomeObj.event_type': event_type,
			'm_eventIds': event_id,
		}).then(res => {
			resolve(res);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: getStudyListForPatient
     * Description: Add study data and send to the backend.
     * later for manipulation.
     * Method has one signature.
****************************************/
export function getStudyListForPatient(m_PatientIdList) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetStudyListForPatient', {
			'm_PatientIdList': m_PatientIdList,
		}).then(res => {
			dispatch({
				type: actionTypes.GET_STUDY_LIST_FOR_PATIENT,
				payload: res.data,
			});
			resolve(res.data);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: getStudyListForDocument
     * Description: Add study data and send to the backend.
     * later for manipulation.
     * Method has one signature.
****************************************/
export function getStudyListForDocument(m_DocIdList) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetStudyListForDocument', {
			'm_DocIdList': m_DocIdList,
		}).then(res => {
			dispatch({
				type: actionTypes.GET_STUDY_LIST_FOR_DOCUMENT,
				payload: res.data,
			});
			resolve(res.data.data);
		})
		.catch(err => {
			reject(err);
		});
	});
}
/** **************************************
     * Function Name: getUserCohortAnnotationCount
     * Description: get user cohort detail.
     * later for manipulation.
     * Method has one signature.
****************************************/
export function getUserCohortAnnotationCount(cohortId) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetUserCohortAnnotationCount', {
			'm_cohortId': cohortId,
		}).then(res => {
			dispatch({
				type: actionTypes.GET_STUDY_VALIDITY,
				payload: res.data.data,
			});

			resolve(res.data.data);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: getDocumentConceptAnnotationResults
     * Description: get user document based on cohort and concept.
     * Method has one signature.
****************************************/
export function getDocumentConceptAnnotationResults(cohortId, conceptId, m_studyArmType,eventId,Is_concept_value_result_type, m_conceptSeverity) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetDocumentConceptAnnotationResults', {
			'm_cohortIdLong': cohortId,
			'm_conceptId': conceptId,
			'm_studyArmType': m_studyArmType,
			'm_eventId':eventId,
			Is_concept_value_result_type:Is_concept_value_result_type,
			'm_conceptSeverity':m_conceptSeverity
		}).then(res => {
			
			dispatch({
				type: actionTypes.GET_STUDY_VALIDITY,
				payload: res.data.payload,
			});
			resolve(res.data.payload);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: searchConceptsById
     * Description: get concept detail based on concept ids provided.
     * Method has one signature.
****************************************/

export function searchConceptsById(conceptIds, eventIds) {
	return dispatch => new Promise(function(resolve, reject) {
        let _state = store.getState();
		let conceptId = [];
		conceptIds =  (typeof conceptIds === 'string') ? [conceptIds] : [...new Set(conceptIds)];
		let studyConceptIds = _state.study?.studyConcepts?.map((item) => {return item.cuid})
		
		let params ={};
		params['conceptTableEntry.umls_concept_category']= 'All';
		if(eventIds){
			params['eventIds'] = eventIds
		}

		if(_state.study.studyConcepts.length > 0){
			conceptId = conceptIds?.filter((item) => {
				return studyConceptIds.indexOf(item) == -1
			});

			if(conceptId.length > 0) {
				params['conceptIds'] = conceptId.join(',');
				
				apiGet('SearchConceptsById', params).then(res => {
					dispatch({
						type: actionTypes.GET_STUDY_CONCEPTS,
						payload: res.data.data,
					});
					resolve(_state.study.studyConcepts.concat(res?.data?.data))
				})
				.catch(err => {
					reject(err);
				});
			} else {
				resolve(_state.study.studyConcepts)
			}
		} else {
			if(conceptIds.length > 0){
				params['conceptIds'] = conceptIds.join(',');
			}
			apiGet('SearchConceptsById', params).then(res => {
				dispatch({
					type: actionTypes.GET_STUDY_CONCEPTS,
					payload: res.data.data,
				});
				resolve(_state.study.studyConcepts.concat(res?.data?.data))
			})
			.catch(err => {
				reject(err);
			});
		}
	});
}

/** **************************************
     * Function Name: getPatientAndDocumentDetails
     * Description: get detail of document based on document_concept_id or annotation id.
     * Method has one signature.
****************************************/
export function getPatientAndDocumentDetails(list, doc_type) {
	return dispatch => new Promise(function(resolve, reject) {
		apiPostForm('GetPatientAndDocumentDetails', {
			'm_DocConceptIdList': list.documentIdList.join(','),
			'm_ManualAnnIdList': list.annotationIdList.join(','),
			'm_studyArmType': doc_type,
		}).then(res => {
			resolve(res.data.data);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/** **************************************
     * Function Name: updateStudyValidation
     * Description: get detail of document based on document_concept_id or annotation id.
     * Method has one signature.
****************************************/
export function updateStudyValidation(updatedStudyValidationValues) {
	return dispatch => {
		dispatch({
			type: actionTypes.UPDATE_STUDY_VALIDITY,
			payload: updatedStudyValidationValues,
		});
	};
}

/** **************************************
     * Function Name: GetCohortDetailsForStudyValidity
     * Description: get kappa score for cohort.
     * Method has one signature.
****************************************/

export function GetCohortDetailsForStudyValidity(cohortId) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetCohortDetailsForStudyValidity', {
			'm_CohortId': cohortId,
		}).then(res => {
			
			resolve(res.data.data);
		})
		.catch(err => {
			reject(err);
		});
	});
}


/** **************************************
     * Function Name: GetCohortKappaScores
     * Description: get kappa score for cohort.
     * Method has one signature.
****************************************/

export function GetCohortKappaScores(cohortId) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetCohortKappaScores', {
			'm_CohortId': cohortId,
		}).then(res => {
			
			resolve(res.data.payload);
		})
		.catch(err => {
			reject(err);
		});
	});
}


/** **************************************
     * Function Name: GetAnnotatorKappaScores
     * Description: get kappa score for cohort.
     * Method has one signature.
****************************************/

export function GetAnnotatorKappaScores(conceptId,cohortId) {
	return dispatch => new Promise(function(resolve, reject) {
		apiGet('GetAnnotatorKappaScores', {
			'm_conceptId': conceptId,
			'm_CohortId': cohortId
		}).then(res => {
			
			resolve(res.data.payload);
		})
		.catch(err => {
			reject(err);
		});
	});
}
