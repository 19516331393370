import { apiGet } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import store from '../../../store';

export const getEncounterTwoClassConceptDetails = (
	m_conceptId,
	m_studyArm,
	m_descriptor,
	m_severity,
	m_progression,
	is_event = 'false',
	pageNo,
	recordsPerPage
) => {
	return new Promise((resolve, reject) => {
		apiGet('GetEncounterTwoClassConceptDetails', {
			m_conceptId: m_conceptId,
			m_studyArm: m_studyArm,
			m_descriptor: m_descriptor,
			m_result: '',
			m_severity: m_severity,
			m_progression: m_progression,
			is_event: is_event,
			pageNumber: pageNo,
			pageSize: recordsPerPage,
		})
			.then((res) => {
				resolve(res?.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getStudyDetail = (study_id) => {
	return new Promise((resolve, reject) => {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const setIsLoading = (loading) => {
	store.dispatch({
		type: actionTypes.IS_LOADING,
		payload: loading,
	});
};

export const updateStudyValidation = (updatedStudyValidationValues) => {
	store.dispatch({
		type: actionTypes.UPDATE_STUDY_VALIDITY,
		payload: updatedStudyValidationValues,
	});
};

export const setSideNavList = (list) => {
	store.dispatch({
		type: actionTypes.SET_SIDE_NAVLIST,
		payload: list,
	});
};
export const setHistory = (list) => {
	store.dispatch({
		type: actionTypes.SET_HISTORY,
		payload: list,
	});
};
