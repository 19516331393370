import { apiGet, apiPostForm } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';
import queryString from 'query-string';

export function getPatientAlgorithmicProblemList(sectionData) {
	return new Promise(function (resolve, reject) {
		apiPostForm('GetPatientAlgorithmicProblemList',  sectionData)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getPatientList(
	patient_ids,
	index,
	record,
	cohort_id,
	concept_id,
	isflagForPatientWithConcept,
	searchText,
	study_id,
	searchByPatient
) {
	return new Promise(function (resolve, reject) {
		let forKappaScore = queryString.parse(window.location.search).forKappaScore;
		let kappa = forKappaScore ? '&forKappaScore=true' : '';
		let cohort = cohort_id ? '&m_cohortId=' + cohort_id : '';
		let study = study_id ? '&m_studyId=' + study_id : '';
		let concept = concept_id ? '&m_conceptId=' + concept_id : '';
		let stext = searchText ? '&m_searchString=' + searchText : '';
		let m_searchByPatient =
			searchByPatient != undefined && searchByPatient.toString()
				? '&m_searchByPatient=' + searchByPatient
				: '';
		let flagForPatientWithConcept =
			isflagForPatientWithConcept != undefined
				? '&flagForPatientWithConcept=' + isflagForPatientWithConcept
				: '';
		let url =
			index != null && index != undefined
				? 'GetPatient?pageNumber=' +
				  index +
				  '&pageSize=' +
				  record +
				  cohort +
				  study +
				  concept +
				  flagForPatientWithConcept +
				  stext +
				  kappa +
				  m_searchByPatient
				: 'GetPatient?m_start';
		apiGet(url, patient_ids ? { m_PatientIdList: patient_ids } : {})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_PATIENT_DATA,
					payload: res.data,
				});
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
}
export function getDocuments(patient_id, organization_id, cohort_id, searchString) {
	return new Promise(function (resolve, reject) {
		apiGet('GetDocuments', {
			patient_id: patient_id,
			organization_id: organization_id,
			m_cohortId: cohort_id,
			m_searchString: searchString != undefined ? searchString.toString() : '',
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_DOCUMENTS,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getUserEntityCounts(query = {}) {
	return new Promise(function (resolve, reject) {
		let forKappaScore = queryString.parse(window.location.search).forKappaScore;
		if (forKappaScore) {
			query.forKappaScore = true;
		}
		apiGet('GetUserEntityCounts', query)
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getCohortListForPatient(patient_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortListForPatient', {
			m_PatientIdList: patient_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.SET_PATIENT_COHORTS,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getDiagnosesInfo(patient_id) {
	return new Promise(function (resolve, reject) {
		apiGet('getDiagnosesInfo', {
			patient_id: patient_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.SET_DIAGNOSES,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.SET_DIAGNOSES,
					payload: [],
				});
				reject(err);
				console.log(err);
			});
	});
}

export function getPatient_Encounters(patient_id, organization_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetEncounter', {
			patient_id: patient_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function getPatient_Episodes(episodeIDs) {
	return new Promise(function (resolve, reject) {
		apiGet('GetEpisode', {
			episodeID: episodeIDs,
		})
			.then((res) => {
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function getAlgorithmicProblemList(patientId) {
	return new Promise(function (resolve, reject) {
		apiGet('GetAlgorithmicProblemList', {
			patientId: patientId,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getExcludedAlgorithmicProblemList(patientId) {
	return new Promise(function (resolve, reject) {
		apiGet('GetExcludedAlgorithmicProblemList', {
			patientId: patientId,
		})
			.then((res) => {
				resolve(res.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function setDocName(doc_name) {
	store.dispatch({
		type: actionTypes.SET_DOC_NAME,
		payload: doc_name,
	});
}

export function setHistoryAction(list) {
	store.dispatch({
		type: actionTypes.SET_HISTORY,
		payload: list,
	});
}

export function emptyPatientDeatils() {
	store.dispatch({
		type: actionTypes.EMPTY_PATIENT_DETAILS,
		payload: '',
	});
}
