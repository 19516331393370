import React, { useState, useEffect } from 'react';
import constants from '../../constants';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import {
	getStudyList,
	getCohortCount,
	getPatientCount,
	getDataCount,
	gotoPage,
	searchConceptsById,
	getCohortList,
	getEvent,
	deleteStudy,
} from './StudyHelper';
import CountLinkLoader from '../../components/CountLinkLoader/CountLinkLoader';
import { filterList } from '../../utilities/utils';
import { setHistory, setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import { getQueryParamObject } from '../CohortList/CohortHelper';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { roleAccess } from '../../utilities/AppConstants';
import Loading from 'react-loading-spinner';
import actions from '../../actions';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import ErrorBoundary from '../../components/ErrorBoundary';
import SortComponent from '../../common/SortComponent/SortComponent';
import Copyright from '../../components/Copyright/Copyright';
import { setIsLoading } from '../../actions/common';
import PropTypes from 'prop-types';
import trialIcon from '../../images/trial_icon.png';
import removeMinusIcon from '../../images/remove_minus_icon.svg';
import addPlusIcon from '../../images/add_plus_icon.svg'

const headerData = {
	title: 'Study',
	searchTitle: 'studies',
	list: 'list',
	img: (
		<img
			src={trialIcon}
			className={'App-TrialList'}
			alt={'Trial List'}
			style={{ width: '30px', marginRight: '10px' }}
		/>
	),
};

const columns = [{ dataKey: 'study_name' }];

function StudyList(props) {
	const [studies, setStudies] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [showLoading, setShowLoading] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [open, setOpen] = useState(false);
	const [selectedStudy, setSelectedStudy] = useState(-1);
	const [filterApplied, setFilterApplied] = useState(undefined);
	const [sortOrder, setSortOrder] = useState('');
	const [columnName, setColumnName] = useState(props.columnName ? props.columnName : '');
	const [className, setClassName] = useState('');
	let filteredStudies = filterList(searchText, studies, [{dataKey: 'study_name'}]);

	let { userRole } = props.sidenav;
	let { loading } = props.loading;
	let { study } = props.study;

	useEffect(() => {
		getFilter();
	}, []);

	useEffect(() => {
		if (props.sidenav.filter !== filterApplied) {
			setFilterApplied(props.sidenav.filter);
			getStudyList2(queryString.parse(props?.location?.search));
		}
	}, [props.sidenav?.filter]);

	useEffect(() => {
		if (
			columnName == 'study_count' ||
			columnName == 'cohort_count' ||
			columnName == 'patient_count' ||
			columnName == 'data_count'
		) {
			sortOptionCount(studies);
		} else if (sortOrder && studies.length) {
			study = sortOption(studies);
		}
	}, [studies]);

	const getFilter = () => {
		setIsLoading(true);
		let cohort_id = queryString.parse(window.location.search).cohort_id;
		let concept_id = queryString.parse(window.location.search).concept_id;
		let event_id = queryString.parse(window.location.search).event_id;
		let study_id = queryString.parse(window.location.search).study_id;

		if (cohort_id) {
			setIsLoading(true);

			let cohortName = '';
			getCohortList().then((res) => {
				if (res.data.data) {
					let temp = res?.data?.data.filter((item) => item.cohort_id == cohort_id)[0];
					cohortName = temp?.cohort_name;
					if (concept_id) {
						searchConceptsById(concept_id).then((resp) => {
							let options = [
								{
									filterLabel: 'Cohort',
									name: cohortName,
								},
								{
									filterLabel: 'Concept',
									name: resp[0].text,
								},
							];
							setIsLoading(false);
							setFilter(options);
						});
					} else if (event_id && event_id != 0) {
						getEvent().then((res) => {
							let events = res;
							let event = events.filter((item) => item.event_id == event_id);

							let eventName = event.length > 0 ? event[0].name : '';
							let options = [
								{
									filterLabel: 'Cohort',
									name: cohortName,
								},
								{
									filterLabel: 'Event',
									name: eventName,
								},
							];
							setIsLoading(false);

							setFilter(options);
						});
					} else {
						let options = [
							{
								filterLabel: 'Cohort',
								name: cohortName,
							},
						];
						setIsLoading(false);

						setFilter(options);
					}
				}
			});
		}
		sortASC('study_name', 'asc');
	};

	const onEditMode = () => {
		setEditMode(true);
	};

	const exitEditMode = () => {
		setEditMode(false);
	};
	const addStudy = () => {
		props.history.push(constants.ROUTE_URL + '/dashboard/addstudy');
	};
	/*****************************************
	 * Description: This function makes a call to the backend to remove
	 * a particular study data on click of minus button
	 * edit study page.
	 ****************************************/
	const removeStudy = (study_id) => {
		setOpen(true);
		setSelectedStudy(study_id);
	};
	// Description: This function alters the state of the modal.
	const closeModal = () => {
		setOpen(false);
		setClassName('testClass');
	};
	//  Description: This function alters the state of the modal.
	const handleClose = () => {
		setOpen(false);
		setTimeout(() => setClassName('testClass'), 1000);
	};

	// * Description: This function call the calback method handleDeleteManualAnnotation
	const deleteStudy1 = () => {
		setOpen(false);
		setClassName('testClass');
		setIsLoading(true);

		deleteStudy(selectedStudy).then((res) => {
			setIsLoading(false);

			getStudyList2();
		});
	};

	const setFilter = (options) => {
		let filterLabel = {
			page: 'patient',
			options: options,
		};
		setFilterLabel(filterLabel);
	};

	const getStudyList2 = (query) => {
		const queryParams = getQueryParamObject(query);
		setIsLoading(true);

		getStudyList(queryParams).then((res) => {
			setStudies(res);
			setIsLoading(false);
			const studyIds = res.map((study) => study.study_id).join();
			// GET COHORT COUNT

			getCohortCount([...res], studyIds).then((studiesRes) => {
				setStudies(studiesRes);
			});

			// GET PATIENT COUNT
			getPatientCount([...res], studyIds).then((studiesRes) => {
				setStudies(studiesRes);
			});

			// GET DATA COUNT
			getDataCount([...res], studyIds).then((studiesRes) => {
				setStudies(studiesRes);
			});
		});
	};
	const sortASC = (columnName, order) => {
		if (!order) {
			if (!sortOrder || sortOrder == 'dsc') {
				setSortOrder('asc');
				setColumnName(columnName);
			} else {
				setSortOrder('dsc');
				setColumnName(columnName);
			}
		} else {
			setSortOrder(order);
			setColumnName(columnName);
		}
	};

	const sortOption = (arr) => {
		// let columnName = columnName;

		if (sortOrder == 'asc') {
			try {
				arr.sort(function (a, b) {
					if (columnName?.indexOf('_count') && !a[columnName]) {
						a[columnName] = a.counts[columnName];
					}
					if (columnName?.indexOf('_count') && !b[columnName]) {
						b[columnName] = b.counts[columnName];
					}

					if (
						a[columnName]?.toString().toLowerCase() <
						b[columnName]?.toString().toLowerCase()
					) {
						return -1;
					}
					if (
						a[columnName]?.toString().toLowerCase() >
						b[columnName]?.toString().toLowerCase()
					) {
						return 1;
					}
					return 0;
				});
			} catch (err) {
				console.log(err);
			}
		} else {
			try {
				arr.sort(function (a, b) {
					if (columnName?.indexOf('_count') && !a[columnName]) {
						a[columnName] = a.counts[columnName];
					}
					if (columnName?.indexOf('_count') && !b[columnName]) {
						b[columnName] = b.counts[columnName];
					}

					if (
						a[columnName]?.toString().toLowerCase() <
						b[columnName]?.toString().toLowerCase()
					) {
						return 1;
					}
					if (
						a[columnName]?.toString().toLowerCase() >
						b[columnName]?.toString().toLowerCase()
					) {
						return -1;
					}
					return 0;
				});
			} catch (err) {
				console.log(err);
			}
		}
		return arr;
	};

	const sortOptionCount = (arr) => {
		// let columnName = columnName;

		if (sortOrder == 'asc') {
			try {
				arr.sort(function (a, b) {
					if (columnName?.indexOf('_count') && !a[columnName]) {
						a[columnName] = a.counts[columnName];
					}
					if (columnName?.indexOf('_count') && !b[columnName]) {
						b[columnName] = b.counts[columnName];
					}

					if (a[columnName] < b[columnName]) {
						delete a[columnName];
						delete b[columnName];
						return -1;
					}
					if (a[columnName] > b[columnName]) {
						delete a[columnName];
						delete b[columnName];
						return 1;
					}
					delete a[columnName];
					delete b[columnName];
					return 0;
				});
			} catch (err) {
				console.log(err);
			}
		} else {
			try {
				arr.sort(function (a, b) {
					if (columnName?.indexOf('_count') && !a[columnName]) {
						a[columnName] = a.counts[columnName];
					}
					if (columnName?.indexOf('_count') && !b[columnName]) {
						b[columnName] = b.counts[columnName];
					}

					if (a[columnName] < b[columnName]) {
						delete a[columnName];
						delete b[columnName];
						return 1;
					}
					if (a[columnName] > b[columnName]) {
						delete a[columnName];
						delete b[columnName];
						return -1;
					}
					delete a[columnName];
					delete b[columnName];
					return 0;
				});
			} catch (err) {
				console.log(err);
			}
		}
		return arr;
	};

	const gotoStudyDesign = (studyId) => {
		setHistory();
		props.history.push(constants.ROUTE_URL + '/dashboard/studydesign/' + studyId);
	};
	const searchStudy = (event) => {
		setSearchText(event.target.value);
	};

	return (
		<div>
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}

			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					editMode={onEditMode}
					search
					searchFunction={searchStudy}
					searchText={searchText}
				/>
			</ErrorBoundary>
			<GridHeader columnName={columnName} sortOrder={sortOrder} sortASC={sortASC} />
			<div className="bg-dark-first" style={{ minHeight: '70vh', position: 'relative' }}>
				{filteredStudies.length > 0 &&
					filteredStudies.map((study, index) => (
						<div key={index} className="v-title-wrapper">
							<div className="v-col-link">
								{editMode && (
									<div className="minusIcon-container">
										<img
											src={removeMinusIcon}
											onClick={() => removeStudy(study.study_id)}
											className="App-minusIcon"
											alt="minusIcon"
										/>
									</div>
								)}
								<span
									className="hyperlink"
									onClick={() => gotoStudyDesign(study.study_id)}
								>
									{study.study_name}
								</span>
							</div>
							<div className="v-col-count">
								<ErrorBoundary>
									<CountLinkLoader
										count={study?.counts?.cohort_count}
										handleClick={() =>
											gotoPage('cohort', study.study_id, study.study_name)
										}
									/>
								</ErrorBoundary>
							</div>
							<div className="v-col-count">
								{userRole && userRole.Role == roleAccess.ANNOTATOR ? (
									<ErrorBoundary>
										<CountLinkLoader
											count={study?.counts?.patient_count}
											handleClick={() =>
												gotoPage('data', study.study_id, study.study_name)
											}
										/>
									</ErrorBoundary>
								) : (
									<ErrorBoundary>
										<CountLinkLoader
											count={study?.counts?.patient_count}
											handleClick={() =>
												gotoPage(
													'patient',
													study.study_id,
													study.study_name
												)
											}
										/>
									</ErrorBoundary>
								)}
							</div>
							<div className="v-col-count">
								<ErrorBoundary>
									<CountLinkLoader
										count={study?.counts?.data_count}
										handleClick={() =>
											gotoPage('data', study.study_id, study.study_name)
										}
									/>
								</ErrorBoundary>
							</div>
						</div>
					))}
				{editMode && (
					<div className="v-title-wrapper">
						<div className="add-row" style={{ paddingLeft: '16px' }}>
							<img
								src={addPlusIcon}
								onClick={addStudy}
								className="App-plusicon hyperlink"
								alt="plusicon"
							/>
						</div>
					</div>
				)}
				{showLoading && <div className="v-loading-spinner" />}
			</div>
			{editMode && (
				<div className="text-right trialButton addNewTrial exitEditPage">
					<button type="button" className="trialArm" onClick={() => exitEditMode()}>
						Close
					</button>
				</div>
			)}
			<ErrorBoundary>
				<ModalComponent
					open={open}
					content={
						<div className="jconfirm-box-container">
							<div className="jconfirm-content-pane">
								<h4>Are you sure you want to delete?</h4>
							</div>
							<div className="jconfirm-content-pane">
								<div className="jconfirm-buttons">
									<button
										className="btn btn-default"
										onClick={() => closeModal()}
									>
										Cancel
									</button>
									<button
										className="btn btn-default"
										onClick={() => deleteStudy1()}
									>
										Ok
									</button>
								</div>
							</div>
						</div>
					}
					className="testClass"
					handleClose={handleClose}
				/>
			</ErrorBoundary>
			<Copyright />
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		loading: state.loading,
		sidenav: state.sidenav,
		filter: state.sidenav.filter,
		userRole: state.sidenav.userRole,
		study: state.study,
		location: state.location,
	};
};

export default connect(mapStateToProps)(StudyList);

StudyList.propTypes = {
	history: PropTypes.object,
	loading: PropTypes.object,
	sidenav: PropTypes.object,
	filter: PropTypes.any,
	location: PropTypes.object,
	userRole: PropTypes.object,
};
const GridHeader = ({ columnName, sortOrder, sortASC }) => {
	return (
		<div className="v-grid-header">
			<div className="v-col-link" onClick={() => sortASC('study_name')}>
				<span>Study name</span>
				<SortComponent
					columnName={'study_name'}
					sortedColumnName={columnName}
					sortOrder={sortOrder}
					sortASC={sortASC}
				/>
			</div>
			<div className="v-col-count" onClick={() => sortASC('cohort_count')}>
				<span>Cohorts</span>
				<SortComponent
					columnName={'cohort_count'}
					sortedColumnName={columnName}
					sortOrder={sortOrder}
					sortASC={sortASC}
				/>
			</div>
			<div className="v-col-count" onClick={() => sortASC('patient_count')}>
				<span>Patients</span>
				<SortComponent
					columnName={'patient_count'}
					sortedColumnName={columnName}
					sortOrder={sortOrder}
					sortASC={sortASC}
				/>
			</div>
			<div className="v-col-count" onClick={() => sortASC('data_count')}>
				<span>Data</span>
				<SortComponent
					columnName={'data_count'}
					sortedColumnName={columnName}
					sortOrder={sortOrder}
					sortASC={sortASC}
				/>
			</div>
		</div>
	);
};
