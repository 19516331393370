import store from '../../../store';
import actions from '../../../actions';

export const getStudyDetail = (study_id) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getStudyDetail(study_id))
			.then((studyDetail) => {
				resolve(studyDetail);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const searchConceptsById = (concept_id) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(concept_id))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getSubdocumentById = (docId) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getSubdocumentById(docId))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};

export const getManualAnnotationsWithConflicts = (
	study_id,
	concept_id,
	userIdList,
	initial,
	pageNumber,
	recordsPerPage,
	forKappaScore
) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(
				actions.getManualAnnotationsWithConflicts(
					study_id,
					concept_id,
					userIdList,
					initial,
					pageNumber,
					recordsPerPage,
					forKappaScore
				)
			)
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};



export const getUserDetails = (userIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.getUserDetails(userIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};


export const resolveManualAnnotationConflicts = (resolveAnnotationsList) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.resolveManualAnnotationConflicts(resolveAnnotationsList))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
