import actionTypes from './actionTypes';
import { apiGet } from '../utilities/utils';

/**************************************** 
     * Function Name: getNarrativeRecallPrecision
     * Description: exports an action to get the Narrative Recall and Precision data from the backend.
     * The data received is returned via promise which can be used
     * for manipulation. 
     * @param - m_conceptId - used to make the api call based on the m_conceptId.
****************************************/
export function getNarrativeRecallPrecision(m_conceptId) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('GetRecallPrecisionFmeasureForCohort', {
			'm_conceptId' : m_conceptId
		}).then(res => {
			dispatch({
				type: actionTypes.GET_SUMMARY_ANALYTICS,
				payload: res.data.data	
				
			})
			resolve(res.data.data);
		})
		.catch(err => {
			dispatch({
				type: actionTypes.IS_LOADING,
				payload: false
			})
			console.log(err);
			reject(err);
		});
	});
}

/**************************************** 
     * Function Name: getDiscreteRecallPrecision
     * Description: to get the Discrete Recall, Precision and P value
     * @param - m_conceptId - used to make the api call based on the concept id.
     * @param - m_cohortId - used to make the api call based on the cohort id.
****************************************/
export function getDiscreteRecallPrecision(m_conceptId) {
	return () => new Promise(function(resolve, reject){

		apiGet('GetRecallPrecisionFmeasureForDiscrete', {

			'm_conceptId' : m_conceptId

		}).then(res => {
			
			resolve(res.data.data);
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
	});
}

/**************************************** 
     * Function Name: getNarrativeAnnotationData
     * Description: exports an action to get the annotation data for narrative
     * @param - m_conceptId - used to make the api call based on the concept id.
****************************************/

export function getNarrativeAnnotationData(m_conceptId) {
	return () => new Promise(function(resolve, reject){

		apiGet('GetPresenceOfAnnotationForConcept', {

			'm_conceptId' : m_conceptId

		}).then(res => {
			
			resolve(res.data.data)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
	});
}
/**************************************** 
     * Function Name: GetConceptOccurences
     * Description: exports an action to get the annotation data for narrative
     * @param - m_conceptId - used to make the api call based on the concept id.
****************************************/

export function GetConceptOccurences(m_conceptId) {
	return () => new Promise(function(resolve, reject){

		apiGet('GetConceptsOccurences', {

			'm_conceptId' : m_conceptId

		}).then(res => {
			
			resolve(res.data)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
	});
}
/**************************************** 
     * Function Name: getDiscreteAnnotationData
     * Description: exports an action to get the annotation data for discrete
     * @param - m_conceptId - used to make the api call based on the concept id.
****************************************/

export function getDiscreteAnnotationData(m_conceptId) {
	return () => new Promise(function(resolve, reject){

		apiGet('GetPresenceOfAnnotationForDiscrete', {

			'm_conceptId' : m_conceptId

		}).then(res => {
			
			resolve(res.data.data)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
	});
}

/**************************************** 
     * Function Name: getPatientsWithConcept
     * Description: to get patients with concept
     * from the backend.
     * The data received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - m_conceptId - used to make the api call based on the concept id.
****************************************/
export function getPatientsWithConcept(m_conceptId) {
	return dispatch => new Promise(function(resolve, reject){

		apiGet('GetConceptPatientsForCohortSummary', {

			'm_conceptId' : m_conceptId

		}).then(res => {
			dispatch({
				type: actionTypes.GET_PATIENT_DATA,
				payload: res.data
			})
			
			resolve(res.data.data)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
	});
}

/**************************************** 
     * Function Name: getTotalPatients
     * Description: to get the total patients
     * from the backend.
     * The data received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - m_conceptId - used to make the api call based on the concept id.
****************************************/
export function getTotalPatients(m_conceptId) {
	return dispatch => new Promise(function(resolve, reject){

		apiGet('GetPatientsWithConceptForCohort', {

			'm_conceptId' : m_conceptId

		}).then(res => {
			dispatch({
				type: actionTypes.GET_PATIENT_DATA,
				payload: res.data
			})
			resolve(res.data.data)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
	});
}

/**************************************** 
     * Function Name: getConceptListForCohort
     * Description: to get the concept list for the cohort passed
     * @param - m_cohortId - cohort id of the cohort
****************************************/
export function getConceptListForCohort(m_cohortId) {
	return () => new Promise(function(resolve, reject){

		apiGet('GetConceptsNameForCohort', {

			'm_cohortId' : m_cohortId

		}).then(res => {
			// console.log("GetConceptsNameForCohort: ",res.data.data);
			resolve(res.data.data)
		})
		.catch(err => {
			console.log(err);
			reject(err)
		});
	});
}
