import actionTypes from './actionTypes';
import { apiGet } from '../utilities/utils';

/**************************************** 
     * Function Name: getUserDetails
     * Description: exports an action to get the user
     * data from the backend
     * The data received gets set in the userList reducer which can be used
     * later for manipulation. 
     * Method has no signature.
****************************************/

export function getUserDetails(userIdList) {
	return (dispatch) =>
		new Promise(function(resolve, reject) {
			let params = {};
			if (userIdList) {
				params.userIdList = userIdList;
			}

			apiGet('FetchUserDetails', params)
				.then((res) => {
					resolve(res);
					dispatch({
						type: actionTypes.GET_USER_DETAILS,
						payload: res.data.data
					});
				})
				.catch((err) => {
					reject(err);
					console.log(err);
				});
		});
}

/**************************************** 
     * Function Name: getProviderDetails
     * Description:exports an action to get the user
     * data from the backend
     * Method has one signature.
****************************************/
export function getProviderDetails(provider_id) {
	return (dispatch) =>
		new Promise(function(resolve, reject) {
			apiGet('FetchProviderDetails', {
				provider_id: provider_id
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/**************************************** 
     * Function Name: getProviderAdress
     * Description:exports an action to get the user
     * data from the backend
     * Method has one signature.
****************************************/
export function getProviderAdress(provider_id) {
	return (dispatch) =>
		new Promise(function(resolve, reject) {
			apiGet('FetchProviderAddress', {
				provider_id: provider_id
			})
				.then((res) => {
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/**************************************** 
     * Function Name: setProviderDetails
     * Description:exports an action to get the user access
     * data from the backend
     * The data received gets set in the providerDetails reducer which can be used
     * later for manipulation. 
     * Method has one signature.
****************************************/

export function setProviderDetails(providerDetails) {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_PROVIDER_DETAILS,
			payload: providerDetails
		});
	};
}

/**************************************** 
     * Function Name: setUserDetails
     * Description:exports an action to get the user list
     * data from the backend
     * The data received gets set in the userDetails reducer which can be used
     * later for manipulation. 
     * Method has one signature.
****************************************/

export function setUserDetails(userDetails) {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SET_USER_LIST,
			payload: userDetails
		});
	};
}

/**************************************** 
     * Function Name: UpdatePermissionDetails
     * Description: exports an action to sending user updated 
     * data to backend  
     * Method has one signature.
     
****************************************/
export function UpdatePermissionDetails(userAccessString) {
	return (dispatch) => {
		apiGet('UpdatePermissionDetails', {
			userCriterionString: userAccessString
		})
			.then((res) => {
				//console.log(res)
			})
			.catch((err) => {
				console.log(err);
			});
	};
}

/**************************************** 
     * Function Name: getUserPermissions
     * Description:exports an action to get the role of user
     * data from the backend
     * The data received gets set in the userPermissionList reducer which can be used
     * later for manipulation. 
     * Method has one signature.
****************************************/
export function getUserPermissions(user_id) {
	return (dispatch) =>
		new Promise(function(resolve, reject) {
			apiGet('FetchUserPermission', {})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_USER_PERMISSION,
						payload: res.data.data
					});
					resolve(res);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}

/****************************************
     * Function Name: getSystemUserDetails
     * Description: exports an action to get the user
     * data from the backend
     * The data received gets set in the userList reducer which can be used
     * later for manipulation.
     * Method has no signature.
****************************************/

export function getSystemUserDetails(user_id) {
	return (dispatch) =>
		new Promise(function(resolve, reject) {
			apiGet('FetchSystemUserDetails', {
				m_UserId: user_id
			})
				.then((res) => {
					dispatch({
						type: actionTypes.GET_SYSTEM_USER_DETAILS,
						payload: res.data
					});
					resolve(res);
				})
				.catch((err) => {
					reject(err);
					console.log(err);
				});
		});
}
/**************************************
 * Function Name: FetchUserList
 * Description: Get user list with access/role.
 **************************************/
export function FetchUserList() {
	return (dispatch) =>
		new Promise((resolve, reject) => {
			apiGet('FetchUserList').then((res) => {
				resolve(res);
			});
		}).catch((err) => {
			reject(err);
			console.log(err);
		});
}
