import { apiGet } from '../../utilities/utils';
import actionTypes from '../../actions/actionTypes';
import store from '../../store';
import actions from '../../actions';

export function getCohortList(params) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortList', params)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
}

export function getCohortCriteria(cohort_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortCriterion', {
			'cohortObj.cohort_id': cohort_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export function searchConceptsById(conceptIds, eventIds) {
// 	return new Promise(function (resolve, reject) {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// }

export function setCriterion_Concepts(cohortData) {
	store.dispatch({
		type: actionTypes.SET_CRITERION_CONCEPTS,
		payload: cohortData,
	});
}

export function setCriteriaData(criteriaData) {
	store.dispatch({
		type: actionTypes.SET_CRITERIA_DATA,
		payload: criteriaData,
	});
}

export function deleteCohortApi(cohort_id) {
	return new Promise(function (resolve, reject) {
		apiGet('DeleteCohort', {
			'cohortObj.cohort_id': cohort_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
export function confirmCohortDelete(status) {
	store.dispatch({
		type: actionTypes.TOGGLE_CONFIRM_DELETE,
		payload: status,
	});
}

export function getCohortOutcomes(cohort_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortOutcomes', { 'cohortObj.cohort_id': cohort_id })
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}