import React, { useEffect, lazy, Suspense } from 'react';
import store from '../../store';
import constants from '../../constants';
import { connect } from 'react-redux';
import { setFilterLabel } from '../../common/FilterFunctions/FilterFunctions';
import queryString from 'query-string';
import actions from '../../actions';
import Loading from 'react-loading-spinner';
import ErrorBoundary from '../../components/ErrorBoundary';
import {
	setTotalPatients,
	getStudyDetail,
	searchConceptsById,
	getEvent,
	getCohortList,
} from './PatientListHelper';
import { setIsLoading } from '../../actions/common';
import PropTypes from 'prop-types';

const Patients = lazy(() =>
	import(/* webpackChunkName: "Patients" */ '../../components/Patients/Patients')
);
import patientIcon from '../../images/patient_icon.png';

const headerData = {
	title: 'Patient',
	searchTitle: 'patients',
	list: 'list',
	img: (
		<img
			src={patientIcon}
			style={{ marginRight: '15px', width: '12px' }}
			className={'App-PatientListImage'}
			alt={'Patients List'}
		/>
	),
	lastColumn: 'Data',
};

function PatientList(props) {
	let { userRole } = props.sidenav;
	let { loading } = props.loading;

	useEffect(() => {
		setTotalPatients(0);
		let cohort_id = queryString.parse(window.location.search).cohort_id;
		let concept_id = queryString.parse(window.location.search).concept_id;
		let event_id = queryString.parse(window.location.search).event_id;
		let study_id = queryString.parse(window.location.search).study_id;
		let forResult = queryString.parse(window.location.search).forResult;

		if (study_id) {
			setIsLoading(true);
			let studyName = '';
			getStudyDetail(study_id).then((studyDetail) => {
				if (studyDetail) {
					studyName = studyDetail.study_name;
					if (concept_id) {
						searchConceptsById(concept_id).then((resp) => {
							let options = [
								{
									filterLabel: 'Study',
									name: studyName,
								},
								{
									filterLabel: forResult == 'true' ? 'Concept result' : 'Concept',
									name: resp[0].text,
								},
							];
							setIsLoading(false);
							setFilter(options);
						});
					} else if (event_id && event_id != 0) {
						getEvent().then((res) => {
							let events = res;
							let event = events.filter((item) => item.event_id == event_id);

							let eventName = event.length > 0 ? event[0].name : '';
							let options = [
								{
									filterLabel: 'Study',
									name: studyName,
								},
								{
									filterLabel: 'Event',
									name: eventName,
								},
							];
							setIsLoading(false);
							setFilter(options);
						});
					} else {
						let options = [
							{
								filterLabel: 'Study',
								name: studyName,
							},
						];
						setIsLoading(false);
						setFilter(options);
					}
				}
			});
		}
		if (concept_id && !study_id) {
			setIsLoading(true);
			searchConceptsById(concept_id).then((resp) => {
				setIsLoading(true);
				let options = [
					{
						filterLabel: forResult == 'true' ? 'Concept result' : 'Concept',
						name: resp[0].text,
					},
				];
				setFilter(options);
			});
		}

		if (cohort_id) {
			setIsLoading(true);
			getCohortList().then((res) => {
				setIsLoading(false);
				if (res.data.data) {
					let temp = res.data.data.filter((item) => item.cohort_id == cohort_id)[0];

					let options = [
						{
							filterLabel: 'Cohort',
							name: temp.cohort_name,
						},
					];
					setFilter(options);
				}
			});
		}
	}, []);

	const setFilter = (options) => {
		let filterLabel = {
			page: 'patient',
			options: options,
		};
		setFilterLabel(filterLabel);
	};

	return (
		<React.Fragment>
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<Patients
						filter={props.sidenav.filter}
						headerData={headerData}
						isDataPage={0}
						userRole={userRole}
						{...props}
					/>
				</Suspense>
			</ErrorBoundary>
		</React.Fragment>
	);
}

const mapStateToProps = (state) => {
	return {
		sidenav: state.sidenav,
		loading: state.loading,
	};
};
export default connect(mapStateToProps)(PatientList);

PatientList.propTypes = {
	loading: PropTypes.object,
	sidenav: PropTypes.object,
};
