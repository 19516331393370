import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import constants from '../../../constants';
import { setObjectToLocal } from '../../../utilities/utils';
import auth0 from 'auth0-js';
import { login } from './Auth0LoginHelper';
import logo from '../../../images/logo.svg';

const propTypes = {
	auth: PropTypes.shape({
		error: PropTypes.any,
		success: PropTypes.any,
	}),
	dispatch: PropTypes.func,
	history: PropTypes.shape({
		push: PropTypes.func,
	}),
};

const Auth0Login = (props) => {
	const [err, setErr] = useState('');
	const [disableForm, setDisableForm] = useState(false);
	const [userPlaceholder, setUserPlaceholder] = useState('Username');
	const [passPlaceholder, setPassPlaceholder] = useState('Password');
	const [user, setUser] = useState({
		email: '',
		password: '',
	});

	const focusPlaceholder = (e) => {
		setUserPlaceholder('');
	};

	const blurPlaceholder = (e) => {
		setUserPlaceholder('Username');
	};

	const passFocusPlaceholder = (e) => {
		setPassPlaceholder('');
	};

	const passBlurPlaceholder = (e) => {
		setPassPlaceholder('Password');
	};
	useEffect(() => {
		//adds background image to the body
		const element = document.getElementsByTagName('body')[0];
		element?.classList?.add('bg-image');
		sessionStorage.clear();
		localStorage.clear();
	}, []);

	/****************************************
	 * It checks for the change in props and navigates the user
	 * to the step2 verification page of the application.
	 * Method has one signature.
	 * @param - props - These are the updated props that the component receives.
	 ****************************************/

	useEffect(() => {
		if (props.auth?.error) {
			setErr(props.auth.error);
			setDisableForm(false);
		}
	}, [props.auth?.error]);

	useEffect(() => {
		if (props.auth?.success && user.email != '') {
			setObjectToLocal('user', { email: user.email });
			//switching the route to Step2 Verification Page after successful authentication.
			props.history.push(constants.ROUTE_URL + '/step2');
		}
	}, [props.auth?.success]);

	/****************************************
	 * Function Name: validate.
	 * Description: This function performs the necessary validations on the
	 * front end whenever the user submits a form.
	 * Checks for valid email and password and if true it makes
	 * a call to the function Login to make Api call to the backend.
	 ****************************************/

	const validate = () => {
		//form validations on the front end
		localStorage.clear();
		const { email, password } = user;
		setDisableForm(true);
		setErr('');

		if (!email?.trim()) {
			setErr('Email is required.');
			setDisableForm(false);
			return;
		}

		if (email) {
			//checking the email pattern entered by the user
			let emailReg = new RegExp(/^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/);
			let res = emailReg.test(email);
			if (!res) {
				setErr('Provide a valid email.');
				setDisableForm(false);
				return;
			}
		}
		if (!password) {
			setErr('Password is required.');
			setDisableForm(false);
			return;
		}
		Login();
	};

	/****************************************
	 * Function Name: updateValue.
	 * Description: This method updates the value of the credentials
	 * from the form input in the state.
	 * Method has one signature.
	 * @param - e - It is event property through which we get the values.
	 ****************************************/

	const updateValue = (e) => {
		//updates the value from the form input
		const _user = {
			...user,
			[e.target.name]: e.target.value,
		};
		setUser(_user);
		setErr('');
	};

	/****************************************
	 * Function Name: Login.
	 * Description: This function is used to call the Auth0 login api end point
	 * in order to authenticate a particular user.
	 ****************************************/

	const Login = () => {
		const { email, password } = user;
		// For Auth0 Access
		if (constants.ALLOW_AUTH0_ACCESS_URL == 'Y') {
			//Assigning Auth0 credentials from the config file.
			const callbackUrl = constants.CALLBACK_URL + '/step2';
			const webAuth = new auth0.WebAuth({
				domain: constants.AUTH0_DOMAIN,
				clientID: constants.AUTH0_CLIENT_ID,
			});
			// Validating the user credentials using Auth0 aunthentication
			webAuth.login(
				{
					realm: 'Username-Password-Authentication',
					username: email,
					password: password,
					responseType: 'token id_token',
					redirectUri: callbackUrl,
				},
				(err, authResult) => {
					if (err) {
						setErr(err.error_description);
						setDisableForm(false);
					}
				}
			);
			setObjectToLocal('change_password_user', {
				email: email,
			});
		}
		// For Normal Access
		else {
			login(user);
		}
	};

	/****************************************
	 * Function Name: handleKeyPress.
	 * Description: This function handles form submit when Enter key is pressed.
	 * Checks if enter key is pressed and if true it calls
	 * the function validate to perform validations.
	 ****************************************/

	const handleKeyPress = (target) => {
		//check if enter is pressed.
		if (target.charCode == 13) {
			validate();
		}
	};

	const { email, password } = user;
	return (
		<div className="login-container">
			<div className="login">
				<div className="login-header">
					<div className="logo-wrapper">
						<img className="logo_img" src={logo} alt="Verantos_Logo" />
					</div>

					<span className="login-text">Login</span>
				</div>
				<fieldset className="login_form" disabled={disableForm}>
					<div className="form-field-control">
						<FormControl
							type="text"
							name="email"
							className="form-field-input"
							id="email"
							onBlur={blurPlaceholder}
							placeholder=" "
							onChange={updateValue}
							autoFocus
							onKeyPress={handleKeyPress}
							value={email}
						/>
						<label htmlFor="email" id="email-label" className="form-field-label">
							Email
						</label>
					</div>
					<div className="form-field-control">
						<FormControl
							type="password"
							name="password"
							className="form-field-input"
							id="password"
							onBlur={passBlurPlaceholder}
							placeholder=" "
							onChange={updateValue}
							value={password}
							onKeyPress={handleKeyPress}
						/>
						<label htmlFor="password" id="email-label" className="form-field-label">
							Password
						</label>
					</div>
					<div className="login-error_field">{err}</div>
					<p className="submit">
						<Button
							type="submit"
							buttonType="2"
							name="commit"
							value="Sign in"
							id="login"
							onClick={validate}
						>
							Login
						</Button>
					</p>
					<p>
						<Link to={constants.ROUTE_URL + '/forgotpassword'} className="forgot">
							Forgot password?
						</Link>
					</p>
				</fieldset>
			</div>
		</div>
	);
};

Auth0Login.propTypes = propTypes;
const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Auth0Login);
