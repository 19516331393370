import actionTypes from '../../actions/actionTypes';
import { apiGet } from '../../utilities/utils';
import store from '../../store';
import actions from '../../actions';

export function getStudyDetail(study_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyDetail', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_DETAIL,
					payload: res.data,
				});
				resolve(res.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export function getCohortList(params) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortList', params)
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_COHORT_LIST,
					payload: res.data.data,
				});
				resolve(res);
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.IS_LOADING,
					payload: false,
				});
				console.log(err);
				reject(err);
			});
	});
}

export function getStudyArmList(study_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetStudyArmList', {
			'm_StudyObj.study_id': study_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.GET_STUDY_ARM_LIST,
					payload: res.data.data,
				});
				resolve(res.data.data);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

export const searchConceptsById = (conceptIds) => {
	return new Promise((resolve, reject) => {
		store
			.dispatch(actions.searchConceptsById(conceptIds))
			.then((resp) => {
				resolve(resp);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
// export function searchConceptsById(conceptIds, eventIds) {
// 	return new Promise(function (resolve, reject) {
// 		let params = {};
// 		params['conceptTableEntry.umls_concept_category'] = 'All';
// 		if (conceptIds) {
// 			params['conceptIds'] = conceptIds;
// 		}
// 		if (eventIds) {
// 			params['eventIds'] = eventIds;
// 		}
// 		apiGet('SearchConceptsById', params)
// 			.then((res) => {
// 				store.dispatch({
// 					type: actionTypes.GET_STUDY_CONCEPTS,
// 					payload: res.data.data,
// 				});
// 				resolve(res.data.data);
// 			})
// 			.catch((err) => {
// 				reject(err);
// 			});
// 	});
// }

export function getCohortCriteria(cohort_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortCriterion', {
			'cohortObj.cohort_id': cohort_id,
		})
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function getCohortPatient(cohort_id) {
	return new Promise(function (resolve, reject) {
		apiGet('GetCohortPatient', {
			'cohortObj.cohort_id': cohort_id,
		})
			.then((res) => {
				store.dispatch({
					type: actionTypes.SET_COHORT_PATIENT,
					payload: res.data.data,
				});

				resolve(res.data.data);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}

export function saveStudyCohorts(studyCohort, cohortIndex) {
	store.dispatch({
		type: actionTypes.SAVE_STUDY_COHORTS,
		cohortIndex: cohortIndex,
		payload: studyCohort,
	});
}

export function clearStudyCohorts() {
	store.dispatch({
		type: actionTypes.CLEAR_STUDY_COHORTS,
	});
}

export const GetCohortOutcomesPatientCounts = (cohortId) => {
	return new Promise((resolve, reject) => {
		apiGet('GetCohortOutcomesPatientCounts', { 'cohortObj.cohort_id': cohortId })
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				reject(err);
			});
	});
};
