import React from 'react';
import { withRouter, browserHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import constants from '../../constants';
import actions from '../../actions';
//import history from '../../node_modules/history';
import { setQueryParams, getRoutePath } from '../../utilities/utils';
import store from '../../store';
import queryString from 'query-string';
import locationhistory from '../CreateBrowserHistory/CreateBrowserHistory';
import { setIsLoading } from '../../actions/common';
import logoVerantos from '../../images/Verantoslogos.svg';

const mapStateToProps = (state) => state;

const Navigation = (props) => {
	let { navList, filter, history } = props.sidenav;
	let revHistory = sessionStorage.getItem('storedHistory')
		? JSON.parse(sessionStorage.getItem('storedHistory'))
		: [];
	let totalOptions = filter && filter.options && filter.options.length;

	const goToPage = (nav) => {
		//REMOVE EXISTING FILTER IF ANY
		props.dispatch(actions.setFilterText(null));

		setQueryParams({
			pathname: nav.pathname,
			search: nav.search,
		});
	};

	const removeBreadCrumb = (filter, filterLabel) => {
		const values = queryString.parse(window.location.search);
		let oldUrl = window.location.href;

		if (filter.options.length > 0) {
			filter.options = filter.options.filter((item) => item.filterLabel != filterLabel);
			store.dispatch(actions.setFilterText(filter));
			let path = window.location.pathname; //getRoutePath(filter.page);
			var urlParams = new URLSearchParams(window.location.search);
			let isConceptKappaPage =
				window.location.pathname.indexOf('conceptKappa') != -1 ? true : false;
			let queryParams = {};
			for (const [key, value] of urlParams.entries()) {
				if (key.toString() != filterLabel.toLowerCase() + '_id') {
					queryParams[key] = value;
				}
			}
			if (
				values.patientView &&
				(filterLabel.toLowerCase() == 'concept' ||
					filterLabel.toLowerCase() == 'concept result')
			) {
				queryParams = '';
			}
			if (values.patientView && filterLabel.toLowerCase() == 'event') {
				queryParams = '';
			}
			if (isConceptKappaPage && filterLabel.toLowerCase() == 'concept') {
				locationhistory.goBack();
			}

			setQueryParams({
				pathname: path,
				query: queryParams,
			});
			if (props.resetState) {
				props.resetState(oldUrl);
			}

			// setIsLoading(false)
		} else {
			store.dispatch(actions.setFilterText(null));
			const path = window.location.pathname; //getRoutePath(filter.page);
			setQueryParams(path);
			// setIsLoading(false)
		}
	};

	const textwrap = (text) => {
		let result = text?.substring(0, 27) || '';
		if (result.length > 26) {
			result = result + ' ...';
		}
		return result;
	};

	return (
		<div id="sidebar-content" className="col-sm-12 container">
			<div className="dataNavigation">
				<div className="logo-container">
					<img className="logo_img" src={logoVerantos} alt="Verantos logo" />
				</div>
				<div className="topMargin">
					<div>
						<ul>
							<li>
								<p>Filter</p>
							</li>
							<li>
								{filter && (
									<div className="filterTab">
										{filter.options.map((items, index) => {
											return  items.name !== 'undefined' ? (
												<a
													className="filterText"
													title={items.filterLabel + ': ' + items.name}
													key={index}
												>
													{textwrap(
														items.filterLabel + ': ' + items.name
													)}
												</a>
											) : (
												''
											);
										})}
									</div>
								)}
							</li>
							<li>
								<p>History</p>
							</li>
						</ul>
					</div>
				</div>
				<div className="historyTab">
					<ul>
						{revHistory.map((items, index) => {
							return (
								<li title={items.display_name} className="listNav" key={index}>
									<a onClick={() => goToPage(items)}>
										{textwrap(items.display_name)}
									</a>
								</li>
							);
						})}
					</ul>
				</div>
			</div>
		</div>
	);
};

export default connect(mapStateToProps)(Navigation);
