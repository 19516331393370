import { apiPost, apiPostForm } from '../../../utilities/utils';
import actionTypes from '../../../actions/actionTypes';
import { setObjectToLocal } from '../../../utilities/utils';
import store from '../../../store';

export const login = (user) => {
	new Promise((resolve, reject) => {
		apiPostForm(
			'Login',
			{ 'email': user.email, 'password':user.password})
			.then((res) => {
				store.dispatch({
					type: actionTypes.AUTH_LOGIN_SUCCESS,
					payload: true,
				});
				setObjectToLocal('authSecret', { secret: res.data.authSecret }),
					setObjectToLocal('user', { email: user.email }),
					sessionStorage.setItem('userSession', 'Y');
			})
			.catch((err) => {
				store.dispatch({
					type: actionTypes.AUTH_LOGIN_ERROR,
					payload: err.response,
				});
			});
	});
};
