import actionTypes from "../../actions/actionTypes";

const initialState = {
    isCohortEdited: false,
}

export const cohortReducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.COHORT_EDITED: 
            return { ...state, isCohortEdited: action.payload };

        default : {
            return state;
        }
    }
}