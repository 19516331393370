import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CountLink from '../../../common/CountLink/CountLink';
import { getUserEntityCounts } from './AccountDataHelper';

const propTypes = {
	dispatch: PropTypes.func,
	gotoPage: PropTypes.func,
};
function AccountData(props) {
	const [studiesCount, setStudiesCount] = useState(0);
	const [cohortCount, setCohortCount] = useState(0);
	const [patientCount, setPatientCount] = useState(0);
	const [dataCount, setDataCount] = useState(0);
	const { gotoPage } = props;

	useEffect(() => {
		getUserEntityCounts().then((res) => {
			setStudiesCount(res.study_count);
			setCohortCount(res.cohort_count);
			setPatientCount(res.patient_count);
			setDataCount(res.data_count);
		});
	}, []);

	return (
		<div id='account-data-content'>
			<div data-test='content-title' style={{ padding: 10, paddingLeft: 24, background: '#f5f5f5', marginTop: 16 }}>
				Studies:
			</div>
			<table id="count-table">
				<tbody>
					<tr data-test='row1-data'>
						<td>Studies:</td>
						<td>
							<CountLink data-test='study-link' handleClick={() => gotoPage('study')} count={studiesCount} />
						</td>
					</tr>
					<tr data-test='row2-data'>
						<td>Cohorts:</td>
						<td>
							<CountLink data-test='cohort-link' handleClick={() => gotoPage('cohort')} count={cohortCount} />
						</td>
					</tr>
					<tr data-test='row3-data'>
						<td>Patients:</td>
						<td>
							<CountLink
							data-test='patient-link'
								handleClick={() => gotoPage('patient')}
								count={patientCount}
							/>
						</td>
					</tr>
					<tr data-test='row4-data'>
						<td>Documents:</td>
						<td>
							<CountLink data-test='document-link' handleClick={() => gotoPage('data')} count={dataCount} />
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

AccountData.propTypes = propTypes;

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AccountData);
