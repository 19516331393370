
const modalConstants = {
    logout: 'LOGOUT',
    productSetting: 'PRODUCT_SETTING',
    accountSetting: 'ACCOUNT_SETTING',
    dataSetting: 'DATA_SETTING',

    addUser: 'ADD_USER',
    userList: 'USER_LIST',
    userDetail: 'USER_DETAIL',
    editUser: 'EDIT_USER',
    searchUser: 'SEARCH_USER',
    deleteUser: 'DELETE_USER',
    dataAnalyse: 'DATA_ANALYSE',
    userRoles: 'USER_ROLES'

}
export default modalConstants;