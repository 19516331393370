import axios from 'axios';
import constants from '../constants';
import history from '../common/CreateBrowserHistory/CreateBrowserHistory';
import { format } from 'date-fns';
import store from '../store';
// import moment from 'moment';
export function generateUrl(path) {
	return constants.API_URL + path;
}

export function apiPost(endpoint, data, headers = {}) {
	return apiReq(generateUrl(endpoint), data, 'post');
}

/****************************************
 * Function Name: apiPostForm
 * Description: Convert an object to form data for post call
 ****************************************/

export function apiPostForm(endpoint, data, headers = {}) {
	var postdata = {};
	if (Array.isArray(data)) {
		postdata = {
			'list': data
		}
		// bodyFormData.set('m_JSONArrayList', JSON.stringify(data));
	} else {
		postdata = {
			'data': data
		}
	}

	return apiReq(generateUrl(endpoint), postdata, 'post');
}
export function apiGet(endpoint, data, headers = {}) {
	let _state = store.getState();
	let userRole = '';
	if (_state.sidenav && _state.sidenav.userRole.Role) {
		userRole = _state.sidenav.userRole.Role;
	}
	if (!data) {
		data = {};
	}
	let exactAPIUrl = endpoint.split('?')[0]
	if(exactAPIUrl == 'GetPatient' ){
		data.userRole = userRole;
	}
	if(exactAPIUrl == 'GetDocuments'){
		data.userRole = userRole;
	}
	if(exactAPIUrl == 'GetManualAnnotations'){
		data.userRole = userRole;
	}
	if(exactAPIUrl == 'GetDocumentManualAnnotations'){
		data.userRole = userRole;
	}
	if(exactAPIUrl == 'GetCohortManualAnnotations'){
		data.userRole = userRole;
	}
	if(exactAPIUrl == 'GetPatientManualAnnotations'){
		data.userRole = userRole;
	}
	if(exactAPIUrl == 'GetManualAnnotationsWithConflicts'){
		data.userRole = userRole;
	}
	if(exactAPIUrl == 'GetPatientManualAnnotationsWithConflicts'){
		data.userRole = userRole;
	}
	if(exactAPIUrl == 'GetAlgorithmicProblemList'){
		data.userRole = userRole;
	}
	return apiReq(generateUrl(endpoint), data, 'get', headers);
}

export function apiReq(endpoint, data, method, headers) {
	return new Promise((res, rej) => {
		headers = {
			...headers,
		};

		if (method == 'get') {
			data = {
				params: data,
				headers,
				withCredentials: true,
			};
		}

		axios[method](endpoint, data, { headers })
			.then((result) => {
				return res(result);
			})
			.catch((err) => {
				if (err?.response?.status == 401) {
					localStorage.removeItem('user');
					history.push('');
					// window.location.reload();
					return;
				}
				return rej(err);
			});
	});
}

/****************************************
 * Function Name: hasDuplicates
 * Description: Checks whether an array consists of duplicate elements or not.
 * Method has one signature.
 * @param - arr - An array for which to find out whether it has duplicates
 * or not.
 ****************************************/

export function hasDuplicates(arr, property) {
	let seen = {};

	let check = arr.some(function (currentObject) {
		if (currentObject[property]) {
			return (
				seen.hasOwnProperty(currentObject[property]) ||
				(seen[currentObject[property]] = false)
			);
		}
	});

	return check;
}

export function partial(fn, ...presetArgs) {
	return function partiallyApplied(...laterArgs) {
		return fn(...presetArgs, ...laterArgs);
	};
}

export function numberWithCommas(x) {
	if (!x) return x;

	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getIntersection(FilterFromArr, FilterWithArr, matchingProperty) {
	if (FilterFromArr && FilterWithArr && matchingProperty) {
		return JSON.parse(
			JSON.stringify(
				[...FilterFromArr].filter((element1) =>
					FilterWithArr.some(
						(element2) => element1[matchingProperty] == element2[matchingProperty]
					)
				)
			)
		);
	}
}

/****************************************
 * Function Name: setObjectToLocal
 * Description: This method sets the variable in the local storage.
 * @param - key - The name of the variable.
 * @param - value - The value of the variable.
 ****************************************/
export function setObjectToLocal(key, value) {
	if (window && window.localStorage) {
		try {
			localStorage.setItem(key, JSON.stringify(value));
		} catch (error) {
			// if (error == 'QUOTA_EXCEEDED_ERR') {
			try {
				Object.keys(localStorage).map((keys) => {
					if (keys.indexOf('subdocumentjson_') != -1) {
						localStorage.removeItem(keys);
					}
				});
				localStorage.setItem(key, JSON.stringify(value));
			} catch (err) {}
			// }
		}
	}
}
/****************************************
 * Function Name: removeItem
 * Description: This method removes the variable from the local  and session storage.
 * @param - key - The name of the variable.
 ****************************************/
export function removeItemFromLocal(key) {
	if (window && window.localStorage) {
		localStorage.removeItem(key);
	}
	if (window && window.sessionStorage) {
		sessionStorage.removeItem(key);
	}
}
/****************************************
 * Function Name: getObjectFromLocal
 * Description: This method gets the variable from the local storage.
 * @param - key - The name of the variable.
 ****************************************/
export function getObjectFromLocal(key) {
	if (typeof window === 'undefined') {
		return null;
	}

	if (window && window.localStorage) {
		const value = localStorage.getItem(key);
		return value ? JSON.parse(value) : null;
	}
}

export function filterList(value, list, columns) {
	if (!value) {
		return list;
	} else {
		return manualSearch(value, list, columns);
	}
}

function manualSearch(term, list, columns) {
	if (term && list) {
		const tokenizeArray = term.split(' ').filter((token) => token);

		return list.filter((item) => {
			for (let j = 0; j < tokenizeArray.length; j++) {
				const token = tokenizeArray[j].toLowerCase();
				// we want to make sure all tokens are matched.
				var found = false;
				for (let i = 0; i < columns.length; i++) {
					let column = columns[i];
					const matched =
						item[column.dataKey] &&
						item[column.dataKey].toString().toLowerCase().indexOf(token) > -1;

					if (matched) {
						found = true;
						break;
					}
				}

				if (!found) {
					return false;
				}
			}
			return true;
		});
	}
	return list;
}

/****************************************
 * Function Name: formatDate
 * Description: Convert date string in given format
 * @param {string} date input date to format
 * @param {string} format date format in which date string expected to format, default = MM-DD-YYYY
 */
export function formatDate(date, dateformat = 'MM/DD/YYYY') {
	dateformat = dateformat.replace('YYYY', 'yyyy').replace('DD', 'dd');
	return date && format(new Date(date), dateformat);
	// return moment(date).format(dateformat)
}

/****************************************
 * Function Name: sortByDate
 * Description: Sort array of object by date in given order.
 * @param {array} collection - array of objects.
 * @param {string} key - Key on which sort must be performed.
 * @param {boolean} isAscending - Sort ascending if true, descending if false
 */
export function sortByDate(collection, key, isAscending = true) {
	return collection.sort((item1, item2) => {
		//console.log(moment.utc(item1[key]));

		return isAscending
			? new Date(item1[key]) < new Date(item2[key])
			: new Date(item2[key]) < new Date(item1[key]);
	});

	//   return isAscending
	//     ? moment.utc(item1[key]).diff(moment.utc(item2[key]))
	//     : moment.utc(item2[key]).diff(moment.utc(item1[key]))
	// })
}

export function ConvertToLocale(count) {
	if (count) {
		return count.toLocaleString(navigator.language);
	} else {
		return 0;
	}
}

// /****************************************
//     * Function Name: isValidDateRange
//     * Description: It checks if first date is earlier than end date.
//     * @param {Object} startDate - Start date of range.
//     * @param {Object} endDate - End date of range.
//  */
// export function isValidDateRange(startDate, endDate) {
//   if (moment.utc(startDate).diff(moment.utc(endDate)) < 1) {
//     return true;
//   }
//   return false;
// }

/**
 * Function Name: getRoutePath
 * Description: Returns target page url for given page.
 * @param page Target page
 */
export const getRoutePath = (page) => {
	let path = '';
	if (page === 'cohort') {
		path = '/dashboard/cohortlist';
	} else if (page === 'study') {
		path = '/dashboard/studylist';
	} else if (page === 'patient') {
		path = '/dashboard/patientlist';
	} else if (page === 'data') {
		path = '/dashboard/datalist';
	}
	return constants.ROUTE_URL + path;
};

export function setQueryParams(route) {
	let queryParams = '';
	if (route.query) {
		for (var param in route.query) {
			queryParams += param + '=' + route.query[param] + '&';
		}
	}

	history.push({
		pathname: route.pathname,
		search: route.search ? route.search : '?' + queryParams,
	});
}

export function convertToPlural(word) {
	let plural = {
		'(quiz)$': '$1zes',
		'^(ox)$': '$1en',
		'([m|l])ouse$': '$1ice',
		'(matr|vert|ind)ix|ex$': '$1ices',
		'(x|ch|ss|sh)$': '$1es',
		'([^aeiouy]|qu)y$': '$1ies',
		'(hive)$': '$1s',
		'(?:([^f])fe|([lr])f)$': '$1$2ves',
		'(shea|lea|loa|thie)f$': '$1ves',
		sis$: 'ses',
		'([ti])um$': '$1a',
		'(tomat|potat|ech|her|vet)o$': '$1oes',
		'(bu)s$': '$1ses',
		'(alias)$': '$1es',
		'(octop)us$': '$1i',
		'(ax|test)is$': '$1es',
		'(us)$': '$1es',
		'([^s]+)$': '$1s',
	};

	let updatedWord = [];
	Object.keys(plural).map(function (reg) {
		var pattern = new RegExp(reg, 'i');

		if (pattern.test(word)) {
			updatedWord.push(word.replace(pattern, plural[reg]));
		}
	});
	return updatedWord[0];
}

export function rearrangeConceptCategories(conceptCategoryList) {
	let categories = [];
	let categoryIndex = 5;
	conceptCategoryList.map((item) => {
		if (item.Category == 'Problem') {
			categories[0] = item.Category;
		} else {
			if (item.Category == 'Finding') {
				categories[1] = item.Category;
			} else {
				if (item.Category == 'Procedure') {
					categories[2] = item.Category;
				} else {
					if (item.Category == 'Medication') {
						categories[3] = item.Category;
					} else {
						if (item.Category == 'Lab') {
							categories[4] = item.Category;
						} else {
							categories[categoryIndex] = item.Category;
							categoryIndex++;
						}
					}
				}
			}
		}
	});

	return categories;
}

export function rearrangeCategories(conceptCategoryList) {
	let categories = [];
	let categoryIndex = 5;
	conceptCategoryList.map((item) => {
		if (item == 'Problem') {
			categories[0] = item;
		} else {
			if (item == 'Finding') {
				categories[1] = item;
			} else {
				if (item == 'Procedure') {
					categories[2] = item;
				} else {
					if (item == 'Medication') {
						categories[3] = item;
					} else {
						if (item == 'Lab') {
							categories[4] = item;
						} else {
							categories[categoryIndex] = item;
							categoryIndex++;
						}
					}
				}
			}
		}
	});

	return categories;
}

Number.prototype.toFixedNoRounding = function (n) {
	const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + n + '})?', 'g');
	const a = this.toString().match(reg)[0];
	const dot = a.indexOf('.');
	if (dot === -1) {
		// integer, insert decimal dot and pad up zeros
		return a + '.' + '0'.repeat(n);
	}
	const b = n - (a.length - dot) + 1;
	return b > 0 ? a + '0'.repeat(b) : a;
};


export function retryComponentLoad(fn, retriesLeft = 5, interval = 1000) {
	return new Promise((resolve, reject) => {
	  fn()
		.then(resolve)
		.catch((error) => {
		  setTimeout(() => {
			if (retriesLeft === 1) {
			  // reject('maximum retries exceeded');
			  reject(error);
			  return;
			}
  
			// Passing on "reject" is the important part
			retry(fn, retriesLeft - 1, interval).then(resolve, reject);
		  }, interval);
		});
	});
  }