import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const NavLink = (props) => {
	let isActive = false; //this.context.router.isActive(props.to, true);
	let className = isActive ? 'active' : '';
	let name = props.name;

	// if(this.context.router.location.pathname.indexOf(name)!=-1 && Object.keys(this.context.router.params).length!=0){
	//     className = 'active';
	// }
	if (location.pathname.indexOf('techdetail') != -1 &&  props.name == 'technology') {
		className = 'active';
	} else if (location.pathname.indexOf('document') != -1 &&  props.name == 'data') {
		className = 'active';
	} else if (location.pathname.indexOf('datamanual') != -1 &&  props.name == 'data') {
		className = 'active';
	} else if (location.pathname.indexOf('dataautomated') != -1 &&  props.name == 'data') {
		className = 'active';
	} else if (location.pathname.indexOf('datacohort') != -1 &&  props.name == 'data') {
		className = 'active';
	} else if (location.pathname.indexOf('addcohort') != -1 &&  props.name == 'cohort') {
		className = 'active';
	} else if (location.pathname.indexOf('cohortdesign') != -1 &&  props.name == 'cohort') {
		className = 'active';
	} else if (location.pathname.indexOf('cohort/edit') != -1 &&  props.name == 'cohort') {
		className = 'active';
	} else if (location.pathname.indexOf('cohortlist') != -1 &&  props.name == 'cohort') {
		className = 'active';
	} else if (location.pathname.indexOf('cohortresult') != -1 &&  props.name == 'cohort') {
		className = 'active';
	} else if (location.pathname.indexOf('datalist') != -1 &&  props.name == 'data') {
		className = 'active';
	} else if (location.pathname.indexOf('techitem') != -1 &&  props.name == 'technology') {
		className = 'active';
	} else if (location.pathname.indexOf('patient') != -1 &&  props.name == 'patient') {
		className = 'active';
	} else if (location.pathname.indexOf('study') != -1 &&  props.name == 'study') {
		className = 'active';
	}
	// else if(  props.name == 'study'){
	//     className = 'active';
	// }
	if(className){
		localStorage.setItem('activeIndex',props.id)
	}
	return sessionStorage.getItem('PreviewText') ? (
		<li className={className} onClick={ props.onClick}>
			{ props.children}
		</li>
	) : (
		<li className={`${className}  nav-side-link`}>
			<div {... props} />
		</li>
	);
};

NavLink.contextTypes = {
	router: PropTypes.object
};

export default NavLink;
