import { apiPostForm } from '../../../utilities/utils';

export function getPreviewGATEResponse(data) {
	return new Promise(function (resolve, reject) {
		apiPostForm('TagNLP?isTextDocument=true&nlpTechnology=GATE', data)
			.then((res) => {
				resolve(res);
			})
			.catch((err) => {
				console.log(err);
				reject(err);
			});
	});
}
