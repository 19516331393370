import { apiGet } from '../../../utilities/utils';
import queryString from 'query-string';

/****************************************
 * Function Name: getUserEntityCounts
 * Description: exports an action to get the organization details of a
 * particular organization from the backend.
 * The data received is returned via promise which can be used
 * later for manipulation.
 * Method has one signature.
 * @param - organization_id - The id of the organization for which the details have
 * to be fetched from the backend.
 ****************************************/

 export function getUserEntityCounts(query = {}) {
	// let data = organization_id ? { "organizationId": organization_id } : {};
	return new Promise(function (resolve, reject) {
			let forKappaScore = queryString.parse(window.location.search).forKappaScore;
			if (forKappaScore) {
				query.forKappaScore = true;
			}
			apiGet('GetUserEntityCounts', query)
				.then((res) => {
					resolve(res.data.data);
				})
				.catch((err) => {
					console.log(err);
					reject(err);
				});
		});
}