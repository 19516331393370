import React, { useState, useEffect, lazy, Suspense } from 'react';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Loading from 'react-loading-spinner';
import { setIsLoading } from '../../../actions/common';
import { GetUserRole, getPatientListAnnotator, getPatientIcons } from './DataWorkQueueHelper';
import { useSelector } from 'react-redux';
import dataIcon from '../../../images/data_icon.png';
const ListOfPatientCollapsible = lazy(() =>
	import(
		/* webpackChunkName: "ListOfPatientCollapsible" */ '../../../components/ListOfPatientCollapsible/ListOfPatientCollapsible'
	)
);
import PaginationComponent from '../../../common/Pagination/PaginationComponent';

const GridHeader = lazy(() =>
	import(/* webpackChunkName: "GridHeader" */ '../../../components/GridHeader/GridHeader')
);

const headerData = {
	title: 'Data',
	searchTitle: 'data',
	list: 'list',
	img: (
		<div className="data_icon">
			<img src={dataIcon} alt="Data List" style={{ width: 20 }} />
		</div>
	),
	lastColumn: 'Patients',
};

const DataWorkAnnotator = (props) => {
	const [patients, setPatients] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [pageNo, setPageNo] = useState(1);
	const [fetchDocuments, setFetchDocuments] = useState(false);
	const [totalPatients, setTotalPatients] = useState('');
	const { userRole } = useSelector((state) => state.sidenav);
	const { loading } = useSelector((state) => state.loading);

	useEffect(() => {
		setIsLoading(true);
		if (!props?.sidenav?.userRole?.Role) {
			GetUserRole().then((resp) => {
				getWorkQueuePatient(1, '');
			});
		} else {
			getWorkQueuePatient(1, '');
		}
	}, []);

	const getWorkQueuePatient = (pageNo, searchText, recordsPerPage = 10) => {
		setIsLoading(true);
		getPatientListAnnotator(1, pageNo, searchText, '', true, recordsPerPage)
			.then((res) => {
				const _patients = res.list;
				setPatients(_patients);
				setTotalPatients(res.count);

				//PREVENT FURTHER PROCESSING, IF PATIENTS ARE NOT PRESENT
				if (!_patients.length) {
					return;
				}
				const patientIdList = _patients.map((patient) => patient.patient_id).join();
				//GET REQUIRED INFO TO SHOW COHORT and ANNOTATION ICONS.
				getPatientIcons(_patients, patientIdList, true).then((patients) => {
					setPatients(patients);
					setFetchDocuments(true);
				});
				setIsLoading(false);
			})
			.catch((error) => {
				setIsLoading(false);
			});
	};
	const gotoPage = (pagenumber, recordsPerPage) => {
		setPageNo(pagenumber);
		getWorkQueuePatient(pagenumber, '', recordsPerPage);
	};

	return (
		<div className="data-work-annotator">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={true}
					searchFunction={() => {}}
					searchText=""
					history={props.history}
					noMenu={true}
				/>
			</ErrorBoundary>
			<div className="document-header-container">Data list: Manual annotation work queue</div>
			<Suspense fallback={<div className="defaultSpinner" />}>
				<GridHeader
					listLabel="Patients"
					lastLabel={headerData.lastColumn}
					userRole={userRole}
					isDataPage={1}
				/>
			</Suspense>
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<ListOfPatientCollapsible
						patients={patients}
						paddingLeft={0}
						parentBgDark
						isDataPage={1}
						searchText={searchText}
						fetchDocuments={fetchDocuments}
						userRole={userRole}
					/>
				</Suspense>
			</ErrorBoundary>
			{totalPatients && (
				<ErrorBoundary>
					<PaginationComponent
						totalItemsCount={totalPatients}
						gotoPage={gotoPage}
						currentPage={pageNo}
					/>{' '}
				</ErrorBoundary>
			)}
		</div>
	);
};
export default DataWorkAnnotator;
