import React, { useEffect, lazy, Suspense } from 'react';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import constants from '../../constants';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';
import { setCriteriaData } from './AddCohortHelper';
import cohortIcon from '../../images/cohort_icon.png'

const EditCohort = lazy(() =>
	import(/* webpackChunkName: "EditCohort" */ '../../components/EditCohort/EditCohortComponent')
);

const headerData = {
	title: 'Cohort',
	list: 'add',
	img: (
		<img
			src={cohortIcon}
			className={'App-CohortList'}
			alt={'Cohort List'}
		></img>
	),
};

function AddCohort(props) {
	useEffect(() => {
		setCriteriaData([]);
	}, []);

	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles data={headerData} search={false} {...props} />
			</ErrorBoundary>
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<EditCohort cohortId={-1} />
				</Suspense>
			</ErrorBoundary>
		</div>
	);
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AddCohort);
