import React, { useState, useEffect, lazy, Suspense } from 'react';
import constants from '../../constants';
import { connect } from 'react-redux';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import Loading from 'react-loading-spinner';
import Copyright from '../../components/Copyright/Copyright';
import {
	getCohortList,
	getStudyDetail,
	getEvent,
	clearStudyCohorts,
	getStudyArmList,
	searchConceptsById,
	getCohortCriteria,
	saveStudyCohorts,
	deleteStudy,
	addStudy,
	editStudy,
} from './AddStudyHelper';
import { setIsLoading } from '../../actions/common';
import trialIcon from '../../images/trial_icon.png';
import addPlusIcon from '../../images/add_plus_icon.svg';



const AddStudyCohorts = lazy(() =>
	import(
		/* webpackChunkName: "AddStudyCohorts" */ '../../components/AddStudyCohorts/AddStudyCohorts'
	)
);
const OutcomeMeasures = lazy(() =>
	import(
		/* webpackChunkName: "OutcomeMeasures" */ '../../components/OutcomeMeasures/OutcomeMeasures'
	)
);

const AddStudyContainer = (props) => {
	const [studyCohort, setStudyCohort] = useState(
		props.study?.studyCohorts[0] ? props.study.studyCohorts : [{ value: '' }]
	);
	const [cohortMinusIconClicked, setCohortMinusIconClicked] = useState(false);
	const [placeholder, setPlaceholder] = useState('Enter study name');
	const [studyName, setStudyName] = useState('');
	const [studyMeasures, setStudyMeasures] = useState([{ title: 'Measure #', value: '' }]);
	const [errors, setErrors] = useState([]);
	const [open, setOpen] = useState(props.customOpen ? props.customOpen : false); // test customOpen props delete modal
	const [studyId, setStudyId] = useState(props.match?.params?.studyId);
	const [className, setClassName] = useState('');
	const { studyDetail, studyCohorts } = props.study;
	let { loading } = props.loading;
	

	let _props = {
		title: 'Study',
		list: studyId ? 'edit' : 'add',
		img: (
			<img
				src={trialIcon}
				className={'App-TrialList'}
				alt={'Study image'}
			/>
		),
	};

	let cohortOptions =
	
	props?.cohort?.cohortList &&
	props?.cohort?.cohortList.map((cohort) => {
			return { value: cohort.cohort_id, label: cohort.cohort_name };
		});

	let eventOptions =
	props?.cohort?.events &&
	props?.cohort?.events.map((event, index) => {
			return { value: event.event_id, label: event.name };
		});

	const modifiedStudyCohort = studyId
		? studyCohort.map((cohort) => {
				const firstCohort = cohort[0] || {};

				return {
					value: firstCohort.cohort_id || '',
					label:
						(firstCohort && firstCohort.cohort && firstCohort.cohort.cohort_name) || '',
				};
		  })
		: studyCohort;

	let newCohortOptions =
		(cohortOptions &&
			cohortOptions.filter(
				(option) =>
					!modifiedStudyCohort.find((cohort) => option.value == (cohort && cohort.value))
			)) ||
		[];

	useEffect(() => {
		getCohortList().then((res) => {});
		getEvent();
		editStudyCohort();
	}, []);

	useEffect(() => {
		if (props.studyId) {
			setStudyCohort(props.study.studyCohorts);
		}
	}, [props.studyId]);

	useEffect(() => {
		return () => {
			clearStudyCohorts();
		};
	}, []);

	const editStudyCohort = () => {
		try {
			if (studyId) {
				getStudyDetail(studyId).then((studyDetail) => {
					setStudyName(studyDetail.study_name);
					const measureValue = [
						{
							title: studyMeasures[0].title,
							value: studyDetail.event_id,
						},
					];
					setStudyMeasures(measureValue);

					getCohortList().then((_cohortList) => {
						getStudyArmList(studyId).then((studyArmList) => {
							let cohortList = [];
							_cohortList &&
								_cohortList.filter((cohort) => {
									if (cohort.cohort_id == studyDetail.base_cohort) {
										cohortList.push(cohort);
									}
								});
							if (studyArmList.length) {
								studyArmList.map((studyArm) => {
									_cohortList &&
										_cohortList.filter((cohort) => {
											if (cohort.cohort_id == studyArm.cohort_id) {
												cohortList.push(cohort);
											}
										});
								});
							}

							let PromiseArrCohort =
								studyArmList &&
								studyArmList.map((studyArm, index) => {
									return getCohortCriteria(studyArm.cohort_id);
								});

							PromiseArrCohort &&
								PromiseArrCohort.splice(
									0,
									0,

									getCohortCriteria(studyDetail.base_cohort)
								);

							PromiseArrCohort &&
								Promise.all(PromiseArrCohort).then((cohortCriterion) => {
									try {
										cohortCriterion &&
											cohortCriterion.map((criterion, index) => {
												const cohortIndex = index;
												let conceptIds = [];
												let eventIds = [];
												criterion?.data &&
													criterion?.data.data &&
													criterion?.data.data.map((criteria) => {
														if (criteria.criteria_type == 'EventId') {
															eventIds.push(criteria.event_id);
														} else {
															conceptIds.push(criteria.concept_id);
														}
													});

												const studyCohorts = [];

												searchConceptsById(
													conceptIds,
													eventIds.join(',')
												).then((criteriaConcepts) => {
													criterion?.data.data.map((criteria, index) => {
														const cohortObj = cohortList.find(
															(cohort, index) => {
																return (
																	cohort.cohort_id ==
																	criteria.cohort_id
																);
															}
														);
														let associatedConcept;
														if (criteria.criteria_type == 'EventId') {
															associatedConcept =
																criteriaConcepts.filter(
																	(items) =>
																		items.event_id ==
																		criteria.event_id
																)[0];
														} else {
															associatedConcept =
																criteriaConcepts.filter(
																	(items) =>
																		items.cuid ==
																		criteria.concept_id
																)[0];
														}
														studyCohorts.push({
															...criteria,
															cohort: cohortObj,
															concepts: associatedConcept,
														});
													});
													// WHEN INDEX EVENT IS ABSENT COHORT CONSIDERED AS "ALL", HENCE "STUDY ALL"
													if (!criterion?.data.data.length) {
														if (cohortIndex != 0) {
															const cohort = cohortList.find(
																(coh) =>
																	coh.cohort_id ==
																	studyArmList[cohortIndex - 1]
																		.cohort_id
															);
															// const cohort = cohortList.data.data.find(coh => coh.cohort_id === parseInt(studyArmList[0].cohort_id, 10));
															studyCohorts.push({
																cohort,
																concepts: [],
															});
														} else {
															const cohort = cohortList.find(
																(coh) =>
																	coh.cohort_id ===
																	studyDetail.base_cohort
															);
															// const cohort = cohortList.data.data.find(coh => coh.cohort_id === parseInt(studyArmList[0].cohort_id, 10));
															studyCohorts.push({
																cohort,
																concepts: [],
															});
														}
													}
													saveStudyCohorts(studyCohorts, cohortIndex);
												});
											});
									} catch (err) {
										console.log(err);
									}
								});
						});
					});
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	const addStudyArm = () => {
		
		let _studyCohort = Object.assign([], studyCohort);
		if (_studyCohort.length < props?.cohort?.cohortList.length) {
			_studyCohort.push({
				value: '',
			});
			setStudyCohort(_studyCohort);
		}
	};

	const addMeasureArm = () => {
		
		let _studyMeasures = Object.assign([], studyMeasures);
		if (studyMeasures.length < props?.cohort?.events.length) {
			_studyMeasures.push({
				title: 'Measure #',
				value: '',
			});
			setStudyMeasures(_studyMeasures);
		}
	};

	const onFieldChange = (event) => {
		setStudyName(event.target.value);
	};

	const onCohortChange = (event, index) => {
		
		// let { studyId } = props.match.params;

		let dataObj = [];
		let cohort = props?.cohort?.cohortList.find((cohort, index) => {
			return cohort.cohort_id == event.value;
		});
		if (studyId) {
			getCohortCriteria(event.value).then((criterian) => {
				if (criterian.data.data.length) {
					let criteria = criterian.data.data;
					let conceptIds = [];
					let eventIds = [];
					criteria.map((criterion) => {
						if (criterion.criteria_type == 'EventId') {
							eventIds.push(criterion.event_id);
						} else {
							conceptIds.push(criterion.concept_id);
						}
					});

					searchConceptsById(conceptIds, eventIds.join(',')).then(
						(criteriaConcepts) => {
							criteria.map((criterion) => {
								let associatedConcept;
								if (criterion.criteria_type == 'EventId') {
									associatedConcept = criteriaConcepts.filter(
										(items) => items.event_id == criterion.event_id
									)[0];
								} else {
									associatedConcept = criteriaConcepts.filter(
										(items) => items.cuid == criterion.concept_id
									)[0];
								}
								criterion.cohort = cohort;
								criterion.concepts = associatedConcept;
								return criterion;
							});
							studyCohort[index] = [...criteria];
							let _studyCohort = Object.assign([], studyCohort);
							setStudyCohort(_studyCohort);
						}
					);
				} else {
					dataObj.push({ cohort: cohort });
					studyCohort[index] = [...dataObj];
					let _studyCohort = Object.assign([], studyCohort);
					setStudyCohort(_studyCohort);
				}
			});
		} else {
			studyCohort[index] = { ...studyCohort[index], ...event };
			let _studyCohort = Object.assign([], studyCohort);
			setStudyCohort(_studyCohort);
		}
	};

	const onMeasureChange = (event, index) => {
		studyMeasures[index].value = event.value;
		let _studyMeasures = Object.assign([], studyMeasures);
		setStudyMeasures(_studyMeasures);
	};

	const focusPlaceholder = (e) => {
		setPlaceholder('');
	};

	const blurPlaceholder = (e) => {
		setPlaceholder('Enter study name');
	};

	/****************************************
	 * Function Name: _onSubmit
	 * Description: This function works on Click on Submit button.
	 * It send data to Submit Api.
	 * The Method has no signature.
	 ****************************************/
	const _onSubmit = () => {
		let _errors = [];
		if (!studyName?.trim()) {
			_errors['studyName'] = 'Study name is required';
		}

		if ((!studyCohort && !studyCohort.length) || studyCohort[0].value == '') {
			_errors['studyCohort'] = 'Study cohorts is required';
		}

		setErrors(_errors);

		if (Object.keys(_errors).length == 0) {
			let studyArmString = '';
			let eventArmString = '';
			studyCohort &&
				studyCohort.map((_studyCohort, index) => {
					studyArmString += index != 0 ? _studyCohort.value + ',' : '';
				});
			studyMeasures &&
				studyMeasures.map((_studyMeasure) => {
					eventArmString += _studyMeasure.value + ',';
				});
			setIsLoading(true);

			addStudy(studyCohort[0].value, studyName, studyArmString, 'outcome', eventArmString)
				.then((res) => {
					setIsLoading(false);
					goToStudy();
				})
				.catch((res) => {
					setIsLoading(false);
					if (res?.response?.status == 400) {
						let _errors = [];
						_errors['studyName'] = res.response.data.description;
						setErrors(_errors);
					}
				});
		}
	};

	/****************************************
	 * Function Name: updateStudy
	 * Description: This function update the study data
	 * Component to the edit_study page and also update to the Criterion Data
	 * depending on whether the icon clicked is for criterion or date ranges
	 * on click of save icon.
	 * Method has no signature.
	 ****************************************/
	const updateStudy = () => {
		// let { studyId } = props.match.params;
		let _errors = [];

		if (!studyName.trim()) {
			_errors['studyName'] = 'Study name is required';
		}

		if ((!studyCohort && !studyCohort.length) || studyCohort[0].value == '') {
			_errors['studyCohort'] = 'Study cohorts is required';
		}
		setErrors(_errors);

		if (Object.keys(_errors).length == 0) {
			// let { studyId } = props.match.params;

			let studyArmString = '';
			let eventArmString = '';
			let studyCohortId = '';

			studyCohort &&
				studyCohort.map((_studyCohort, index) => {
					studyArmString += index != 0 ? _studyCohort[0].cohort.cohort_id + ',' : '';
				});

			studyCohort &&
				studyCohort.map((_studyCohort, index) => {
					const cohort = _studyCohort[0];
					if (index == 0) {
						studyCohortId += _studyCohort[0].cohort.cohort_id;
					}
				});
			studyMeasures &&
				studyMeasures.map((_studyMeasure) => {
					eventArmString += _studyMeasure.value + ',' || '0';
				});
			setIsLoading(true);

			editStudy(studyId, studyCohortId, studyName, studyArmString, 'outcome', eventArmString)
				.then((res) => {
					setIsLoading(false);
					props.history.push(constants.ROUTE_URL + '/dashboard/studydesign/' + studyId);
				})
				.catch((res) => {
					setIsLoading(false);
					if (res?.response?.status == 400) {
						let _errors = [];
						_errors['studyName'] = res.response.data.description;
						setErrors(_errors);
					}
				});
		}
	};

	/****************************************
	 * Function Name: goToStudy
	 * Description: This function switches the route to the cohort list page
	 * on click of cancel button it switches the user to study list page
	 * Method has no signature.
	 ****************************************/
	const goToStudy = () => {
		clearStudyCohorts();
		props.history.push(constants.ROUTE_URL + '/dashboard/studylist');
	};

	/****************************************
	 * Function Name: goToDetail
	 * Description: This function switches the route to the study list page
	 * on click of save button it switches the user to study list page
	 * Method has no signature.
	 ****************************************/
	const goToDetail = () => {
		// let { studyId } = props.match.params;
		clearStudyCohorts();
		props.history.push(constants.ROUTE_URL + '/dashboard/studydesign/' + studyId);
	};

	/****************************************
	 * Function Name: removeStudyArm
	 * Description: This function removes the last InclusionExclusionCriterion
	 * and an object to the dates array having empty data
	 * depending on whether the icon clicked is for criterion or date ranges
	 * on click of minusicon.
	 * Method has one signature.
	 ****************************************/
	const removeStudyArm = (index) => {
		let _studyCohort = Object.assign([], studyCohort);
		_studyCohort.splice(index, 1);
		setStudyCohort(_studyCohort);
	};

	/****************************************
	 * Function Name: removeMeasureArm
	 * Description: This function removes the last InclusionExclusionCriterion
	 * and an object to the dates array having empty data
	 * depending on whether the icon clicked is for criterion or date ranges
	 * on click of minusicon.
	 * Method has one signature.
	 ****************************************/
	const removeMeasureArm = (index) => {
		let _studyMeasures = Object.assign([], studyMeasures);
		_studyMeasures.splice(index, 1);
		setStudyMeasures(_studyMeasures);
	};

	/*****************************************
	 * Function name: closeModal
	 * Description: This function alters the state of the modal.
	 * Sets the open state of the modal to false to close the modal.
	 * Also adds a css class to it for designing.
	 ****************************************/
	const closeModal = () => {
		setClassName('testClass');
		setOpen(false);
	};

	const removeStudy = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setTimeout(() => setClassName('testClass'), 1000);
	};

	const deleteStudy1 = (_studyId) => {
		setOpen(false);
		setClassName('testClass');

		setIsLoading(true);
		deleteStudy(_studyId).then((res) => {
			setIsLoading(false);
			props.history.push(constants.ROUTE_URL + '/dashboard/studylist');
		});
	};

	return (
		<div>
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span />
				</Loading>
			) : (
				''
			)}
			<HeaderTitles data={_props} search={false} />
			<div className="addstudy">
				<div className="study_name">
					<div className="lbl">
						<label className="lbl_name">Study name:</label>
					</div>

					<div className="study_input">
						<input
							type="text"
							className={'study_txt ' + (errors['studyName'] ? 'error' : '')}
							name="studyName"
							placeholder={placeholder}
							onBlur={blurPlaceholder}
							onFocus={focusPlaceholder}
							value={studyName}
							onChange={onFieldChange}
						/>
						{errors ? (
							<div>
								<span className="help-block error error3">
									{errors['studyName']}
								</span>
							</div>
						) : null}
					</div>
				</div>
				<div className="cohorts_group">
					<div className="lbl">Cohort</div>
					<div className="icons text-right"></div>
				</div>
				{studyCohort &&
					studyCohort.map((_studyCohort, index) => {
						return (
							<Suspense fallback={<div className="defaultSpinner" />}>
								<AddStudyCohorts
									key={index}
									error={errors['studyCohort']}
									index={index}
									study_id={studyId}
									studyCohort={_studyCohort}
									cohortOptions={newCohortOptions}
									title={index == 0 ? 'Base cohort' : 'Study arm'}
									value={_studyCohort.value}
									valueFull={
										_studyCohort.length && _studyCohort.length > 0
											? {
													label: _studyCohort[0].cohort.cohort_name,
													value: _studyCohort[0].cohort.cohort_id,
											  }
											: _studyCohort
									}
									onChange={onCohortChange}
									minusIconClicked={cohortMinusIconClicked}
									removeStudyArm={removeStudyArm}
								/>
							</Suspense>
						);
					})}
				<div className="add-row">
					<img
						src={addPlusIcon}
						onClick={() => addStudyArm()}
						className="App-plusicon hyperlink"
						alt="plusicon"
					/>
				</div>
				<div className="cohorts_group">
					<div className="lbl">Outcome measures</div>
				</div>
				{studyMeasures &&
					studyMeasures.map((measure, index) => {
						return (
							<Suspense fallback={<div className="defaultSpinner" />}>
								<OutcomeMeasures
									key={index}
									index={index}
									onChange={onMeasureChange}
									title={measure.title + (index + 1)}
									value={measure.value}
									eventOptions={eventOptions}
									removeMeasureArm={removeMeasureArm}
								/>
							</Suspense>
						);
					})}
				<div className="add-row">
					<img
						src={addPlusIcon}
						onClick={() => addMeasureArm()}
						className="App-plusicon hyperlink"
						alt="plusicon"
					/>
				</div>
				<div className="editStudy nopadding">
					<div className="col-sm-6 text-right trialButton addNewTrial">
						{studyId ? (
							<button
								type="button"
								id="testid-btn-cancel-1"
								className="trialArm"
								onClick={() => goToDetail(studyId)}
							>
								Cancel
							</button>
						) : (
							<button
								type="button"
								id="testid-btn-cancel-2"
								className="trialArm"
								onClick={() => goToStudy()}
							>
								Cancel
							</button>
						)}
						{studyId ? (
							<button
								type="button"
								id="testid-btn-save-1"
								className="trialArm"
								onClick={() => updateStudy(studyId)}
							>
								Save
							</button>
						) : (
							<button
								type="button"
								id="testid-btn-save-2"
								className="trialArm"
								onClick={(e) => _onSubmit(e)}
							>
								Save
							</button>
						)}
					</div>
					<div className="col-sm-6 text-left trialButton addNewTrial">
						{studyId ? (
							<button
								type="button"
								className="trialArm edit"
								onClick={() => removeStudy(studyId)}
							>
								Delete
							</button>
						) : null}
					</div>
				</div>
			</div>
			<ModalComponent
				open={open}
				content={
					<div className="jconfirm-box-container">
						<div className="jconfirm-content-pane">
							<h4>Are you sure you want to delete?</h4>
						</div>
						<div className="jconfirm-content-pane">
							<div className="jconfirm-buttons">
								<button
									id="testid-btn-cancel-2"
									className="btn btn-default"
									onClick={() => closeModal()}
								>
									Cancel
								</button>
								<button
									id="testid-btn-save-3"
									className="btn btn-default"
									onClick={() => deleteStudy1(studyId)}
								>
									Ok
								</button>
							</div>
						</div>
					</div>
				}
				className="testClass"
				handleClose={handleClose}
			/>
			<Copyright />
		</div>
	);
};

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(AddStudyContainer);
