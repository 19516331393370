import actionTypes from '../actions/actionTypes';

let initialState = {
	loading : false
}

export const loadingReducer = (state = initialState, action) => {

	switch(action.type) {
	
		case actionTypes.IS_LOADING : {
			return {...state, loading: action.payload}
		}

		default : return state;
	}

}