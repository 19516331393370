import actionTypes from "../actions/actionTypes";

const initialState = {
    totalPatients: 0,
}

export const reducerPatient = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_TOTAL_PATIENTS: 
            return {...state, totalPatients: action.payload};
        
        default : {
            return state;
        }
    }
}