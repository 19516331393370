import React, { useState, useEffect } from 'react';
import modalConstants from './modalConstants';
import { getObjectFromLocal } from '../../utilities/utils';
import actions from '../../actions';
import { ButtonToolbar, DropdownButton, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import MessagePopup from '../../components/MessagePopup/MessagePopup';
import { withRouter } from 'react-router-dom';
import constants from '../../constants';
import PropTypes from 'prop-types';
import CancelRapidManual from '../../components/CancelRapidManual/CancelRapidManual';
import { getSystemUserDetails, getUserPermissions } from './SettingMenuListHelper';
import UserSetting from '../../images/UserSetting.svg';

let messagePopup = {};
// const propTypes = {
// 	// userRoles: PropTypes.any,
// 	usermanagement: PropTypes.shape({
// 		loggedInUser: PropTypes.shape({
// 			email: PropTypes.any,
// 		}),
// 		userPermissionList: PropTypes.any,
// 	}),
// 	handleSelect: PropTypes.func,
// 	dispatch: PropTypes.func,
// 	history: PropTypes.shape({
// 		push: PropTypes.func,
// 	}),
// };

function SettingMenuList(props) {
	const [authorizedUser, setAuthorizedUser] = useState(false);
	const [modalType, setModalType] = useState('');
	const [confirmPopup, setConfirmPopup] = useState({});

	let tempNew = window.localStorage.getItem('newRapidAnnotation');
	let newAnnotation = tempNew ? JSON.parse(tempNew) : '';
	let tempUpdated = window.localStorage.getItem('updatedAnnotation');
	let updatedAnnotation = tempUpdated ? JSON.parse(tempUpdated) : '';
	let tempDeleted = window.localStorage.getItem('deletedAnnotation');
	let deletedAnnotation = tempDeleted ? JSON.parse(tempDeleted) : '';

	useEffect(() => {
		// console.log('getSystemUserDetails', props.usermanagement);
		// if(!props.usermanagement.loggedInUser){
		getSystemUserDetails();
		// }
	}, []);

	useEffect(() => {
		if (props.usermanagement?.loggedInUser?.email) {
			let profileUser = getObjectFromLocal('user');

			if (profileUser) {
				let permissionUserId;
				if (props.usermanagement?.loggedInUser?.email == profileUser.email) {
					permissionUserId = props.usermanagement.loggedInUser.user_id;
				}
				// Dialog should be visible only when the access type is an administrator or company.

				getUserPermissions(permissionUserId);
			}
		}
	}, [props.usermanagement?.loggedInUser]);

	useEffect(() => {
		if (props.usermanagement?.userPermissionList?.length > 0) {
			userManagement();
		}
	}, [props.usermanagement?.userPermissionList]);

	//Description: It checks user role and corresponding permissions to access menu.
	const userManagement = () => {
		if (
			props.usermanagement?.userPermissionList?.[0].access == 'Administrator' ||
			props.usermanagement?.userPermissionList?.[0].access == 'Company'
		) {
			setAuthorizedUser(true); //SET USER TYPE AS AUTHORIZED USER
		}
		// let profileUser = getObjectFromLocal('user');

		// if (props.usermanagement?.loggedInUser?.email) {
		// 	let { userPermissionList } = props.usermanagement;
		// 	if (
		// 		userPermissionList?.[0].access == 'Administrator' ||
		// 		userPermissionList?.[0].access == 'Company'
		// 	) {
		// 		setAuthorizedUser(true); //SET USER TYPE AS AUTHORIZED USER
		// 	}
		// } else {
		// 	getSystemUserDetails().then((res) => {
		// 		try {
		// 			let permissionUserId;
		// 			if (res.data.email == profileUser.email) {
		// 				permissionUserId = res.data.user_id;
		// 			}
		// 			// Dialog should be visible only when the access type is an administrator or company.
		// 			getUserPermissions(permissionUserId).then((res) => {
		// 				if (
		// 					res.data.data[0] &&
		// 					(res.data.data[0].access == 'Administrator' ||
		// 						res.data.data[0].access == 'Company')
		// 				) {
		// 					setAuthorizedUser(true); //SET USER TYPE AS AUTHORIZED USER
		// 				}
		// 			});
		// 		} catch (err) {
		// 			console.log(err);
		// 		}
		// 	});
		// }
	};

	const handleOptions = (type) => {
		if (sessionStorage.getItem('PreviewText')) {
			if (messagePopup?.handleClick) {
				messagePopup.handleClick();
			}
		} else {
			props.handleSelect(type);
		}
	};
	const handleSelect = (type) => {
		if (newAnnotation || updatedAnnotation || deletedAnnotation) {
			if (confirmPopup?.handleClick) {
				confirmPopup.handleClick();
			}
		} else {
			handleOptions(type);
		}
	};

	const redirectToPage = () => {
		if (props.handleSelect) {
			sessionStorage.removeItem('patientpreviewdata');
			let goBackUrl = sessionStorage.getItem('EndPreviewUrl');
			if (goBackUrl) {
				props.history.push(constants.ROUTE_URL + goBackUrl);
			}
		}
	};
	const cancelUpdate = () => {
		window.localStorage.removeItem('newRapidAnnotation');
		window.localStorage.removeItem('updatedAnnotation');
		window.localStorage.removeItem('deletedAnnotation');
		window.localStorage.removeItem('selectedConcept');
		window.localStorage.removeItem('editmodeNormal');
		window.localStorage.removeItem('fromEditMultipleManual');
		props.reloadPageData(true);
		if (props.handleSelect) {
			props.handleSelect(modalType);
		}
	};

	return (
		<div className="submenu settingmenulist">
			<ButtonToolbar>
				<DropdownButton
					title={<img className="icon-setting" src={UserSetting} />}
					alignRight
					id="dropdown-no-caret"
					onSelect={(modalType) => {
						setModalType(modalType);
						handleSelect(modalType);
					}}
				>
					{authorizedUser && (
						<Dropdown.Item eventKey={modalConstants.productSetting}>
							Product settings
						</Dropdown.Item>
					)}

					<Dropdown.Item eventKey={modalConstants.accountSetting}>
						Account settings
					</Dropdown.Item>

					{authorizedUser && (
						<Dropdown.Item eventKey={modalConstants.dataSetting}>
							Data administration
						</Dropdown.Item>
					)}
					<Dropdown.Divider />
					{props.sidenav?.userRoles?.length > 1 && (
						<Dropdown.Item eventKey={modalConstants.userRoles}>
							Switch role
						</Dropdown.Item>
					)}
					<Dropdown.Item eventKey={modalConstants.logout}>Logout</Dropdown.Item>
				</DropdownButton>
			</ButtonToolbar>
			<MessagePopup
				rootClosexit={() => {}}
				open={(c) => (messagePopup = c)}
				text={
					'If you exit preview, your changes will be lost. Are you sure you want to exit preview?'
				}
				confirmClick={redirectToPage}
			/>
			<CancelRapidManual
				cancelRapidUpdate={cancelUpdate}
				open={(c) => {
					if (c?.handleClick) {
						setConfirmPopup({ handleClick: c?.handleClick });
					}
				}}
				revertRapidChanges={() => {}}
			/>
		</div>
	);
}

// SettingMenuList.propTypes = propTypes;

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(SettingMenuList));
