import React from 'react';
import constants from '../../constants';
import ErrorBoundary from '../ErrorBoundary';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import { format } from 'date-fns';
import Copyright from '../Copyright/Copyright';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import dataIcon from '../../images/data_icon.png';

const headerData = {
	title: 'Data',
	list: 'List',
	img: (
		<div className="data_icon">
			<img
				src={dataIcon}
				className={'App-DataListImage'}
				alt={'Data List'}
				style={{ width: '38px' }}
			/>
		</div>
	),
};

export function DemographicDetail(props) {
	let patientInfo = JSON.parse(sessionStorage.getItem('patientInfo'));
	let DOB = patientInfo.date_of_birth;
	let gender = patientInfo.gender;
	let originalDate = DOB && DOB.split('/').reverse().join(',');
	let date_of_birth = DOB && format(new Date(originalDate), 'LLLL d, yyyy');
	return (
		<div>
			<div>
				<ErrorBoundary>
					<HeaderTitles data={headerData} history={props.history} match={props.match} />
				</ErrorBoundary>
				<ErrorBoundary>
					<div className="patientNameTitle col-sm-12">
						<h4>
							{' '}
							Structured:
							<a className="hyperlink">
								{' Demographics list on '}
								{patientInfo.demographic_date}
							</a>
						</h4>
					</div>
				</ErrorBoundary>
				<div className="sub-header">
					<div className="first">Demographics</div>
				</div>
				<div style={{ margin: '21px 25px' }}>
					<div>
						<span>Date of birth: </span>
						<span>{date_of_birth}</span>
					</div>
					<div>
						<span>Gender: </span>
						<span>{gender}</span>
					</div>
				</div>
			</div>
			<Copyright />
		</div>
	);
}
const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(DemographicDetail));
