import actionTypes from './actionTypes';
import { apiGet } from '../utilities/utils';

/**************************************** 
     * Function Name: getRapidManualAnnotationData
     * Description: exports an action to get the rapid manual annotation data data from the backend.
     * The data received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - subdocument_id - used to make the api call based on the subdocument ID.
****************************************/

export function getRapidManualAnnotationData(subdocument_id) {
	return dispatch => new Promise(function(resolve, reject){

		apiGet('GetRapidManualAnnotationList', {
			'm_SubdocumentId' : subdocument_id
		}).then(res => {
			
			dispatch({
				type: actionTypes.GET_RAPID_MANUAL_ANNOTATION,
				payload: res.data.data	
				
			})
			resolve(res.data.data);
		})
		.catch(err => {
			dispatch({
				type: actionTypes.IS_LOADING,
				payload: false
			})
			console.log(err);
			reject(err);
		});
	});
}

/****************************************
     * Function Name: addRapidManualAnnotation
     * Description: exports an action to add rapid manual annotation List from backend
     * Method has seven signature.
     * to be fetched from the backend.
****************************************/
export function addRapidManualAnnotation(concept_id, document_id, is_present, is_current, is_definitive, is_experienced, numeric_result, document_concept_id) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('AddManualAnnotation', {
			'm_ManualAnnotationObject.concept_id' : concept_id,
			'm_ManualAnnotationObject.document_id' : document_id,
			'm_ManualAnnotationObject.is_present' : is_present,
			'm_ManualAnnotationObject.is_current' : is_current,
			'm_ManualAnnotationObject.is_definitive' : is_definitive,
			'm_ManualAnnotationObject.is_experienced' : is_experienced,
			'm_ManualAnnotationObject.numeric_result' : numeric_result,	
			'm_ManualAnnotationDocConceptObject.document_concept_id' : document_concept_id
				
		}).then(res => {

			resolve(res);
		})
		.catch(err => {
			reject(err);
		});
	});
}

/**************************************** 
     * Function Name: deleteRapidManualAnnotation
     * Description: exports an action to delete a particular rapid concept name
     * from the backend.
     * The response received is returned via promise which can be used
     * for manipulation. 
     * Method has one signature.
     * @param - annotation_id - used to make the api call based on the annotation id.
****************************************/

export function deleteRapidManualAnnotation(annotation_id, concept_id, document_id) {
	return dispatch => new Promise(function(resolve, reject){
		apiGet('DeleteManualAnnotations', {
			'm_ManualAnnotationObject.annotation_id' : annotation_id,
			'm_ManualAnnotationObject.concept_id' : concept_id,
			'm_ManualAnnotationObject.subdocument_id' : document_id
		}).then(res => {
			//console.log(res);
			resolve(res);
		})
		.catch(err => {
			console.log(err);
			reject(err);
		})
	})
}

