import actionTypes from '../actions/actionTypes';

let initialState = {
	path: [],
	organizations: [],
	origins: [],
	fileFormats: [],
	fetching: false,
	patientDocuments:[],
	batchList:[]
	
};

//reducer that matches the action type for history actions and changes the state
export const dataManagementReducer = (state = initialState, action ) => {
	
	switch(action.type) {

		case actionTypes.SET_PATH_LIST : {
			return {...state, path: action.payload}
		}

		case actionTypes.SET_UPLOAD_ORGANIZATION: {
			return {...state, organizations: action.payload}
		}

		case actionTypes.SET_UPLOAD_ORIGIN: {
			return {...state, origins: action.payload}
		}

		case actionTypes.SET_FILE_FORMAT: {
			return {...state, fileFormats: action.payload}
		}

		case actionTypes.START_DATA_UPLOAD : {
			return {...state, fetching: true}
		}

		case actionTypes.END_DATA_UPLOAD : {
			return {...state, fetching: false}
		}
		case actionTypes.GET_BATCH_ORGANIZATION : {
			return {...state, batchOrganization:action.payload}
		}
		case actionTypes.GET_DOCUMENTS : {
			return {...state, patientDocuments: action.payload}
		}
		case actionTypes.SET_BATCH_LIST : {
			return {...state, batchList:action.payload}
		}

		default : {
			return state;
		}
	}
}