import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import constants from '../../../constants';
import HeaderTitles from '../../../common/HeaderTitles/HeaderTitles';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import actions from '../../../actions';
import {
	setQueryParams,
	setObjectToLocal,
	convertToPlural,
	rearrangeConceptCategories,
} from '../../../utilities/utils';
import SaveConfirm from '../../../components/SaveConfirm/SaveConfirm';
import MessagePopup from '../../../components/MessagePopup/MessagePopup';
import CancelRapidManual from '../../../components/CancelRapidManual/CancelRapidManual';
import ErrorBoundary from '../../../components/ErrorBoundary';
import Copyright from '../../../components/Copyright/Copyright';
import {
	getSubdocumentById,
	getConceptCategories,
	getUserManualAnnotations,
	searchConceptsById,
	deleteManualAnnotation,
	AddManualAnnotationsInBulk,
} from './EditDataManualHelper';
import { setIsLoading } from '../../../actions/common';

const CancelManual = lazy(() =>
	import(/* webpackChunkName: "CancelManual" */ '../../../components/CancelManual/CancelManual')
);
import dataIcon from '../../../images/data_icon.png';
import removeMinusIcon from '../../../images/remove_minus_icon.svg';
import addPlusIcon from '../../../images/add_plus_icon.svg';

const headerData = {
	title: 'Data',
	list: 'detail',
	searchTitle: 'document',
	img: (
		<img
			src={dataIcon}
			className={'App-DataListImage'}
			alt={'Data List'}
			style={{ width: '38px' }}
		/>
	),
};

const EditDataManual = (props) => {
	const [doc_name, setDoc_name] = useState('');
	const [saveRapidPopupMessage, setSaveRapidPopupMessage] = useState('');
	const [togglePopupMessage, setTogglePopupMessage] = useState('');
	const [content_type, setContent_type] = useState('');
	const [distinctCategory, setDistinctCategory] = useState('');
	const [initialConcepts, setInitialConcepts] = useState([]);
	const [manualAnnotationList, setManualAnnotationList] = useState([]);
	const [originalManualAnnotationList, setOriginalManualAnnotationList] = useState([]);
	const [allConcepts, setAllConcepts] = useState([]);
	let { loading } = props.loading;
	const messagePopup = useRef();
	const confirmDocumentPopup = useRef();
	const confirmInfoPopup = useRef();
	const confirmSavePopup = useRef();
	const confirmPopup = useRef();
	let conceptWithError = [];

	window.localStorage.removeItem('editmodeDocument');
	window.localStorage.removeItem('fromEditManual');
	window.localStorage.removeItem('selectedConcept');

	useEffect(() => {
		let { docId, doc_name } = props.match.params;

		setIsLoading(true);
		getSubdocumentById(docId).then((resp) => {
			setDoc_name(resp.data?.data?.name_with_date);
			setContent_type(resp.data?.data?.content_type);
		});

		//Fetch the concept categories from data store if present else fire an API to fetch it
		if (props.historyreducer.conceptCategoryList.length > 0) {
			let categories = rearrangeConceptCategories(props.historyreducer.conceptCategoryList);
			setDistinctCategory(categories);
		} else {
			getConceptCategories().then((resp) => {
				let categories = rearrangeConceptCategories(resp);
				setDistinctCategory(categories);
			});
		}

		let m_annotationList = [];
		let checkAlreadyPresentConcept = '';
		setIsLoading(true);
		getUserManualAnnotations(docId).then((annotation) => {
			setInitialConcepts([...new Set(annotation.map((item) => item.concept_id))]);
			annotation.map((rapid, index) => {
				if (rapid.subject == null) {
					annotation[index].subject = 'Patient';
				}
			});

			let temp = window.localStorage.getItem('newRapidAnnotation');
			let newRapidAnnotationTemp = temp ? JSON.parse(temp) : '';
			if (newRapidAnnotationTemp) {
				newRapidAnnotationTemp.map((items, index) => {
					if (items.concept_category == '') {
						items.concept_category = null;
					}

					let isExist = false;
					// concept already present as document level and we are adding annotaion as document leve
					let conceptAlreadyPresent = annotation.filter(
						(annotation) => annotation.concept_id == items.concept_id
					);
					if (conceptAlreadyPresent.length > 0 && items.annotation_type == 'Document') {
						isExist = true;
						checkAlreadyPresentConcept = items.concept_id;
						newRapidAnnotationTemp.splice(index, 1);
					}

					// concept already present as document level and we are adding annotaion other than document level
					if (conceptAlreadyPresent.length > 0 && items.annotation_type != 'Document') {
						let tempIndex = -1;
						annotation.map((annotation, index) => {
							if (
								annotation.concept_id == items.concept_id &&
								annotation.annotation_type == 'Document'
							) {
								tempIndex = index;
							}
						});
						if (tempIndex != -1) {
							newRapidAnnotationTemp[index].annotation_id =
								annotation[tempIndex].annotation_id;
							annotation.splice(tempIndex, 1);
						}
					}

					// annotation.map((item,index)=>{
					//     if(item.annotation_id == items.annotation_id){
					//         annotation[index] = items;
					//         isExist =true;
					//     }
					// });
					if (!isExist) {
						annotation.push(items);
					}
				});

				if (newRapidAnnotationTemp.length == 0) {
					localStorage.removeItem('newRapidAnnotation');
				} else {
					setObjectToLocal('newRapidAnnotation', newRapidAnnotationTemp);
				}
			}

			let conceptIds = [];
			annotation.map((annotationList) => {
				conceptIds.push(annotationList.concept_id);
			});
			searchConceptsById(conceptIds)
				.then((criteriaConcepts) => {
					annotation.map((annotation) => {
						let concept = criteriaConcepts?.filter(
							(item) => annotation.concept_id == item.cuid
						)[0];
						if (concept) {
							m_annotationList.push({
								...annotation,
								data: concept,
								concept_name: concept?.text,
							});
						}
					});

					let tempDeleted = window.localStorage.getItem('deletedAnnotation');
					let deletedAnnotation = tempDeleted ? JSON.parse(tempDeleted) : [];
					let uniqueArr = [...new Set(deletedAnnotation.map((x) => x.annotation_id))];

					//Sort the annotations in the ascending order of the concept names
					m_annotationList = m_annotationList?.sort(function (a, b) {
						if (a.data.text.toLowerCase() < b.data.text.toLowerCase()) return -1;
						if (a.data.text.toLowerCase() > b.data.text.toLowerCase()) return 1;
						return 0;
					});

					let tempUpdated = window.localStorage.getItem('updatedAnnotation');
					let updatedAnnotation = tempUpdated ? JSON.parse(tempUpdated) : [];
					if (tempUpdated) {
						m_annotationList?.map((items, index) => {
							let updatedValue = updatedAnnotation.filter(
								(updated) => updated.annotation_id == items.annotation_id
							)[0];

							if (updatedValue) {
								m_annotationList[index].is_current = updatedValue.is_current;
								m_annotationList[index].is_definitive = updatedValue.is_definitive;
								m_annotationList[index].is_experienced =
									updatedValue.is_experienced;
								m_annotationList[index].numeric_result =
									updatedValue.numeric_result;
							}
						});
					}

					if (checkAlreadyPresentConcept) {
						let conceptname = m_annotationList.filter(
							(item) => item.concept_id == checkAlreadyPresentConcept
						)[0].concept_name;
						setTogglePopupMessage(
							conceptname +
								' is saved annotation to this document. To assure annotations are maintained, you can change this annotation, but cannot add duplicate at document level.'
						);

						messagePopup.current.handleClick();
					}
					setManualAnnotationList(
						deletedAnnotation
							? m_annotationList.filter((item) => {
									return uniqueArr.indexOf(item.annotation_id) == -1;
							  })
							: m_annotationList
					);
					setOriginalManualAnnotationList(m_annotationList);
					setAllConcepts([...new Set(m_annotationList.map((item) => item.concept_id))]);
				})
				.finally(() => {
					setIsLoading(false);
				});
		});
	}, []);

	const gotoDatadetail = () => {
		let tempNew = window.localStorage.getItem('newRapidAnnotation');
		let newAnnotation = tempNew ? JSON.parse(tempNew) : '';
		let temp = window.localStorage.getItem('deletedAnnotation');
		let deleteAnnotation = temp ? JSON.parse(temp) : '';
		if (newAnnotation || deleteAnnotation) {
			confirmDocumentPopup.current.handleClick();
		} else {
			props.history.push(
				constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId
			);
		}
	};

	const gotoannotation = (items) => {
		setObjectToLocal('fromEditManual', true);
		if (items.is_present) {
			setQueryParams({
				pathname: constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId,
				query: {
					route_annotation_id: items.annotation_id,
				},
			});
		} else {
			setObjectToLocal('editmodeDocument', true);
			setObjectToLocal('selectedConcept', items);
			props.history.push(
				constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId
			);
		}
	};

	const addManualAnnotation = () => {
		setObjectToLocal('editmodeDocument', true);
		setObjectToLocal('fromEditManual', true);
		props.history.push(constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId);
	};

	const removeAnnotaton = (annotation) => {
		let temp = window.localStorage.getItem('deletedAnnotation');
		let deletedAnnotation = temp ? JSON.parse(temp) : [];

		if (annotation.annotation_id > 0) {
			//Delete the manual annotations from the database only if there are more than one
			//annotation of a concept in the document
			let storedAnnotationCount = 0;
			manualAnnotationList.map((currentAnnot, currentAnnotIndex) => {
				if (
					currentAnnot.annotation_id > 0 &&
					currentAnnot.concept_id == annotation.concept_id
				) {
					if (storedAnnotationCount > 0) {
						deletedAnnotation.push(annotation);
						const _manualAnnotationList = manualAnnotationList.filter((item) => {
							return item.annotation_id != annotation.annotation_id;
						});
						setManualAnnotationList(_manualAnnotationList);
					}
					storedAnnotationCount = storedAnnotationCount + 1;
				}
			});

			//Show the popup message if there is only one manual annotation for the delete annotation concept in the database
			if (storedAnnotationCount == 1) {
				//Show popup message
				setTogglePopupMessage(
					annotation.concept_name +
						' is a saved annotation to this document. To assure annotations are maintained, you can change this annotation, but cannot remove it entirely'
				);

				messagePopup.current.handleClick();
			}
		} else {
			deletedAnnotation.push(annotation);

			//Remove the manual annotation from the newRapidAnnotation, if present
			let temp = window.localStorage.getItem('newRapidAnnotation');
			let newRapidAnnotationTemp = temp ? JSON.parse(temp) : '';
			if (newRapidAnnotationTemp) {
				//Store the number of annotations in the temporary annotation list
				newRapidAnnotationTemp = newRapidAnnotationTemp.filter((items) => {
					if (annotation.annotation_type == 'Document') {
						return items.concept_id != annotation.concept_id;
					} else {
						return (
							items.concept_id != annotation.concept_id &&
							items.begin_offset != annotation.begin_offset &&
							items.end_offset != annotation.end_offset
						);
					}
				});
				setObjectToLocal('newRapidAnnotation', newRapidAnnotationTemp);
			}

			let _manualannotationList = manualAnnotationList.filter((item) => {
				if (annotation.annotation_type == 'Document') {
					return item.concept_id != annotation.concept_id;
				} else {
					return (
						item.concept_id != annotation.concept_id &&
						item.begin_offset != annotation.begin_offset &&
						item.end_offset != annotation.end_offset
					);
				}
			});
			setManualAnnotationList(_manualannotationList);
		}

		setObjectToLocal('deletedAnnotation', deletedAnnotation);
	};

	const checkManualAnnotation = () => {
		let tempDeleted = window.localStorage.getItem('deletedAnnotation');
		let deletedAnnotation = tempDeleted ? JSON.parse(tempDeleted) : '';
		let tempNew = window.localStorage.getItem('newRapidAnnotation');
		let newAnnotation = tempNew ? JSON.parse(tempNew) : '';

		let foundNew = false;

		allConcepts.map((newConcepts) => {
			let isFound = initialConcepts.filter((oldConcept) => oldConcept == newConcepts);
			if (isFound.length == 0) {
				foundNew = true;
			}
		});

		if (foundNew) {
			setSaveRapidPopupMessage(
				'Initial annotations will be locked for calculating inter-rater reliability. <br />Any subsequent changes will be considered revisions.'
			);
		} else {
			setSaveRapidPopupMessage('Are you sure you want to save revision in annotations?');
		}
		confirmSavePopup.current.handleClick();
	};

	const getAnnotationObject = (rapid, document_id, isUpdate) => {
		// is_present=false ,is_current=false ,is_definitive=false ,is_experienced=false ,numeric_result ,
		// startpos, endpos,selectedSectionId,annotationType,isUpdate, negation=false, subject='Patient',
		// concept_severity) => {
		// getAnnotationObject = (rapid,document_id, isUpdate)=>{
		// const {annotation_id,concept_id,is_present,is_current} = rapid
		// rapid.annotation_id,
		// rapid.concept_id, docId, rapid.is_present, rapid.is_current, rapid.is_definitive,
		// rapid.is_experienced, rapid.numeric_result || '', rapid.begin_offset || 0, rapid.end_offset || 0,
		// rapid.section_number ? rapid.section_number.toString() : '', rapid.annotation_type, false, rapid.negation, rapid.subject,
		// rapid.concept_severity
		let temp = {
			annotation_id: rapid.annotation_id.toString(),
			concept_id: rapid.concept_id.toString(),
			subdocument_id: document_id.toString(),
			is_present: rapid.is_present ? rapid.is_present.toString() : 'false',
			is_current: rapid.is_current ? rapid.is_current.toString() : 'false',
			is_definitive: rapid.is_definitive ? rapid.is_definitive.toString() : 'false',
			is_experienced: rapid.is_experienced ? rapid.is_experienced.toString() : 'false',
			numeric_result: (rapid.numeric_result || '').toString(),
			startPosition: (rapid.begin_offset || 0).toString(),
			endPosition: (rapid.end_offset || 0).toString(),
			section_number: (rapid.section_number
				? rapid.section_number.toString()
				: ''
			).toString(),
			// negation: rapid.negation ? rapid.negation : null,
			// subject: rapid.subject ? rapid.subject.toString() : '',
			not_definitive_detail: rapid.not_definitive_detail?.toString(),
			not_experienced_reason: rapid.not_experienced_reason?.toString(),
			concept_severity: rapid.concept_severity ? rapid.concept_severity.toString() : '',
			concept_descriptor: rapid.concept_descriptor ? rapid.concept_descriptor.toString() : '',
			concept_progression: rapid.concept_progression
				? rapid.concept_progression.toString()
				: '',

			numeric_result_double: rapid.numeric_result_double?.toString(),
			numeric_value_unit: rapid.numeric_value_unit ? rapid.numeric_value_unit.toString() : '',
			medication_route: rapid.medication_route ? rapid.medication_route.toString() : '',
			medication_form: rapid.medication_form ? rapid.medication_form.toString() : '',
			medication_daily_dose: rapid.medication_daily_dose?.toString(),
			medication_daily_dose_unit: rapid.medication_daily_dose_unit
				? rapid.medication_daily_dose_unit.toString()
				: '',
			medication_strength: rapid.medication_strength?.toString(),
			medication_strength_unit: rapid.medication_strength_unit
				? rapid.medication_strength_unit.toString()
				: '',
			medication_daily_frequency: rapid.medication_daily_frequency?.toString(),
			medication_daily_frequency_unit: rapid.medication_daily_frequency_unit
				? rapid.medication_daily_frequency_unit
				: '',
			observation_date: rapid.observation_date?.toString(),
			concept_category: rapid.concept_category ? rapid.concept_category.toString() : '',
		};
		if (rapid.annotation_type == 'Document') {
			temp.annotation_type = 'Document';
		} else if (rapid.annotation_type == 'Document concept') {
			temp.annotation_type = 'Document concept';
		} else {
			if (isUpdate) {
				temp.annotation_type = 'Text';
			} else {
				temp.annotation_type = 'Text';
			}
		}
		return temp;
	};

	const saveManualAnnotation = () => {
		setIsLoading(true);
		let docId = props.match.params.docId;
		let tempNew = window.localStorage.getItem('newRapidAnnotation');
		let newAnnotation = tempNew ? JSON.parse(tempNew) : '';
		let tempUpdated = window.localStorage.getItem('updatedAnnotation');
		let updatedAnnotation = tempUpdated ? JSON.parse(tempUpdated) : '';
		let tempDeleted = window.localStorage.getItem('deletedAnnotation');
		let deletedAnnotation = tempDeleted ? JSON.parse(tempDeleted) : '';
		conceptWithError = [];
		let promisAddManualAnnotation = [];
		let promiseDeleteManualAnnotation = [];

		let arrManualAnnotation = [];
		if (updatedAnnotation || newAnnotation || deletedAnnotation) {
			if (newAnnotation) {
				newAnnotation.map((rapid, index) => {
					if (rapid.annotation_id.toString().indexOf('temp_') != -1) {
						rapid.annotation_id = 0;
					}
					arrManualAnnotation.push(getAnnotationObject(rapid, docId, false));
				});
			}
			if (updatedAnnotation) {
				updatedAnnotation.map((rapid, index) => {
					arrManualAnnotation.push(getAnnotationObject(rapid, docId, false));
				});
			}
			if (deletedAnnotation) {
				let uniqueDeletedManualAnnotation = [
					...new Set(deletedAnnotation.map((item) => item.annotation_id)),
				];
				uniqueDeletedManualAnnotation.map((annotation_id, index) => {
					if (annotation_id > 0) {
						promiseDeleteManualAnnotation.push(
							deleteManualAnnotation(annotation_id).then((res) => {})
						);
					}
				});
				window.localStorage.removeItem('fromEditMultipleManual');
			}
		}
		if (arrManualAnnotation.length > 0) {
			promisAddManualAnnotation.push(AddManualAnnotationsInBulk(arrManualAnnotation));
		}

		if (promiseDeleteManualAnnotation.length > 0) {
			Promise.all(promiseDeleteManualAnnotation)
				.then((resp) => {})
				.then((resp) => {
					addManualAnnotationBulk(promisAddManualAnnotation);
				});
		} else {
			addManualAnnotationBulk(promisAddManualAnnotation);
		}
	};

	const addManualAnnotationBulk = (promisAddManualAnnotation) => {
		if (promisAddManualAnnotation.length > 0) {
			Promise.all(promisAddManualAnnotation)
				.then((resp) => {
					window.localStorage.removeItem('newRapidAnnotation');
					window.localStorage.removeItem('selectedConcept');
					window.localStorage.removeItem('editmodeNormal');
					window.localStorage.removeItem('deletedAnnotation');
					window.localStorage.removeItem('updatedAnnotation');
					window.localStorage.removeItem('fromEditMultipleManual');
					setIsLoading(false);
					props.history.push(
						constants.ROUTE_URL + '/dashboard/datamanual/' + props.match.params.docId
					);
				})
				.finally((resp) => {
					window.localStorage.removeItem('newRapidAnnotation');
					window.localStorage.removeItem('selectedConcept');
					window.localStorage.removeItem('editmodeNormal');
					window.localStorage.removeItem('deletedAnnotation');
					window.localStorage.removeItem('updatedAnnotation');
					window.localStorage.removeItem('fromEditMultipleManual');
					setIsLoading(false);
					props.history.push(
						constants.ROUTE_URL + '/dashboard/datamanual/' + props.match.params.docId
					);
				})
				.catch((resp) => {
					window.localStorage.removeItem('newRapidAnnotation');
					window.localStorage.removeItem('selectedConcept');
					window.localStorage.removeItem('editmodeNormal');
					window.localStorage.removeItem('deletedAnnotation');
					window.localStorage.removeItem('updatedAnnotation');
					window.localStorage.removeItem('fromEditMultipleManual');
					setIsLoading(false);
					props.history.push(
						constants.ROUTE_URL + '/dashboard/datamanual/' + props.match.params.docId
					);
				});
		} else {
			setIsLoading(false);
			props.history.push(
				constants.ROUTE_URL + '/dashboard/datamanual/' + props.match.params.docId
			);
		}
	};

	const cancelManualAnnotation = () => {
		confirmPopup.current.handleClick();
	};

	const confirmClick = () => {
		window.localStorage.removeItem('newRapidAnnotation');
		window.localStorage.removeItem('deletedAnnotation');
		window.localStorage.removeItem('updatedAnnotation');
		if (window.localStorage.getItem('fromEditMultipleManual')) {
			window.localStorage.removeItem('fromEditMultipleManual');
			props.history.push(
				constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId
			);
		} else {
			props.history.push(
				constants.ROUTE_URL + '/dashboard/datamanual/' + props.match.params.docId
			);
		}
	};

	const cancelDocumentUpdate = () => {
		window.localStorage.removeItem('newRapidAnnotation');
		window.localStorage.removeItem('selectedConcept');
		window.localStorage.removeItem('editmodeNormal');
		window.localStorage.removeItem('deletedAnnotation');
		window.localStorage.removeItem('fromEditMultipleManual');
		props.history.push(
			constants.ROUTE_URL + '/dashboard/document/' + props.match.params.docId
		);
		//getpageData();
	};
	const rootClosexit = () => {};

	return (
		<div className="DataAutomated">
			{loading ? (
				<Loading isLoading={true} loadingClassName="loading">
					<span></span>
				</Loading>
			) : (
				''
			)}

			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					history={props.history}
					match={props.match}
				/>
			</ErrorBoundary>
			<div className="document-header-container">
				<span>Manual annotations: </span>
				<a onClick={gotoDatadetail} id="doc-name-id">
					{doc_name}
				</a>
			</div>

			{distinctCategory &&
				distinctCategory.map((category, index) => {
					return (
						<React.Fragment key={index + index}>
							<div className="section-header">
								{category ? convertToPlural(category) : 'Others'}
							</div>
							<ul>
								{manualAnnotationList
									?.filter((annotation) => {
										return annotation.concept_category == category;
									})
									.map((items) => {
										return (
											<li key={items.annotation_id}>
												<div className="list-item">
													<div className="rmv-icon">
														<img
															src={removeMinusIcon}
															onClick={() => removeAnnotaton(items)}
															className="hyperlink control-button"
															alt="minusicon"
														/>
													</div>
													<div className="annotation-name">
														<a
															className="hyperlink"
															onClick={() => gotoannotation(items)}
														>
															{items.is_present &&
															!items.is_experienced
																? 'No '
																: ''}
															{items.data.text}
														</a>
														({items.data.sab} {items.data.code})
														{!items.is_present ? ' not mentioned' : ''}
													</div>
												</div>
											</li>
										);
									})}
								{
									<li key="conceptadd">
										<div className="list-item">
											<div className="rmv-icon">
												<img
													src={addPlusIcon}
													onClick={addManualAnnotation}
													className="hyperlink control-button"
													alt="plusicon"
												/>
											</div>
										</div>
									</li>
								}
							</ul>
						</React.Fragment>
					);
				})}

			{
				// manualAnnotationList && manualAnnotationList.map((annotation)=>{
				//     return (
				//         <div className="list-item">
				//             <a className="hyperlink">{annotation.data.text}</a> ({annotation.data.sab} {annotation.data.code})
				//         </div>
				//     )
				// })
			}
			<div className="datarapid-buttoncontainer">
				<div className="col-sm-6 text-right trialButton addNewTrial">
					<button
						type="button"
						className="trialArm"
						onClick={cancelManualAnnotation}
						id="id-cancel-annotation"
					>
						Cancel
					</button>
					<button
						type="button"
						className="trialArm"
						onClick={checkManualAnnotation}
						id="id-manual-annotation"
					>
						Save
					</button>
				</div>
			</div>
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<CancelManual
						rootClosexit={rootClosexit}
						open={(c) => (confirmPopup.current = c)}
						//openPopup={openPopup}
						confirmClick={confirmClick}
					/>
				</Suspense>
			</ErrorBoundary>

			<ErrorBoundary>
				<SaveConfirm
					rootClosexit={rootClosexit}
					open={(c) => (confirmSavePopup.current = c)}
					//openPopup={openPopup}s
					text={saveRapidPopupMessage}
					confirmClick={saveManualAnnotation}
				/>
			</ErrorBoundary>

			<ErrorBoundary>
				<SaveConfirm
					rootClosexit={rootClosexit}
					open={(c) => (confirmInfoPopup.current = c)}
					//openPopup={openPopup}s
					text={conceptWithError}
					confirmClick={() => {}}
				/>
			</ErrorBoundary>

			<ErrorBoundary>
				<MessagePopup
					rootClosexit={rootClosexit}
					open={(c) => (messagePopup.current = c)}
					text={togglePopupMessage}
					confirmClick={rootClosexit}
				/>
			</ErrorBoundary>

			<ErrorBoundary>
				<CancelRapidManual
					rootClosexit={() => rootClosexit()}
					cancelRapidUpdate={cancelDocumentUpdate}
					open={(c) => (confirmDocumentPopup.current = c)}
					//openPopup={openPopup}
					revertRapidChanges={cancelDocumentUpdate}
				/>
			</ErrorBoundary>
			<Copyright />
		</div>
	);
};
const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(EditDataManual);
