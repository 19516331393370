import React, { useState, useEffect, lazy, Suspense } from 'react';
import SearchComponent from '../SearchComponent/SearchComponent';
import constants from '../../constants';
import SettingMenuList from '../Header/SettingMenuList';
import { connect } from 'react-redux';
import modalConstants from '../Header/modalConstants';
import HamburgerMenu from '../Header/HamburgerMenu';
import { setQueryParams } from '../../utilities/utils';
import history from '../../history';
import ModalComponent from '../ModalComponent/ModalComponent';
import { getStudyList, openModal, SetUserRole } from './HeaderTitleHelper';
import CustomSelect from '../../common/CustomSelect/CustomSelect';
import queryString from 'query-string';
import PageTitle from '../../components/PageTitle/PageTitle';
import { getIconName } from '../Header/headerFuns';
import { withRouter } from 'react-router-dom';
import ErrorBoundary from '../../components/ErrorBoundary';
import MessagePopup from '../../components/MessagePopup/MessagePopup';
import { roleAccess } from '../../utilities/AppConstants';
import { setIsLoading } from '../../actions/common';
import { getObjectFromLocal } from '../../utilities/utils';

const CancelManual = lazy(() =>
	import(/* webpackChunkName: "CancelManual" */ '../../components/CancelManual/CancelManual')
);
const RapidAnnotation = lazy(() =>
	import(
		/* webpackChunkName: "RapidAnnotation" */ '../../components/RapidAnnotation/RapidAnnotation'
	)
);

function HeaderTitles(props) {
	const [showSearch, setShowSearch] = useState(false);
	const [studySelectPopup, setStudySelectPopup] = useState(false || props.isStudySelectPopup);
	const [selectedStudy, setSelectedStudy] = useState(-1);
	const [showStudyError, setShowStudyError] = useState(false);
	const [roleSelectPopup, setRoleSelectPopup] = useState(false || props.isRoleSelectPopup);
	const [studyOptions, setStudyOption] = useState([]);
	const [selectedRole, setselectedRole] = useState('');
	const [userRoles, setuserRoles] = useState([]);
	const [redirectURL, setRedirectURL] = useState('');
	const [openMessagePopup, setOpenMessagePopup] = useState(false);
	let { title, searchTitle, list } = props.data;
	const path = window.location.pathname;
	const iconName = getIconName(path);

	useEffect(() => {
		let study_id = queryString.parse(window.location.search).study_id;
		if (study_id) {
			setSelectedStudy({
				value: study_id,
				label: '',
			});
		}
		let path = window.location.pathname;

		if (
			sessionStorage.getItem('PreviewText') &&
			path.indexOf('datapreview') == -1 &&
			path.indexOf('conceptsupport') == -1 &&
			path.indexOf('patient/0') == -1
		) {
			openModal('DATA_SETTING');
		}

		if (sessionStorage.getItem('userRole')) {
			let storedRole = sessionStorage.getItem('userRole');
			setselectedRole(storedRole);
		}
	}, []);

	useEffect(() => {
		if (props?.sidenav?.userRoles?.length > 0 && userRoles?.length == 0) {
			setuserRoles(props.sidenav.userRoles);
			if (sessionStorage.getItem('userRole')) {
				let storedRole = sessionStorage.getItem('userRole');
				setselectedRole(storedRole);
			} else {
				setselectedRole(props.sidenav.userRole.Role);
			}
		}
	}, [props.sidenav]);

	/****************************************
	 * Function Name: submenuClick.
	 * Description: Handles click event and expected functionality of submenus.
	 ****************************************/
	const submenuClick = (eventKey) => {
		// console.log(eventKey)
		let study_id = queryString.parse(window.location.search).study_id;
		let tempNew = window.localStorage.getItem('newRapidAnnotation');
		let newAnnotation = tempNew ? JSON.parse(tempNew) : '';
		let tempUpdated = window.localStorage.getItem('updatedAnnotation');
		let updatedAnnotation = tempUpdated ? JSON.parse(tempUpdated) : '';
		let tempDeleted = window.localStorage.getItem('deletedAnnotation');
		let deletedAnnotation = tempDeleted ? JSON.parse(tempDeleted) : '';
		switch (eventKey) {
			case '1':
				if (props.editMode) {
					props.editMode();
				}
				break;
			case '2':
				props.history.push(
					constants.ROUTE_URL + '/dashboard/studydesign/' + props.match.params.studyId
				);
				break;
			case '3':
				props.history.push(
					constants.ROUTE_URL + '/dashboard/studyresult/' + props.match.params.studyId
				);
				break;
			case '4':
				props.history.push(
					constants.ROUTE_URL +
						'/dashboard/editstudyvalidity/' +
						props.match.params.studyId
				);
				break;
			case '5':
				props.history.push(
					constants.ROUTE_URL + '/dashboard/studyedit/' + props.match.params.studyId
				);
				break;
			case '6':
				props.history.push(
					constants.ROUTE_URL +
						'/dashboard/editstudyvalidity/' +
						props.match.params.studyId
				);
				break;
			case '7':
				if (props.addCohort) {
					props.addCohort();
				}
				break;
			case '8':
				props.history.push(
					constants.ROUTE_URL + '/dashboard/cohortdesign/' + props.match.params.cohort_id
				);
				break;
			case '9':
				props.history.push(
					constants.ROUTE_URL + '/dashboard/cohortresult/' + props.match.params.cohort_id
				);
				break;
			case '10':
				props.history.push(
					constants.ROUTE_URL + `/dashboard/cohort/edit/${props.match.params.cohort_id}`
				);
				break;
			case '11':
				if (
					props.sidenav?.userRole?.Role === roleAccess.ANNOTATOR &&
					(newAnnotation || updatedAnnotation || deletedAnnotation)
				) {
					props?.confirmPopup?.handleClick(
						constants.ROUTE_URL + `/dashboard/dataautomated/` + props.match.params.docId
					);
				} else {
					props.history.push(
						constants.ROUTE_URL + '/dashboard/dataautomated/' + props.match.params.docId
					);
				}
				break;
			case '12':
				if (
					props.sidenav?.userRole?.Role === roleAccess.ANNOTATOR &&
					(newAnnotation || updatedAnnotation || deletedAnnotation)
				) {
					props?.confirmPopup?.handleClick(
						constants.ROUTE_URL + `/dashboard/datamanual/` + props.match.params.docId
					);
				} else {
					props.history.push(
						constants.ROUTE_URL + `/dashboard/datamanual/` + props.match.params.docId
					);
				}
				break;
			case '13':
				props.history.push(
					constants.ROUTE_URL + `/dashboard/datacohort/` + props.match.params.docId
				);
				break;
			case '14':
				if (newAnnotation || updatedAnnotation || deletedAnnotation) {
					props?.confirmPopup?.handleClick();
				} else {
					props.history.push(
						constants.ROUTE_URL + `/dashboard/document/` + props.match.params.docId
					);
				}
				break;
			case '15':
				props.history.push(
					constants.ROUTE_URL + `/dashboard/editdatamanual/` + props.match.params.docId
				);
				break;
			// case '16':
			// 	if (newAnnotation || updatedAnnotation || deletedAnnotation) {
			// 		props.confirmPopup.handleClick();
			// 	} else {
			// 		props.history.push(
			// 			constants.ROUTE_URL + '/dashboard/datarapid/' + props.match.params.docId
			// 		);
			// 	}
			// 	break;
			case '17':
				props.history.push(
					constants.ROUTE_URL + `/dashboard/datareview/` + props.match.params.docId
				);
				// props.history.push(constants.ROUTE_URL + `/dashboard/dataplareview/` + props.match.params.docId);
				break;
			case '18':
				props.history.push(
					constants.ROUTE_URL + `/dashboard/dataresolve/` + props.match.params.docId
				);
				break;
			case '19':
				if (selectedStudy == -1) {
					setStudySelectPopup(true);
					getStudyLst();
				} else {
					let initial = queryString.parse(window.location.search).initial;
					let forKappaScore = queryString.parse(window.location.search).forKappaScore;
					let url = window.location.pathname;
					if (url.indexOf('dataplastudyresolve') != -1) {
						props.history.push(
							constants.ROUTE_URL +
								// `/dashboard/datastudyreview/` +
								`/dashboard/dataplastudyreview/` +
								props.match.params.docId +
								'?study_id=' +
								selectedStudy.value +
								'&initial=' +
								initial +
								'&forKappaScore=' +
								forKappaScore
						);
					} else {
						props.history.push(
							constants.ROUTE_URL +
								`/dashboard/datastudyreview/` +
								// `/dashboard/dataplastudyreview/` +
								props.match.params.docId +
								'?study_id=' +
								selectedStudy.value +
								'&initial=' +
								initial +
								'&forKappaScore=' +
								forKappaScore
						);
					}
				}
				break;
			case '20':
				props.history.push(constants.ROUTE_URL + `/dashboard/dataworkqueue`);
				break;
			case '21':
				props.history.push(constants.ROUTE_URL + `/dashboard/datalist`);
				break;
			case '22':
				let study_id = queryString.parse(window.location.search).study_id;
				let concept_id = queryString.parse(window.location.search).concept_id;
				let userIdList = queryString.parse(window.location.search).userIdList;
				let initial = queryString.parse(window.location.search).initial;
				if (concept_id) {
					props.history.push(
						constants.ROUTE_URL +
							`/dashboard/datastudyresolve/` +
							props.match.params.docId +
							'?study_id=' +
							study_id +
							'&concept_id=' +
							concept_id +
							'&userIdList=' +
							userIdList +
							'&initial=' +
							initial
					);
				} else {
					props.history.push(
						constants.ROUTE_URL +
							`/dashboard/datastudyresolve/` +
							props.match.params.docId +
							'?study_id=' +
							study_id
					);
				}

				break;
			case '23':
				props.history.push(constants.ROUTE_URL + `/dashboard/studylist`);
				break;
			case '24':
				props.history.push(constants.ROUTE_URL + `/dashboard/cohortlist`);
				break;
			case '25':
				if (sessionStorage.getItem('PreviewText')) {
					setRedirectURL(constants.ROUTE_URL + `/dashboard/patientlist`);
					setOpenMessagePopup(true);
				} else {
					props.history.push(constants.ROUTE_URL + `/dashboard/patientlist`);
				}
				break;
			case '26':
				if (props?.relatedEncounter?.length > 0 && props.onMenuClick) {
					let organisationHiearchy = props.relatedEncounter[0].organization;
					let patientId = props.relatedEncounter[0].patient_id;
					let subdocumentId = props.relatedEncounter[0].subdocument_id;
					// if(props.relatedEncounter.length==1){
					props.history.push(
						constants.ROUTE_URL +
							'/dashboard/datalist?refid=' +
							organisationHiearchy +
							'&patient_id=' +
							patientId +
							'&subdocument_id=' +
							subdocumentId
					);
					// } else{
					// 	props.onMenuClick();
					// }
				}
				break;
			case '27':
				props.history.push(
					constants.ROUTE_URL +
						`/dashboard/patientlevelannotation/` +
						props.match.params.docId
				);
				break;
			case '28':
				if (props.editMode) {
					props.editMode();
				}
				break;
			case '29':
				props.history.push(
					constants.ROUTE_URL + `/dashboard/dataplareview/` + props.match.params.docId
				);
				break;
			case '30':
				// let study_id = queryString.parse(window.location.search).study_id;
				props.history.push(
					constants.ROUTE_URL +
						`/dashboard/dataplastudyresolve/` +
						props.match.params.docId +
						'?study_id=' +
						selectedStudy.value
				);
				break;
			case '31':
				// let study_id = queryString.parse(window.location.search).study_id;
				props.history.push(
					constants.ROUTE_URL + `/dashboard/dataplaresolve/` + props.match.params.docId
				);
				break;
			case '32':
				if (props.goBack) {
					props.goBack();
				}
				break;
			case '33':
				if (props.toggleMode) {
					props.toggleMode();
				}
				break;
			case '34':
				let patientInfo = JSON.parse(sessionStorage.getItem('patientInfo'));
				props.history.push(
					constants.ROUTE_URL + `/dashboard/patient/` + patientInfo?.patient_id
				);
				break;
			case 'SHOW_SEARCH':
				setShowSearch(true);
				break;
			case modalConstants.dataSetting:
				handleSettingOptionClick(eventKey); //same case used for patient list and data list,
				break;
			case '35':
				props.history.push(
					constants.ROUTE_URL +
						`/dashboard/derivedautomatedannotation/` +
						props.match.params.docId
				);
				break;
			case '36':
				if (getObjectFromLocal('fromEditMultipleManual')) {
					return;
				} else {
					props.switchToEditMultipleManual();
				}
				break;
			default:
				break;
		}
	};

	const confirmClick = () => {
		window.localStorage.removeItem('newRapidAnnotation');
		window.localStorage.removeItem('updatedAnnotation');
		window.localStorage.removeItem('deletedAnnotation');
		window.localStorage.removeItem('fromEditMultipleManual')
		props.history.push(
			constants.ROUTE_URL + '/dashboard/datamanual/' + props.match.params.docId
		);
	};

	const rootClosexit = () => {};

	/** **************************************
	 * Function Name: getStudyList
	 * Description: Fetch list of studies and required counts also save in state
	 * @param query URL parameter queries
	 ****************************************/
	const getStudyLst = () => {
		setIsLoading(true);
		getStudyList().then((res) => {
			let studyOptions = [];
			if (res) {
				res.map((items) => {
					studyOptions.push({ label: items.study_name, value: items.study_id });
				});
			}
			setStudyOption(studyOptions);
			setIsLoading(false);
		});
	};

	/****************************************
	 * Function Name: handleSettingOptionClick.
	 * Description: Set modal type in redux store.
	 * @param - modalType - Type of modal
	 ****************************************/
	const handleSettingOptionClick = (modalType) => {
		if (modalType == 'DATA_ANALYSE') {
			setQueryParams({ pathname: constants.ROUTE_URL + '/dashboard/dataanalyse' });
		} else if (modalType == 'USER_ROLES') {
			setRoleSelectPopup(true);
		} else {
			openModal(modalType);
		}

		if (props.handleSettingOptionClick) {
			props.handleSettingOptionClick();
		}
	};

	const cancelClick = () => {
		setSelectedStudy(-1);
		setStudySelectPopup(false);
		if (sessionStorage.getItem('userRole')) {
			let storedRole = sessionStorage.getItem('userRole');
			setselectedRole(storedRole);
		} else {
			setselectedRole(props.sidenav.userRole.Role);
		}
		setRoleSelectPopup(false);
	};

	const okClick = () => {
		if (selectedStudy != -1) {
			setShowStudyError(false);
			setStudySelectPopup(false);
			if (window.location.pathname.indexOf('datareview') != -1) {
				props.history.push(
					constants.ROUTE_URL +
						`/dashboard/datastudyreview/` +
						props.match.params.docId +
						'?study_id=' +
						selectedStudy.value
				);
			}
			if (window.location.pathname.indexOf('dataplareview') != -1) {
				props.history.push(
					constants.ROUTE_URL +
						`/dashboard/dataplastudyreview/` +
						props.match.params.docId +
						'?study_id=' +
						selectedStudy.value
				);
			}
		} else {
			setShowStudyError(true);
		}
	};

	/****************************************
	 * Function Name: onIsCurrentChange
	 * Description: This function is passed to onChange prop of select input
	 * It is used to set the state of the current of the list input
	 * after a user selects from the options of the select list.
	 * @param - event - an object having the current value selected by the user.
	 ****************************************/
	const onIsStudyChange = (newValue) => {
		if (newValue == null || newValue.length) {
			setSelectedStudy(null);
		} else {
			setSelectedStudy(newValue);
			setShowStudyError(false);
		}
	};

	const switchRole = () => {
		sessionStorage.setItem('userRole', selectedRole);
		SetUserRole(selectedRole);
		setRoleSelectPopup(false);
		props.history.push(constants.ROUTE_URL + '/dashboard/studylist');
	};

	const redirectToPage = () => {
		sessionStorage.setItem('PreviewText', '');
		sessionStorage.removeItem('patientpreviewdata');
		props.history.push(redirectURL);
	};

	return (
		<React.Fragment>
			<div className="headerbar row">
				<div className="col-sm-3 leftPadder">
					<div className={iconName} />
					<PageTitle title={title} subTitle={list} />
				</div>
				<div className="col-sm-7 searchContainer" style={{ paddingRight: 0 }}>
					{props.search && showSearch && (
						<ErrorBoundary>
							<SearchComponent
								title={searchTitle}
								searchFunction={props.searchFunction}
								searchText={props.searchText}
							/>
						</ErrorBoundary>
					)}
				</div>
				<div className="col-sm-2 text-right" style={{ padding: 0 }}>
					<ErrorBoundary>
						<SettingMenuList
							userRoles={userRoles}
							handleSelect={handleSettingOptionClick}
							reloadPageData={props.reloadPageData}
						/>
					</ErrorBoundary>
					{!props.noMenu && props?.sidenav?.userRole && (
						<ErrorBoundary>
							<HamburgerMenu
								docType={props.doc_type}
								noMenu={props.noMenu}
								userRole={props.sidenav?.userRole}
								history={props.history}
								handleMenuClick={submenuClick}
								props={props}
							/>
						</ErrorBoundary>
					)}
					{props.showRapid && props.sidenav?.userRole?.Role === roleAccess.ANNOTATOR && (
						<Suspense fallback={<div className="defaultSpinner" />}>
							<RapidAnnotation
								history={props.history}
								docId={props.match?.params?.docId || ''}
								switchToEditMode={props.switchToEditMode}
								toggleRapidAnnotationPopup={props.toggleRapidAnnotationPopup}
								onToggleRapidAnnotationPopup={props.onToggleRapidAnnotationPopup}
								reloadPageData={props.reloadPageData}
								closeMessageLevelPopup={props.closeMessageLevelPopup}
								showRapidBadgeIcon={props.showRapidBadgeIcon}
								openDocumentLevelPopupRoot={props.openDocumentLevelPopupRoot}
							/>
						</Suspense>
					)}
				</div>
			</div>

			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<CancelManual
						rootClosexit={rootClosexit}
						open={(c) => c}
						confirmClick={confirmClick}
					/>
				</Suspense>
			</ErrorBoundary>

			<ErrorBoundary>
				<ModalComponent
					open={studySelectPopup}
					backdrop={true}
					content={
						<div className="jconfirm-box-container select-study-popup">
							<div className="jconfirm-content-pane">
								<div style={{ fontSize: 18 }}>Please select a study.</div>
							</div>
							<CustomSelect
								className="uniqueTriaArm custom editFont"
								placeholder={'Select a study'}
								onChange={onIsStudyChange}
								clearable={false}
								value={selectedStudy}
								options={studyOptions}
								//options={[{ label: "True", value: true }]}
							/>
							{showStudyError && (
								<div
									style={{ width: '100%', display: 'inline-bLock' }}
									className="help-block error error3"
								>
									Please select a study
								</div>
							)}
							<div className="jconfirm-content-pane">
								<div className="jconfirm-buttons">
									<button
										type="button"
										className="btn btn-default"
										onClick={(event) => cancelClick(event)}
									>
										Cancel
									</button>
									<button
										type="button"
										className="btn btn-default"
										onClick={() => okClick()}
									>
										Ok
									</button>
								</div>
							</div>
						</div>
					}
					className={''}
					handleClose={props.handleClose}
				/>
				<ModalComponent
					open={roleSelectPopup}
					backdrop={true}
					content={
						<div className="jconfirm-box-container select-study-popup">
							<div className="jconfirm-content-pane">
								<div style={{ fontSize: 14 }}>
									Please select the role you want to switch
								</div>
							</div>
							<div className="jconfirm-content-pane">
								{userRoles?.length > 0 && (
									<ul className="role-container">
										{userRoles.map((items, index) => {
											return (
												<li key={index} className="role-list">
													<span
														style={{ marginRight: 10 }}
														onClick={() => setselectedRole(items.Role)}
														className={
															selectedRole == items.Role
																? 'icon_radio_checked'
																: 'icon_radio_unchecked'
														}
													/>
													{items.Role}
												</li>
											);
										})}
									</ul>
								)}
							</div>
							<div className="jconfirm-content-pane">
								<div className="jconfirm-buttons">
									<button
										type="button"
										className="btn btn-default"
										onClick={(event) => cancelClick(event)}
									>
										Cancel
									</button>
									<button
										type="button"
										className="btn btn-default"
										onClick={() => switchRole()}
									>
										Ok
									</button>
								</div>
							</div>
						</div>
					}
					className={''}
					handleClose={props.handleClose}
				/>
			</ErrorBoundary>
			{openMessagePopup && (
				<MessagePopup
					rootClosexit={() => {}}
					forceOpen={true}
					text={
						'If you exit preview, your changes will be lost. Are you sure you want to exit preview?'
					}
					confirmClick={redirectToPage}
				/>
			)}
		</React.Fragment>
	);
}

const mapStateToProps = (state) => state;

export default withRouter(connect(mapStateToProps)(HeaderTitles));
