import React, { useState, useEffect } from 'react';
import HeaderTitles from '../../common/HeaderTitles/HeaderTitles';
import constants from '../../constants';
import PanelHeading from '../../components/PanelHeading/PanelHeading';
import store from '../../store';
import actions from '../../actions';
import { setFilterLabel, setHistory } from '../../common/FilterFunctions/FilterFunctions';
import CohortResultTable from '../../components/CohortResultTable/CohortResultTable';
import { apiGet, setQueryParams } from '../../utilities/utils';
import { roleAccess } from '../../utilities/AppConstants';
import { connect } from 'react-redux';
import ErrorBoundary from '../../components/ErrorBoundary';
import Copyright from '../../components/Copyright/Copyright';
import {
	getCohortPatient,
	getCohortById,
	GetCohortOutcomesPatientCounts,
} from './CohortResultHelper';
import cohortIcon from '../../images/cohort_icon.png';
const headerData = {
	title: 'Cohort',
	list: 'detail',
	img: (
		<img
			src={cohortIcon}
			className={'App-CohortList'}
			alt={'Cohort List'}
		/>
	),
};

function CohortResult(props) {
	const [cohort, setCohort] = useState({ patientCount: '' });
	const [showLoading, setShowLoading] = useState(false);

	useEffect(() => {
		const cohortId = parseInt(props.match.params.cohort_id, 10);
		getCohortData(cohortId);
		// getCohortPatients(cohortId);
	}, []);

	const getCohortData = (cohortId) => {
		setShowLoading(true);
		getCohortById(cohortId).then((cohortRes) => {
			// setCohort({ ...cohort, ...cohortRes });
			getCohortPatient(cohortId).then((res) => {
				const _cohort = {...cohort, ...cohortRes}
				// const _cohort = { ...cohort, ...cohortId }; // cohortId to sent to patient list
				_cohort.patientCount = res.length;
	
				GetCohortOutcomesPatientCounts(cohortId)
					.then((response) => {
						_cohort.outcomeEvents = response;
						setCohort(_cohort);
						setShowLoading(false);
					})
					.catch((err) => {
						setShowLoading(false);
					});
			});
		});
	};

	// const getCohortPatients = (cohortId) => {
	// 	// store.dispatch(actions.getCohortPatient(cohortId))
	// 	getCohortPatient(cohortId).then((res) => {

	// 		const _cohort = { ...cohort, ...cohortId }; // cohortId to sent to patient list
	// 		_cohort.patientCount = res.length;

	// 		GetCohortOutcomesPatientCounts(cohortId)
	// 			.then((response) => {
	// 				_cohort.outcomeEvents = response;
	// 				setCohort(_cohort);
	// 				setShowLoading(false);
	// 			})
	// 			.catch((err) => {
	// 				setShowLoading(false);
	// 			});
	// 	});
	// };

	const gotoPatientList = (cohort_id, cohortName) => {
		let filterLabel = {
			page: 'patient',
			options: [
				{
					filterLabel: 'Cohort',
					name: cohortName,
				},
			],
		};
		setFilterLabel(filterLabel);
		setHistory(false, cohortName);

		if (props.sidenav.userRole.Role == roleAccess.ANNOTATOR) {
			setQueryParams({
				pathname: constants.ROUTE_URL + '/dashboard/datalist',
				query: { cohort_id },
			});
		} else {
			setQueryParams({
				pathname: constants.ROUTE_URL + '/dashboard/patientlist',
				query: { cohort_id },
			});
		}
	};

	return (
		<div>
			<ErrorBoundary>
				<HeaderTitles
					data={headerData}
					search={false}
					cohortId={cohort.cohort_id}
					{...props}
				/>
			</ErrorBoundary>
			<div style={{ position: 'relative' }}>
				{showLoading && <div className="v-loading-spinner" />}
				<div>
					<h3 className="v-cohort-name">Results: {cohort.cohort_name}</h3>
				</div>
				<PanelHeading heading="Results" />
				<div className="study-result-container">
					<ErrorBoundary>
						<CohortResultTable
							handlePatientClick={() =>
								gotoPatientList(cohort.cohort_id, cohort.cohort_name)
							}
							cohort={cohort}
						/>
					</ErrorBoundary>
				</div>
			</div>
			<Copyright />
		</div>
	);
}

export default connect((state) => state)(CohortResult);
