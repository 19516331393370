import React from 'react';
import PropTypes from 'prop-types';

const CountLinkLoader = ({count, handleClick,noLink}) => {
    return  (
         count === '' || count === undefined ? 
        <div className="v-link-loader">
            <div>•</div>
            <div>•</div>
            <div>•</div>
        </div>
        : count == 0 
            ? <span style={{ color: '#c0c0c0' }}>0</span>
            : !noLink ?
              <span className="hyperlink" onClick={handleClick}>{ count }</span>
              : <span style={{ color: '#c0c0c0' }}>{count}</span>
    )
}

CountLinkLoader.propTypes = {
    count: PropTypes.any,
    handleClick: PropTypes.func.isRequired
}

export default CountLinkLoader;
