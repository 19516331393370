import { apiPost, apiGet, apiPostForm } from '../utilities/utils';
import actionTypes from './actionTypes';
import { setObjectToLocal } from '../utilities/utils';

/****************************************
     * Function Name: login
     * Description: exports an action which makes api call to authenticate a user.
     * The data received gets set in the reducer which can be used
     * later for manipulation.
     * Method has one signature.
     * @param - user - a user object that contains the credentials entered by a user.
****************************************/

export function login(user){
   return dispatch => {
	apiPostForm('Login', { 'email':user.email , 'password' : user.password}).then(res => {
    	dispatch({
    		type: actionTypes.AUTH_LOGIN_SUCCESS,
    		payload: true
    	});
			setObjectToLocal('authSecret', {secret: res.data.authSecret}),
            setObjectToLocal('user', {email: user.email}),
            sessionStorage.setItem('userSession','Y')
    })
    .catch(err => {
    	dispatch({
    		type: actionTypes.AUTH_LOGIN_ERROR,
    		payload: err.response
    	});
    })

}
}
/****************************************
     * Function Name: Auth0Login
     * Description: exports an action which makes api call to authenticate a user using Auth0.
     * The data received gets set in the reducer which can be used
     * later for manipulation.
     * Method has one signature.
     * @param - email - The email id of the user.
****************************************/
export function Auth0Login(email){
	return dispatch => {
        apiPostForm('Login', { 'email':email }).then(res => {
           dispatch({
                type: actionTypes.AUTH_LOGIN_SUCCESS,
                payload: true
            });
                setObjectToLocal('authSecret', {secret: res.data.authSecret}),
                setObjectToLocal('user', {email: email}),
                sessionStorage.setItem('userSession','Y')
        })
        .catch(err => {
            dispatch({
                type: actionTypes.AUTH_LOGIN_ERROR,
                payload: err.response
            });
        })
    }
}
/****************************************
     * Function Name: step2Verify
     * Description: exports an action that sets the step2 verification as success.
     * Method has no signature.
****************************************/

export function step2Verify(){
    return dispatch => {
        dispatch({
            type: actionTypes.STEP2_VERIFICATION_SUCCESS,
            payload: true
        });
    }
}

/****************************************
     * Function Name: verifyAuthenticationCode
     * Description: exports an action that makes api call to the backend
     * and verify the otp code received by the user.
     * Method has two signature.
     * @param - m_AuthenticationSecret - The authentication secret key.
     * @param - code - The code received from otp by the user.
****************************************/
export function verifyAuthenticationCode(m_AuthenticationSecret,code){
    return dispatch => new Promise((resolve, reject) => {
	apiPostForm('VerifyAuthenticationCode',
	{
        'authenticationSecret':m_AuthenticationSecret,
        'authenticationCode':code
    }
	).then(res => {
    	resolve(res);
    }, error => {
        reject(error)
    })
		.catch(err => {
    	dispatch({
    		type: actionTypes.VERIFY_AUTHENTICATION_ERROR,
    		payload: err.response
    	});
    })
})
}
/****************************************
     * Function Name: Auth0VerifyAuthenticationCode
     * Description: exports an action that makes api call to verify the otp code received by the user.
     * Method has three signature.
     * @param - m_AuthenticationSecret - The authentication secret key.
     * @param - code - The code received from otp by the user.
     * @param - email - The email id of the user.
****************************************/
export function Auth0VerifyAuthenticationCode(m_AuthenticationSecret,code,email){
    return dispatch => new Promise((resolve, reject) => {
    apiPostForm('VerifyAuthenticationCode',
	{
        'authenticationSecret':m_AuthenticationSecret,
        'authenticationCode':code
    }
	).then(res => {
    	resolve(res);
    }).then(error => {
        reject(error)
    }).catch(err => {
        reject(err)
    	dispatch({
    		type: actionTypes.VERIFY_AUTHENTICATION_ERROR,
    		payload: err.response
    	});
    })
})
}
/****************************************
     * Function Name: Logout
     * Description: exports an action that makes api call to the backend
     * to logout a user.
     * Method has no signature.
****************************************/
export function Logout() {
    return dispatch => new Promise((resolve, reject) => {
        apiPost('Logout', '').then(res => {
            dispatch({
                type: actionTypes.RESET_STATE,
                payload: null
            });
            dispatch({
				type: actionTypes.GET_USER_ROLE,
				payload: ''
			})
            resolve(res);
            sessionStorage.clear();
        })
        .catch(err => {
            reject(err);
        });
    })
}