import React, { useEffect, lazy, Suspense } from 'react';
import actions from '../../actions';
import { connect } from 'react-redux';
import Loading from 'react-loading-spinner';
import constants from '../../constants';
import ErrorBoundary from '../../components/ErrorBoundary';
import Copyright from '../../components/Copyright/Copyright';
import {
	getLexiconCount,
	getTemporalOrderingCount,
	getGrammarCount,
	getNegationsCount,
	getConceptAssociationsCount,
	getNECount,
	getConceptSupportCount,
	setHistory,
} from './TechnologyHelper';

const TechnologyList = lazy(() =>
	import(
		/* webpackChunkName: "TechnologyList" */ '../../components/TechnologyList/TechnologyList'
	)
);

function Technology(props) {
	useEffect(() => {
		//dispatching all the Actions to get the data from the backend
		getLexiconCount();
		getTemporalOrderingCount();
		getGrammarCount();
		getNegationsCount();
		getConceptAssociationsCount();
		getNECount();
		getConceptSupportCount();
	}, []);

	const goToTechDetail = () => {
		setHistory({ ...window.location, display_name: 'Technology list' });
		props.history.push(constants.ROUTE_URL + '/dashboard/techdetail');
	};

	return (
		<div>
			<Loading isLoading={props.NECount ? false : true} loadingClassName="loading">
				<span></span>
			</Loading>
			<ErrorBoundary>
				<Suspense fallback={<div className="defaultSpinner" />}>
					<TechnologyList
						lexicon={props.lexiconCount}
						temporal={props.temporalCount}
						grammar={props.grammarCount}
						negations={props.negationsCount}
						associations={props.associationCount}
						namedEntity={props.NECount}
						conceptSupport={props.conceptSupport}
						goToTechDetail={goToTechDetail}
						match={props.match}
					/>
				</Suspense>
			</ErrorBoundary>
			<Copyright />
		</div>
	);
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps)(Technology);
