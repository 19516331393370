import React, { useState, useEffect, forwardRef,useImperativeHandle } from 'react';
import ModalComponent from '../../common/ModalComponent/ModalComponent';
import { Tabs } from 'react-bootstrap';
import TabComponent from '../../common/TabComponent/TabComponent';
import AccountProfile from '../../components/AccountSetting/Profile/AccountProfile';
import AccountPermissions from '../../components/AccountSetting/Permissions/AccountPermissions';
import AccountSecurity from '../../components/AccountSetting/Security/AccountSecurity';
import AccountData from '../../components/AccountSetting/Data/AccountData';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import actions from '../../actions';
import { gotoListPage } from '../../common/FilterFunctions/FilterFunctions';
import CancelRapidManual from '../../components/CancelRapidManual/CancelRapidManual';
import ErrorBoundary from '../../components/ErrorBoundary';

const AccountManagement = forwardRef((props, ref) => {
	const [open, setOpen] = useState(false);
	const [key, setKey] = useState(1);
	const [selectedPage, setSelectedPage] = useState('');
	let confirmPopup = {};

	useImperativeHandle(ref, () => {
		handleClick: handleClick;
	});

	useEffect(() => {
		props.open({
			handleClick: () => handleClick(),
		});
		return () => {
			props.open(null);
		};
	}, []);

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setKey(1);
		//SET CLOSE MODAL IN REDUX
		props.dispatch(actions.openModal(''));
	};

	const handleSelect = (selectedKey) => {
		setKey(selectedKey);
	};

	const gotoPage = (page) => {
		let temp = window.localStorage.getItem('newRapidAnnotation');
		let newRapidAnnotationTemp = temp ? JSON.parse(temp) : '';
		var loc = window.location.href;
		if (newRapidAnnotationTemp) {
			setSelectedPage(page);
			confirmPopup.handleClick();
		} else {
			handleClose();
			gotoListPage(page, '', '', true);
		}
	};

	const cancelUpdate = () => {
		window.localStorage.removeItem('newRapidAnnotation');
		window.localStorage.removeItem('selectedConcept');
		window.localStorage.removeItem('editmodeNormal');
		window.localStorage.removeItem('updatedAnnotation');
		window.localStorage.removeItem('deletedAnnotation');
		window.localStorage.removeItem('fromEditMultipleManual');
		handleClose();
		gotoListPage(selectedPage, '', '', true);
	};

	return (
		<div>
			<ErrorBoundary>
				<ModalComponent
					open={open}
					handleClose={handleClose}
					content={
						<div className="modal_body_content modal-wrapper">
							<div className="modal-heading headerbar">
								Account management
								<div className="float-right submenu"></div>
							</div>
							<div className="modal-container">
								<Tabs
									className="nav-tabs-update"
									activeKey={key}
									onSelect={handleSelect}
									id="controlled-tab-example"
								>
									{key == 1 ? (
										<TabComponent
											style={{ padding: '0' }}
											eventKey={1}
											title="Profile"
										>
											<AccountProfile handleClose={handleClose} />
										</TabComponent>
									) : (
										<TabComponent
											style={{ padding: '0' }}
											eventKey={1}
											title="Profile"
										></TabComponent>
									)}
									{key == 2 ? (
										<TabComponent eventKey={2} title="Security">
											<AccountSecurity />
										</TabComponent>
									) : (
										<TabComponent
											style={{ padding: '0' }}
											eventKey={2}
											title="Security"
										></TabComponent>
									)}
									{key == 3 ? (
										<TabComponent eventKey={3} title="Permissions">
											<AccountPermissions />
										</TabComponent>
									) : (
										<TabComponent
											style={{ padding: '0' }}
											eventKey={3}
											title="Permissions"
										></TabComponent>
									)}
									{key == 4 ? (
										<TabComponent eventKey={4} title="Data">
											<ErrorBoundary>
												<AccountData gotoPage={gotoPage} />
											</ErrorBoundary>
										</TabComponent>
									) : (
										<TabComponent
											style={{ padding: '0' }}
											eventKey={4}
											title="Data"
										></TabComponent>
									)}
								</Tabs>
							</div>
						</div>
					}
					className="Data-Management"
				/>
			</ErrorBoundary>
			<ErrorBoundary>
				<CancelRapidManual
					rootClosexit={(event) => {}}
					cancelRapidUpdate={cancelUpdate}
					open={(c) => (confirmPopup = c)}
					revertRapidChanges={cancelUpdate}
				/>
			</ErrorBoundary>
		</div>
	);
});

const mapStateToProps = (state) => state;
export default withRouter(connect(mapStateToProps)(AccountManagement));
