import React from 'react';

function HeaderAccordion(props) {
	return (
		<div className={`${props.type == 'header' ? 'header-accordion' : 'subheader-accordion'} ${props.className? props.className:''}`}>
			<div className="panel-group" id="accordion">
				<div className="panel-default">
					<div className="panel-heading">
						<h4 className="panel-title" style={{ paddingLeft: props.leftPad }}>
							<span
								className={'accordion-toggle episode ' + (props.isOpen ? '' : 'collapsed')}
								aria-expanded="true"
								data-toggle="collapse"
								data-parent="#accordion"
								href={'#accordionContainer' + props.identifier}
							>
								{props.handleClick ? (
									<span
										className="hyperlink titleText"
										onClick={() => props.handleClick(props.referenceId)}
									>
										{props.text}
									</span>
								) : (
									<span className="hyperlink titleText">{props.text}</span>
								)}
							</span>
						</h4>
					</div>
					<div
						id={'accordionContainer' + props.identifier}
						className={'accordionContainer panel-collapse collapse ' + (props.isOpen ? 'show' : '')}
					>
						{props.children}
					</div>
				</div>
			</div>
			{
				// data && data.map((concept)=> {
				// 	let id = "collapseThree" + concept.ConceptID;
				//     return(
				//         <div>
				//         <div className="panel-default">
				// 				<div className="panel-heading">
				// 					<h4 className="panel-title">
				// 						<a className="accordion-toggle episode" aria-expanded="true" data-toggle="collapse" data-parent="#accordion" href={"#"+id}>
				//                             <h5 className="hyperlink">
				// 								{
				// 									concept.PatientId ? concept.PatientIdentifier : concept.ConceptName
				// 								}
				// 							</h5>
				// 						</a>
				// 					</h4>
				// 			    </div>
				// 			<div id={id} className="panel-collapse collapse show">
				//             {
				//                 <SubAccordion subdata={concept.PatientId ? concept.PatientAnnotationDetail : concept.TextAnnotation}/>
				//             }
				//             </div>
				// 		</div>
				//         <p> </p>
				//         </div>
				//     )
				// })
			}
		</div>
	);
}

export default HeaderAccordion;
